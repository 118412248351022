<template>
	<v-app>
		<v-app-bar app clipped-left flat>
			<!-- Основне меню -->
			<!-- <v-app-bar-nav-icon v-if="authorized" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->

			<!-- Логотип -->
			<!-- <v-avatar @click="OnHome" color="primary"> -->
			<v-avatar color="primary" :to="{name: 'home'}">
				<span class="white--text text-h5">CtB</span>
			</v-avatar>

			<!-- Назва -->
			<v-toolbar-title class="ml-4 text-h5" v-show="$vuetify.breakpoint.smAndUp">
				{{ title }} 
				<span class="text-caption">{{ version }}</span>
			</v-toolbar-title>

			<v-spacer></v-spacer>


			<!-- <v-spacer></v-spacer> -->
			
			<!-- Language Menu -->
			<v-menu offset-y open-on-hover>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						{{ $vuetify.lang.current }}
					</v-btn>
				</template>
				<v-list subheader dense>
					<v-subheader>{{ $vuetify.lang.t('$vuetify.language') | capitalize }}</v-subheader>
					<v-list-item-group v-model="selectedlang">
						<template v-for="(item, name, index) in $vuetify.lang.locales">
							<v-list-item :key="index" @click="OnClickLocale(name)">
								<template v-slot:default="{ active }">
									<v-list-item-content>
										<v-list-item-title v-text="item.langname"></v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>
										<v-icon v-if="active">mdi-check</v-icon>
									</v-list-item-action>
								</template>
							</v-list-item>
						</template>
					</v-list-item-group>
				</v-list>
			</v-menu>

			<!-- Увійти -->
			<!-- <v-btn v-show="!authorized"
				class="mx-3"
				outlined 
				color="primary" 
				:to="{name: 'login'}"
			>{{ $vuetify.lang.t('$vuetify.signin') | capitalize }}</v-btn> -->
			<v-btn v-show="!authorized" icon
				class="mx-3"
				:to="{name: 'login'}"
			>
				<v-icon>mdi-login</v-icon>
			</v-btn>


			


			<!-- Створити Аккаунт -->
			<!-- <v-btn v-show="!authorized" 
				color="primary" 
				:to="{name: 'signup'}"
			>{{ $vuetify.lang.t('$vuetify.createaccount') | capitalize }}</v-btn> -->

			<!-- Меню користувача-->
			<v-menu offset-y v-model="menu" :close-on-content-click="true" :close-on-click="true">
				<template v-slot:activator="{ on: menu, attrs }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-show="authorized" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
								<v-icon>mdi-account</v-icon>
							</v-btn>
						</template>
						<span>{{ $vuetify.lang.t('$vuetify.user') | capitalize }}</span>
					</v-tooltip>

				</template>
				<v-list>
					<v-list-item three-line @click="$refs.profile.Open()">
						<v-list-item-action>
							<v-icon>mdi-account</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title class="text-h6">{{ profile.name }}</v-list-item-title>
							<v-list-item-subtitle>{{ profile.company }}</v-list-item-subtitle>
							<v-list-item-subtitle>{{ profile.email }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-divider></v-divider>
				<v-list dense>
					<!-- Зміна паролю -->						
					<v-list-item @click="OnPasswordChange">
						<v-list-item-avatar>
							<v-icon>mdi-key-change</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>{{ $vuetify.lang.t('$vuetify.passwordchange') | capitalize }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- API key -->						
					<v-list-item @click="$refs.apikey.Open()">
						<v-list-item-avatar>
							<v-icon>mdi-key</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>API key</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
				<v-list dense v-if="$vuetify.breakpoint.smAndDown">
					<!-- Вихід -->						
					<v-list-item @click="OnLogout">
						<v-list-item-avatar>
							<v-icon>mdi-exit-to-app</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>{{ $vuetify.lang.t('$vuetify.exit') | capitalize }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>

			<!-- Вихід -->
			<v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-show="authorized" icon @click="OnLogout" v-bind="attrs" v-on="on">
						<v-icon>mdi-logout</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.exit') | capitalize }}</span>
			</v-tooltip>
		</v-app-bar>

		<!-- Головне меню -->
		<v-navigation-drawer app clipped flat expand-on-hover>
			<v-list nav dense >
				<v-list-item link v-for="(item, index) in mainmenu" :key="index" :to="item.to">
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{ $vuetify.lang.t('$vuetify.'+item.title) | capitalize }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		
		<v-main style="padding: 64px 0px 0px 64px;">
			<router-view></router-view>
		</v-main>

		<z-profile ref="profile" :value="profile"></z-profile>
		<z-apikey ref="apikey"></z-apikey>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
  	</v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import zProfile from '@/views/app.profile.vue'
import zApikey from '@/views/app.apikey.vue'

export default {
	name: 'App',

	components: {
		zProfile, zApikey
	},

	data: () => ({
		loading: false,

		drawer: false,
		menu: false,
		selectedlang: null
	}),
	created () {
		//Встановлення мови за замовченням
		let lang = localStorage.getItem('lang')
		if(!lang) {
			lang = navigator.language.substring(0,2)
		}

		if(this.$vuetify.lang.locales.hasOwnProperty(lang)){
			this.$vuetify.lang.current = lang
		}

		this.selectedlang = Object.keys(this.$vuetify.lang.locales).indexOf(this.$vuetify.lang.current)

		//console.log('VUE_APP_API:', process.env.VUE_APP_API);

		// Перевірка валідності токена
		//this.OnToken()

		/* if (this.scenarium.length == 0) {
			this.OnRefresh()
		} */
		//-- Завантаження даних -----------------------------------------
		// Bot
		if (this.$store.getters['bot/items'].length == 0) {
			this.$store.dispatch(`bot/search`, {})
		}

		// DataType
		if (this.$store.getters['datatype/items'].length == 0) {
			this.$store.dispatch(`datatype/search`, {})
		}

		// KeyboardType
		if (this.$store.getters['keyboardtype/items'].length == 0) {
			this.$store.dispatch(`keyboardtype/search`, {})
		}

		// Menu
		if (this.$store.getters['menu/items'].length == 0) {
			this.$store.dispatch(`menu/search`, {})
		}
		// MenuType
		if (this.$store.getters['menutype/items'].length == 0) {
			this.$store.dispatch(`menutype/search`, {})
		}

		// FuncType
		if (this.$store.getters['functype/items'].length == 0) {
			this.$store.dispatch(`functype/search`, {})
		}

		// Scenarium
		if (this.$store.getters['scenarium/items'].length == 0) {
			this.$store.dispatch(`scenarium/search`, {})
		}

		// Chats
		if (this.$store.getters['chats/items'].length == 0) {
			this.$store.dispatch(`chats/search`, {})
		}
	},
	computed: {
		...mapGetters('app', [ 'authorized', 'title', 'profile', 'usermenu', 'services']),
		...mapGetters('menu', [ 'mainmenu' ]),

		version() {
			return process.env.VUE_APP_VERSION;
		},
		
	},
	watch: {
		/* value (val) {
			console.log("value", val)
			this.Mode(val)
			
		}, */
    },

	methods: {
		...mapActions('app', [ 'Token', 'Init', 'Logout' ]),
		...mapActions('scenarium', { SearchScenarium: 'search' }),
		...mapActions('chats', { SearchChats: 'search' }),

		OnToken () {
			var that = this;
            this.loading = true;
            this.Token({
                success: function(response) {
                    that.loading = false;
                },
                error: function(response) {
                    that.loading = false;
					that.$router.push('/login')
                },
            })
		},

		// Вихід користувача з системи
		OnLogout () {
			var that = this;
            this.loading = true;
            this.Logout({
                params: { },
                success: function(response) {
                    that.loading = false;
					//router.push('/login').catch(err => {});
                },
                error: function(response) {
                    that.loading = false;
					//router.push('/login').catch(err => {});
                },
            })
		},

		// Зміна паролю
		OnPasswordChange () {
			this.$router.push('/password/change').catch(err => {});
		}, 

		// Встановлення мови інтерфейсу
		OnClickLocale(name) {
			this.$vuetify.lang.current = name;
			localStorage.setItem('lang', name)
		},
		
		// Перехід на стартову сторінку
		/* OnHome () {
			this.$router.push('/').catch(err => {});
		}, */
	}
	
};
</script>

<style>
	html {
    	overflow-y: auto;
	}
</style>
<template>
	<v-card 
		class="elevation-0"
		@click="$refs.fileinput.$refs.input.click()"
	>
		<v-toolbar class="elevation-0" flat>
			<v-toolbar-title class="text-caption">{{ filename }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon @click.stop="clear" v-if="url">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-btn icon v-else>
				<v-icon>mdi-paperclip</v-icon>
			</v-btn>
		</v-toolbar>

		<v-card-text>
			<v-img :src="url" contain v-if="url" max-height="128"></v-img>
			<v-img :src="urlfile + filepathname" contain v-else-if="filepathname" max-height="128"></v-img>
		</v-card-text>
		
		<v-file-input class="d-none" ref="fileinput" v-model="file" @change="change"></v-file-input>
	</v-card>
</template>

<script>
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API;

export default {
	data () {
		return {
			loading: false,
			file: null,
			url: null,
			urlfile: process.env.VUE_APP_API + '/file/',
		}
	},
	props: {
		label: 			{ type: String, default: 'File'},
		filename:  		{ type: String, default: null},
		filepathname:  	{ type: String, default: null},
	},
	computed: {
        
    },

	watch: {
		/* file: function (val) {
			//this.$emit('change', val)
		},  */
		filepathname: function (val) {
			console.log('attachment','filepathname', val)

			if(val) {
				this.url = urlfile +  val
				//console.log('attachment','url', this.url)
			}
		}
	},
	methods: {
		clear() {
			this.url 	= null
			this.file 	= null

			this.$emit('change', {
				filename: 		null,
				filepathname: 	null,
			})
		},

		/* preview() {
			this.url = URL.createObjectURL(this.file)
		}, */

		change(file) {
			this.url = URL.createObjectURL(file)

			this.loading = true
			
			let formData = new FormData();
			formData.append("file", file, file.name);

			//console.log(context.getters.name, 'upload','formData', formData)
			// files
			/* if(Array.isArray(data.files)) {
				for (let file of data.files) {
					formData.append("files", file, file.name);
				}
			} else {
				formData.append("files", data.files, data.files.name);
			} */

			// additional data
			//formData.append("test", "foo bar");

			var that = this;
			axios.post('file', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(response => {

				console.log('attachment', 'upload','response', response)

				that.$emit('change', {
					filename: 		response.data.filename,
					filepathname: 	response.data.filepathname,
				})

				that.loading = false
			}, error => {
				that.loading = false
				console.log('attachment', 'upload','error', error.response)
			});
		},

		download () {
			var that = this;
			axios.get('download', { 
				params: { filename: this.filename, filepathname: this.filepathname },
				responseType: 'blob'
			}).then(response => {
				console.log('attachment', 'download','response', response)
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', that.filename);
				document.body.appendChild(link);
				link.click();

				that.loading = false
			}, error => {
				that.loading = false
				console.log('attachment', 'download','error', error.response)
			});
		},
	},
}
</script>
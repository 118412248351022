var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.fullscreen,"transition":_vm.transition,"scrollable":_vm.scrollable,"width":_vm.width,"height":_vm.height,"max-width":_vm.maxWidth,"persistent":_vm.persistent},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.activator=='icon')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":_vm.small,"disabled":_vm.disabled}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v(_vm._s(_vm.acvicon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.hint))))])]):(_vm.activator=='list-item')?_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled}},'v-list-item',attrs,false),Object.assign({}, dialog)),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.acvicon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.title.plural))))])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabled}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v(_vm._s(_vm.acvicon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.hint))))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card',{staticClass:"elevation-0",attrs:{"height":"512"}},[_c('v-toolbar',{staticClass:"elevation-0",staticStyle:{"flex":"0 1 auto"},attrs:{"color":"primary","dark":"","flat":""},scopedSlots:_vm._u([(_vm.extension)?{key:"extension",fn:function(){return [_vm._t("extension")]},proxy:true}:null],null,true)},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.'+_vm.title.single)))+" "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.version))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.fullscreen=!_vm.fullscreen}}},[(_vm.fullscreen)?_c('v-icon',[_vm._v("mdi-checkbox-multiple-blank-outline")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._t("context")],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","large":"","disabled":_vm.loading},nativeOn:{"click":function($event){return _vm.ok.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-check-bold")]),_vm._v(" OK ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
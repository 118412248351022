<template>
	<v-form ref="form">
		<v-row dense>
			<v-col  cols="12">
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.name') | capitalize"
					:rules="[rules.required]"
				></v-text-field>
				<v-textarea
					v-model="item.description"
					:label="$vuetify.lang.t('$vuetify.description') | capitalize"
					rows="1"
				></v-textarea>
				<v-combobox
					v-model="item.datatype"
					:items="types"
					:label="$vuetify.lang.t('$vuetify.type') | capitalize"
					item-text="name"
					item-value="id"
					:return-object="true"
					:rules="[rules.required]"
					:prepend-icon="item.icon"
				>
					<template slot="item" slot-scope="data">
						<v-icon>{{ data.item.icon }}</v-icon>
						<span class="ml-3">{{ data.item.name }}</span>
					</template>
				</v-combobox>

				<v-textarea v-if="['text'].includes(type)"
					v-model="item.text"
					:label="$vuetify.lang.t('$vuetify.text') | capitalize"
					rows="2"
				></v-textarea>

				<v-textarea v-if="['photo','document','video','animation'].includes(type)"
					v-model="item.caption"
					:label="$vuetify.lang.t('$vuetify.caption') | capitalize"
					rows="1"
				></v-textarea>

				<v-text-field v-if="['audio', 'venue'].includes(type)"
					v-model="item.title"
					:label="$vuetify.lang.t('$vuetify.title') | capitalize"
				></v-text-field>

				<v-text-field v-if="['audio', 'venue'].includes(type)"
					v-model="item.duration"
					:label="$vuetify.lang.t('$vuetify.duration') | capitalize"
					type="number"
				></v-text-field>

				<v-row>
					<v-col  cols="12" md="6" v-if="['contact'].includes(type)">
						<v-text-field 
							v-model="item.firstName"
							:label="$vuetify.lang.t('$vuetify.firstName') | capitalize"
						></v-text-field>
					</v-col>

					<v-col  cols="12" md="6" v-if="['contact'].includes(type)">
						<v-text-field
							v-model="item.lastName"
							:label="$vuetify.lang.t('$vuetify.lastName') | capitalize"
						></v-text-field>
					</v-col>
				</v-row>

				<v-col  cols="12" md="6" v-if="['contact'].includes(type)">
					<v-text-field
						v-model="item.phoneNumber"
						:label="$vuetify.lang.t('$vuetify.phoneNumber') | capitalize"
					></v-text-field>
				</v-col>

				<v-row>
					<v-col  cols="12" md="6" v-if="['location', 'venue'].includes(type)">
						<v-text-field
							v-model="item.latitude"
							:label="$vuetify.lang.t('$vuetify.latitude') | capitalize"
						></v-text-field>
					</v-col>

					<v-col  cols="12" md="6" v-if="['location', 'venue'].includes(type)">
						<v-text-field
							v-model="item.longitude"
							:label="$vuetify.lang.t('$vuetify.longitude') | capitalize"
						></v-text-field>
					</v-col>
				</v-row>

				<v-text-field v-if="['venue'].includes(type)"
					v-model="item.address"
					:label="$vuetify.lang.t('$vuetify.address') | capitalize"
				></v-text-field>

				<attachment v-if="['photo','document','audio','sticker','video','animation','voice','media'].includes(type)"
					:filename="item.filename"
					:filepathname="item.filepathname"
					@change="changeAttachment"
				></attachment>

				<v-checkbox
					v-model="item.disableNotification"
					:label="$vuetify.lang.t('$vuetify.disableNotification') | capitalize"
					:false-value="false"
					:true-value="true"
				></v-checkbox>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import mixin from './mixins/form'
import attachment from '@/views/components/attachment'

export default {
	mixins: [mixin],
	components: { attachment },
	data () {
		return {

		}
	},
	computed: {
		types: function () {
			//console.log('keyboard', 'computed', 'types', this.$store.getters['keyboardtype/items'])
			return this.$store.getters['datatype/items']
		},
		type: function () {
			if(this.item.datatype) {
				//return this.item.datatype.code
				return this.item.datatype.name
			}
		}
	},
	methods: {
		// для перегрузки 
		open() {},
		clear() {},

		changeAttachment (data) {
			console.log('changeAttachment: data', data)
			//console.log('changeFile: file', file)

			this.item.filename 		= data.filename
			this.item.filepathname 	= data.filepathname
		},

		/* clearFile () {
			this.item.filename 		= null
			this.item.filepathname 	= null
		}, */
	}
}
</script>
export default {
    namespaced: true,
    state: {
        name: 'keyboardbutton',
        title: {
            single: 'keyboardbutton',
            plural: 'keyboardbuttons'
        },
        icon: 'mdi-button-cursor',

        item:  {},
        items: [],

        default: {
            id: null,
            //code: null,
            text: null,
            //description: null,
            row: null,
            ordinal: null,
            //keyboard: null,
            scenarium: null,
            funcs: []
        },

        headers: [
            { text: 'id', value: 'id', sortable: true},
            { text: 'text', value: 'text', sortable: true},
            { text: 'row', value: 'row', sortable: true},
            { text: 'ordinal', value: 'ordinal', sortable: true},
            //{ text: 'keyboard', value: 'keyboard.name', sortable: true},
            { text: 'scenarium', value: 'scenarium.name', sortable: true},
            //{ text: 'description', value: 'description', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],

        tabs: [
			{ title: 'main',        type: 'form', module: 'keyboardbutton' },
			{ title: 'funcs',   type: 'list', module: 'funcs', dataSource: 'funcs', actions: ['add'], offline: true, removable: true },
		],
    },
}
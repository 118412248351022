<template>
	<div :class="['message', 'pa-1', 'ma-1', color, 'rounded-lg', justify=='right'?'rounded-tr-0':'rounded-tl-0']" 
		@click="OnClick"
	>
		<v-list two-line v-if="this.message.document" dense>
			<v-list-item>
				<v-list-item-avatar>
					<!-- <v-avatar color="primary" size="56">
						<v-icon  class="grey lighten-1">mdi-file</v-icon>
					</v-avatar> -->
					<v-icon>mdi-file</v-icon>
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title v-text="this.message.document.fileName"></v-list-item-title>
					<v-list-item-subtitle v-text="this.message.document.fileSize"></v-list-item-subtitle>
				</v-list-item-content>

				<v-list-item-action>
					<v-list-item-action-text>
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon x-small v-bind="attrs" v-on="on">
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<v-list-item v-for="(item, index) in items" :key="index" @click="clickMenu(item)">
									<v-list-item-icon>
										<v-icon v-text="item.icon"></v-icon>
									</v-list-item-icon>
									<!-- <v-list-item-title>{{ $vuetify.lang.t('$vuetify.'+item.title) | capitalize }}</v-list-item-title> -->
									<v-list-item-title>{{ item.title | capitalize }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						
					</v-list-item-action-text>

					<v-list-item-action-text></v-list-item-action-text>

				</v-list-item-action>
      		</v-list-item>
		</v-list>

		<v-img v-if="photo"
			:max-height="photo.height"
			:max-width="photo.width"
			:src="url + photo.fileId"
		></v-img>
		<div v-if="message.caption" v-html="message.caption"></div>
		<div v-if="message.text" v-html="message.text"></div>
	</div>
</template>

<script>
export default {
	components: {
		
	},
	data: () => ({
		items: [
			{code: 'respond', 	title: 'respond', 	icon: 'mdi-arrow-left-top'},
			{code: 'download', 	title: 'download', 	icon: 'mdi-download'},
			{code: 'share', 	title: 'share', 	icon: 'mdi-share-variant'},
			{code: 'forward', 	title: 'forward', 	icon: 'mdi-arrow-right-top'},
			{code: 'pin', 		title: 'pin', 		icon: 'mdi-pin'}, //приперти
			{code: 'complain', 	title: 'complain', 	icon: 'mdi-alert-circle-outline'},
			{code: 'remove', 	title: 'remove', 	icon: 'mdi-trash-can'},
		],
		url: process.env.VUE_APP_API + '/photo/'
	}),
	props: {
		message:	{ type: Object, default: null },	// Повідомлення
		color:		{ type: String, default: null }, 	// Колір фону
		justify:	{ type: String, default: 'left' }, 	// Вирівнювання (left | right)
	},
	created() {
		
	},
	computed: {
		photo() {
			if(this.message.photo[1]) {
				return this.message.photo[1]
			}
		}
	},
	watch: {	
		/* message (val) {
			if(val) {
				console.log('OnClick', 'message', this.message)
			}
		}, */
	},
	methods: {
		
		OnClick (e) {
			console.log('OnClick', 'message', this.message)
		},

		clickMenu(item) {

		},
	},
}
</script>

<style>
	.message{
		max-width:80%;
		display: inline-block;
	}
</style>
<template>
	<v-form ref="form">
		<v-row dense>
			<v-col  cols="12">
				<v-combobox
					v-model="item.type"
					:items="types"
					:label="$vuetify.lang.t('$vuetify.type') | capitalize"
					item-text="name"
					item-value="id"
					:return-object="true"
					:rules="[rules.required]"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-html="item.name"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-combobox>
			</v-col>
			<!-- <v-col  cols="12" md="4">
				<v-text-field
					v-model="item.icon"
					:label="$vuetify.lang.t('$vuetify.icon') | capitalize"
					:prepend-inner-icon="item.icon"
				></v-text-field>
			</v-col> -->
			<v-col  cols="12">
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.name') | capitalize"
					:rules="[rules.required]"
				></v-text-field>
				<v-textarea
					v-model="item.description"
					:label="$vuetify.lang.t('$vuetify.description') | capitalize"
					rows="2"
				></v-textarea>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import mixin from './mixins/form'

export default {
	mixins: [mixin],
	data () {
		return {

		}
	},
	computed: {
		types: function () {
			return this.$store.getters['functype/items']
		},
	}
}
</script>
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import en from '../locale/en'
import ru from '../locale/ru'
import uk from '../locale/uk'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				//primary: colors.red.darken1, // #E53935
				//secondary: colors.red.lighten4, // #FFCDD2
				//accent: colors.indigo.base, // #3F51B5
				//mcolor: '#003c7d',
				//primary: '#003c7d;',
				//primary: '#1A237E',
				primary: '#257047',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				grey:  '#ECEFF1',// colors. #ECEFF1 colors.blue.grey.lighten5
				
				chat: {
					background: '#ECEFF1'
				},
				chatBactground:  '#ECEFF1',
				request:  '#FFFFFF',
				respond:  '#FFF9C4',

				
				/* primary: '#1976D2',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107', */
			},
		},
	},
	lang: {
		//locales: { en, ru, uk },
		locales: { en, uk },
		current: 'uk',
	},
});

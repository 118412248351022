export default {
    //...ru,
    langname: "Русский",
    language: "язык",
    homepage: "Главная",
    profile: "Профиль",

    commonAttributes: "общие атрибуты",
    type: "тип",
    name: "наименование",
    namePersonOrganisation: "ФИО / Наименование организации",
    number: "номер",
    date: "дата",
    from: "с",
    to: "по",
    yes: "да",
    not: "нет",
    error: "ошибка",

    commonActions: "общие действия",
    actions: "Действия",
    enter: "Вход",
    exit: "Выход",
    ok: "OK",
    cancel: "Отменить",
    confirm: "подтвердить",
    agree: "согласен",
    disagree: "не согласен",
    add: "Добавить",
    remove: "Удалить",
    clear: "Очистить",
    new: "Создать",
    edit: "Редактировать",
    create: "Создать",
    update: "Редактировать",
    delete: "Удалить",
    search: "поиск",
    login: "логин",
    signin: "Вход",
    signup: "Регистрация",
    save: "Сохранить",
    open: "открыть",
    close: "закрыть",
    cut: "вырезать",
    copy: "копировать",
    duplicate: "дубликат",
    paste: "вставить",
    refresh: "обновить",
    export: "экспорт",
    import: "импорт",
    authorization: "авторизация",
    createaccount: "Создать Аккаунт",
    registration: "регистрация",
    password: "пароль",
    passwordold: "старый пароль",
    passwordnew: "новый пароль",
    passwordconfirm: "подтвердить пароль",
    passwordchange: "Изменить пароль",
    passwordreset: "Обновить пароль",
    passwordrecovery: "Восстановление пароля",
    passwordforgot: "Забыли пароль?",
    donthaveaccount: "Создать Аккаунт",
    orderconnection: "Заявка на подключение",

    // Objects
    main: "главная",
    account:"учётная запись",
    accounts:"учётные записи",
    bot:"бот",
	bots:"боты",
	botcommand:"команда бота",
	botcommands:"команды бота",
    users: "пользователи",
    user: "пользователь",
    roles: "Роли",
    role: "Роль",
    scenarium: "сценарий",
    scenariums: "сценарии",
    chat: "чат",
    chats: "чаты",
    gallery: "галерея",
    message: "сообщение",
    messages: "сообщения",
    request: "запрос",
    requests: "запросы",
    respond: "ответ",
    responds: "ответы",
    menu: "меню",
    menutype: "тип меню",
	menutypes: "типы меню",
    funs: "функции",
    func: "функция",
    functype: "тип функции",
	functypes: "типы функции",
    functions: "функции",
	function: "функция",

    token: "токен",
    text: "text",
	photo: "photo",
	document: "document",
	audio: "audio",
	sticker: "sticker",
	video: "video",
	animation: "animation",
	voice: "voice",
	contact: "contact",
	location: "location",
	venue: "venue",
	media: "media",

    caption: "заголовок",
	title: "название",
	firstName: "имя",
	lastName: "фамилия",
	phoneNumber: "номер телефона",
    disableNotification: "отключить уведомление",
    onetime: "один раз",
	resize: "изменить размер",
    hidden: "скрыть",
    
    //Keyboard
    keyboard: "клавиатура",
    tableAdd: "добавить таблицу",
    tableRemove: "удалить таблицу",
    tableRefresh: "обновить таблицу",
    tableRowAddBefore: "добавить строку таблицы перед",
    tableRowAddAfter: "добавить строку таблицы после",
    tableRowRemove: "удалить строку таблицы",
    tableColAddBefore: "добавить колонку таблицы перед",
    tableColAddAfter: "добавить колонку таблицы после",
    tableColRemove: "удалить колонку таблицы",
    tableColMoveLeft: "переместить колонку таблицы влево",
    tableColMoveRight: "переместить колонку таблицы вправо",
    tableRowMoveUp: "переместить строку таблицы вверх",
    tableRowMoveDown: "переместить строку таблицы вниз",
    InlineKeyboardMarkup: "Inline Keyboard Markup",
    ReplyKeyboardMarkup: "Reply Keyboard Markup",
    ReplyKeyboardRemove: "Reply Keyboard Remove",
    properties: "свойства",
    buttonsproperties: "свойства кнопки",

    //Sentences
    "Are you sure you want to delete this record?": "Вы уверены, что ходите удалить запись?",

    row: "строка",
    rows: "строки",
    column: "столбец",
    columns: "столбецы",

    //homepage.banner
    cameraoff: "На вашому пристрої доступ до камери заблоковано. В такому режимі ви можете перевіряти поліси, шляхом введення вручну номер полісу або цифри зі штрих-коду.",

    stopscan: "остановить сканирование",
    startscan: "запустить сканирование",

    settings: "настройки",
    video: "видео",
    frame: "рамка",
    laser: "лазер",
    sound: "звук",
    soundeditor: "редактор звуковых сигналов",
    volume: "громкость",
    frequency: "частота",
    duration: "длительность",
    beeps: {
        scan: "считывание кода",
        success: "полис идентифицирован",
        failure: "полис не идентифицирован",
        error: "ошибка",
    },

    //Attributes
    id: "id",
    code: "код",
	username: "имя пользователя",
	description: "описание",
	datecreate: "дата создания",
    pib: "фамилия, имя, отчество",
    telephone: "телефон",
    email: "email",
    comment: "комментарий",
    icon: "значок",
    regexpression: "регулярное выражение",
	ordinal: "порядковый номер",
    type: "тип",
	types: "типы",
    datatype: "тип данных",
	datatypes: "типы данных",
    keyboard: "клавиатура",
	keyboards: "клавиатуры",
	keyboardtype: "тип клавиатуры",
	keyboardtypes: "типы клавиатур",
    keyboardbutton: "кнопка клавиатуры",
	keyboardbuttons: "кнопки клавиатуры",
    button: "кнопка",
	buttons: "кнопки",

persons: "физические лица",
person: "физическое лицо",
firstname: "имя",
surname: "фамилия",
patronymic: "отчество",
birthDate: "дата рождения",
birthPlace: "место рождения",
deathDate: "дата смерти",
deathPlace: "место смерти",
nationality: "гражданство",
post: "должность",
personsname: "ФИО",
organization: "организация",

sex: "пол",
male: "мужской",
female: "женский",

persons_roles: "роли лиц",
claimant: "заявитель",
owner: "владелец",
driver: "водитель",
previous_owners: "предыдущие владельцы",

identification: "документ, удостоверяющий личность",
seria: "серия",
number: "номер",
issuedby: "кем выдан",
dateofissue: "дата выдачи",
recordno: "запись №",
dateofexpiry: "срок действия",
documentno: "документ №",

identificationstype: "тип документа",
identificationstypes: "типы удостоверений личностей",
passport: "паспорт",
passportCitizen: "паспорт гражданина",
passportForeign: "паспорт гражданина для выезда за рубеж",
passportDiplomatic: "дипломатический паспорт",
passportService: "служебный паспорт",
passportSeamen: "паспорт моряка",
passportCrew: "удостоверение члена экипажа",
passportReturn: "удостоверение личности на возвращение",
passportTemporary: "временное удостоверение гражданина",
driverLicense: "водительское удостоверение",
identityCardTravel: "удостоверение личности без гражданства для выезда за границу",
identityCardPermanent: "вид на жительство",
identityCardTemporary: "временный вид на жительство",
identityCardMigrant: "карта мигранта",
refugeeCertificate: "удостоверение беженца",
refugeeTravelDocument: "проездной документ беженца",
identityCardAdditionalProtection: "удостоверение личности, которая нуждается в дополнительной защите",
identityCardGrantedAdditionalProtection: "проездной документ лица, которому предоставлена дополнительная защита",

addresses: "адреса",
address: "адреса",
postalCode: "индекс",
сountry: "страна",
locality: "населенный пункт",
district: "район",
street: "улица",
house: "дом",
apartment: "квартира",
latitude: "latitude",
longitude: "longitude",

addressestypes: "типы адресов",
addresstype: "тип адреса",
addressResidence: "адрес проживания",
addressRegistration: "адрес регистрации",

documents: "документы",
document: "документ",
templates: "Шаблоны",
template: "Шаблон",
constructors: "Кострукторы",
constructor: "Коструктор",
//number: "номер",
//date: "дата",

policies: "договора страхования",
policy: "договор страхования",
//number: "номер",
//date: "дата",
insurer: "страхователь",
periodValidity: "срок действия",

notifications: "уведомления",
notification: "уведомление",
//number: "номер",
//date: "дата",

cases: "дела",
case: "дело",
//number: "номер",
//date: "дата",

payments: "выплаты",
payment: "выплата",
//number: "номер",
//date: "дата",

calendars: "календари",
calendar: "календарь",
//number: "номер",
//date: "дата",

    //---------------------------
    company: "компания",
    inn: "ИНН",

    //Rules
    rule: {
        required: "Значення поля не може бути пустим",
        lenless: "Name must be less than {0} characters",
        code: "Code must be less than {0} characters",
        personsname: "Ім'я особи містить недопустимі символи",
        email: "E-mail вказано невірно",
        phone: "Номер телефону не вірний (+380961112233)",
    },

    carousel: {
        ariaLabel: { 
            delimiter: ''
        }
    },

    noDataText: '',

    dataTable: {
        itemsPerPageText: '',
        ariaLabel: {
            sortNone: '',
            activateAscending: '',
        },
        loadingText: 'Loading... Please wait',
    },

    dataFooter: {
        prevPage: '',
        nextPage: '',
        itemsPerPageAll: '',
        pageText: '',
    },

    datePicker: { 
        prevMonthAriaLabel: '',
        nextMonthAriaLabel: '',
    },

    carousel: {
        prev: 'предыдущий',
        next: 'следующий',
        ariaLabel: {
            delimiter: ''
        }
    },

    badge: '',
}
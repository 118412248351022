export default {
	namespaced: true,
	state: {
		name: 'menu',
		title: {
			single: 'menu',
			plural: 'menu',
		},
		icon: 'mdi-menu',

		item:  {},
		items: [],

		default: {
            id: null,
			code: null,
			name: null,
			description: null,
			type: null,
			icon: null,
			ordinal: null,
			roles: []
        },

        headers: [
            { text: 'id', value: 'id', sortable: true},
			{ text: 'icon', value: 'icon', sortable: false},
            { text: 'name', value: 'name', sortable: true},
			{ text: 'description', value: 'description', sortable: true},
            { text: 'type', value: 'type.name', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],

		tabs: [
			{ title: 'main',	type: 'form', module: 'menu' },
			{ title: 'roles',	type: 'list', module: 'roles', propertyName: 'roles', actions: ['add'] },
		],
	},
	getters: {
		mainmenu(state) {
			let result = []
			if(state.items) {
				//console.log(state.name, 'getters', 'mainmenu', 'state.items', state.items)

				let items = state.items.filter(el => el.type.name === 'main').sort((a, b) => a.ordinal - b.ordinal)
				for(let i in items) {
					let item = items[i]
					result.push({
						name: item.name,
						title: item.name,
						icon: item.icon,
						to: { name: item.name },
					})
				}
			}
			return result
		},
		settings(state) {
			let result = []
			if(state.items) {
				let items = state.items.filter(el => el.type.name === 'settings').sort((a, b) => a.ordinal - b.ordinal)
				for(let i in items) {
					let item = items[i]
					result.push({
						name: item.name,
						title: item.name,
						icon: item.icon,
						to: { name: item.name },
					})
				}
			}
			return result
		},
	},
	mutations: {
        main(state, value) {
            state.main = value
        },
    },
	actions: {
		Clear (context){
			context.commit('mainmenu')
		},
		Load (context, data) {

			/* 
				Повідомлення
				Справи
				Пошта
				Документи
				Банківські реквізити
				Об'єкти страхування
				Затраховані особи
				Договори страхування
				Виплати
				Довірені особи
				Адреси
				Контакти
				Учасники
				Оцінка якості
				Налаштування (мова, геолокація, виджети)
				Інтернет-магазин
				*/

			/* context.commit('items', [
				{ code: 'documents', to: {name: 'documents'}, icon: 'mdi-bookshelf',
					children: [
						{ code: 'policies', to: {name: 'policies'}, icon: 'mdi-bookshelf' },
						{ code: 'notifications', to: {name: 'notifications'}, icon: 'mdi-bullhorn' },
						{ code: 'cases', to: {name: 'cases'}, icon: 'mdi-bookshelf' },
						{ code: 'payments', to: {name: 'payments'}, icon: 'mdi-bookshelf' },
					],
				},
				{ code: 'calendar', to: {name: 'calendar'}, icon: 'mdi-bookshelf' },
			])
			context.commit('items1', [
				{ code: 'policies', to: {name: 'policies'}, icon: 'mdi-file-document-multiple-outline' },
				{ code: 'notifications', to: {name: 'notifications'}, icon: 'mdi-bullhorn' },
				{ code: 'cases', to: {name: 'cases'}, icon: 'mdi-bookshelf' },
				{ code: 'payments', to: {name: 'payments'}, icon: 'mdi-cash-multiple' },
			])
			context.commit('items2', [
				{ code: 'calendar', to: {name: 'calendar'}, icon: 'mdi-calendar' },
			])
			context.commit('items3', [

			]) */

			context.commit('items', [
				//Documents
				{
					items: [
						{ code: 'documents', to: {name: 'documents'}, icon: 'mdi-bookshelf' },
					]
				},
				//Templates
				{
					items: [
						{ code: 'constructors', to: {name: 'constructors'}, icon: 'mdi-tools',
							children: [
								{ code: 'templates', to: {name: 'templates'}, icon: 'mdi-bookshelf' },
								//{ code: 'notifications', to: {name: 'notifications'}, icon: 'mdi-bullhorn' },
							],
						},
					]
				},	
				//Calendar
				{
					items: [
						{ code: 'calendar', to: {name: 'calendar'}, icon: 'mdi-calendar' },
					]
				},
			])

		},
	},
}
export default {
    namespaced: true,
    state: {
        name: 'services',
        title: {
            single: 'service',
            plural: 'services'
        },
        icon: 'mdi-account-tie',

        items: [],
        headers: [
            //{ text: 'ID', value: 'id', align: ' d-none' },
            { text: 'ID', value: 'id', sortable: true},
            { text: 'Назва', value: 'name', sortable: true},
            { text: 'Користувачі', value: 'users.length', sortable: true},
            { text: 'Ролі', value: 'services.length', sortable: true},
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        form: {
            tab: null,
            tabs: ['Головна', 'Користувачі', 'Ролі']
        },

    },
}
<template>
	<v-data-table
		:headers="headers"
		:items="items"
		elevation="0"
		:loading="loading"
		loading-text="Loading... Please wait"
	>
		<template v-for="(item, index) in headers" v-slot:[`header.${item.value}`]="{  }">
			<span :key="index">{{ $vuetify.lang.t('$vuetify.' + item.text) | capitalize }}</span>
		</template>

		<template v-slot:[`item.datecreate`]="{ item }">
			<!-- <span>{{ item.datecreate | dd_mm_yyyy }}</span> -->
			<span>{{ item.datecreate | yyyy-mm-dd }}</span>
		</template>

		<template v-slot:[`item.icon`]="{ item }">
			<v-icon>{{ item.icon }}</v-icon>
		</template>

		<template v-slot:[`item.hidden`]="{ item }">
			<v-simple-checkbox
				v-model="item.hidden"
				disabled
			></v-simple-checkbox>
		</template>

		<template v-slot:[`item.actions`]="{ item }">
			<confirm @agree="$emit('remove', item)" v-if="removable"></confirm>
			<detail :module="module" mode="update" activator="icon" :selected="item" v-if="editable"></detail>
		</template>
		<template v-slot:no-data>
			<!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
		</template>
	</v-data-table>
</template>

<script>
import confirm from '@/views/components/confirm'

export default {
	components: { 
		confirm, 
		detail: () => import('@/views/components/detail')
	},
	data: () => ({
		selected: [],
		active: [],
	}),
	props: {
		module: 		{ type: String, default: null },	// Модуль: users | roles | ....
		items:			{ type: Array, default: ()=>[] },
		headers:		{ type: Array, default: ()=>[] },
		multiple:		{ type: Boolean, default: true },
		movable:		{ type: Boolean, default: false },	// Ознака наявності функції зміни позиції
		removable:		{ type: Boolean, default: false },	// Ознака наявності функції видалення
		editable:		{ type: Boolean, default: false },	// Ознака наявності функції редагування
		creatable:		{ type: Boolean, default: false },	// Ознака наявності функції створення
		icon:       	{ type: String, default: null },
		openAll:		{ type: Boolean, default: false },
		selectionType:	{ type: String, default: 'independent' }, // leaf | independent
		loading:		{ type: Boolean, default: false },
	},
	methods: {
		getSelected () {
			return this.multiple ? this.selected : (this.active.length>0 ? this.active[0] : null)
		},
	}
}
</script>
<template>
	<v-dialog  v-model="dialog" persistent  max-width="700">
		<v-card elevation="4" class="rounded-0" :loading="loading">
			<v-toolbar  elevation="0" dark color="primary">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="OnClose" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			
			<v-card-text class="mt-8">
				<p class="font-weight-black">
					API Key - унікальний API ключ, який дозволяє ідентифікувати користувача та надає доступ до веб-сервісів.
				</p>

				<v-card-subtitle>Ваш ключ: {{ value }}</v-card-subtitle>

				<v-alert color="#2A3B4D" dense border="left" type="warning">
					Цей ключ приватний і не може передаватись іншим особам
				</v-alert>

				<p class="font-weight-black">
					Сервіси знаходяться за адресою:<br>
					<code>{{ url }}</code>
				</p>

				<p class="font-weight-black">
					Для того, щоб взаємодіяти з веб-сервісом необхідно завжди передавати цей ключ в якості параметра URL-адреси.
				</p>
				<p class="font-weight-black">
					Наприклад:<br>
					<code>GET {{ href }}</code>
				</p>


				<z-alert ref="alert"></z-alert>
			</v-card-text>
			<v-card-actions>
				<v-btn text color="primary" target="_blank" :href="href">API Документація</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="success" @click.native="OnClose">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
			</v-card-actions>
		</v-card>

		<!-- <v-overlay :value="loading">
			<v-progress-circular
				:size="70"
				:width="5"
				color="zcolor"
				indeterminate
			></v-progress-circular>
		</v-overlay> -->
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import zAlert from '@/views/components/alert'

export default {
	components: {
		zAlert
	},
	data: () => ({
		title: 'API Key',
		//icon: 'mdi-key',
		dialog: false,
		loading: false,

		value: null,
		url: process.env.VUE_APP_DEVELOPERS
	}),
	props: {
		
	},
	watch: {
		/* dialog: function (val) {
			if(val) {
				//this.OnLoad()
				//console.log('profile.vue', 'watch', 'dialog', val)
			}
		}, */
	},
	computed: {
		...mapGetters('apikey',[
			'icon',
		]),

		href () {
			return this.url + '/doc?apikey='+this.value;
		}
	},
	methods: {
		...mapActions('apikey', [
			'Get', 'Change', 'Delete'
		]),

		Open () {
			this.dialog = true;

			var that = this;
			this.loading = true;

			this.Get({
				success: function(response) {
					that.value = response;
					that.loading = false;
					//that.OnClose();
				},
				error: function(response) {
					that.dialog = false
					//that.$refs.alert.Open(response.data, 'error')
				}
			});
		},

		OnClose () {
			this.dialog = false;
		},

		OnCancel () {
			this.OnClose();
		},

		OnOK () {
			this.value = null;
			this.$refs.alert.Close();
			this.OnClose();
		},
	},
}
</script>
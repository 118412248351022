<template>
	<v-container>
		<v-card elevation="0" :loading="loading">
			<v-toolbar color="primary" dark flat >
				<v-icon>{{ icon }}</v-icon>
				<v-toolbar-title class="mx-3">{{ $vuetify.lang.t('$vuetify.' + title.plural) | capitalize }}</v-toolbar-title>
				
				<v-spacer></v-spacer>
				
				<!-- Експорт -->
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="Export">
							<v-icon>mdi-export</v-icon>
						</v-btn>
					</template>
					<span>{{ $vuetify.lang.t('$vuetify.export') | capitalize }}</span>
				</v-tooltip> -->

				<!-- Імпорт -->
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="$refs.import.$refs.input.click()">
							<v-icon>mdi-import</v-icon>
						</v-btn>
					</template>
					<span>{{ $vuetify.lang.t('$vuetify.import') | capitalize }}</span>
				</v-tooltip>

				<v-file-input class="d-none" ref="import" v-model="file" @change="Import" accept="application/json"></v-file-input>
 				-->

				<v-divider vertical inset></v-divider>

				<!-- Створити -->
				<detail :module="module" mode="create" :selected="selected"></detail>

				<v-divider vertical inset></v-divider>

				<!-- Оновити -->
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="refresh">
							<v-icon>mdi-autorenew</v-icon>
						</v-btn>
					</template>
					<span>{{ $vuetify.lang.t('$vuetify.refresh') | capitalize }}</span>
				</v-tooltip>
			</v-toolbar>

			<v-card-text>
				<component ref="component" 
					v-bind:is="currentComponent" 
					:module="module"
					:items="items"
					:headers="headers"
					:multiple="multiple"
					movable
					removable
					editable
					creatable
					@selected="selected=$event"
					@remove="remove"
				></component>
				<!-- <v-data-table
					:headers="headers"
					:items="items"
					elevation="0"
					:loading="loading"
    				loading-text="Loading... Please wait"
				>
					<template v-for="(item, index) in headers" v-slot:[`header.${item.value}`]="{  }">
						<span :key="index">{{ $vuetify.lang.t('$vuetify.' + item.text) | capitalize }}</span>
					</template>

					<template v-slot:[`item.datecreate`]="{ item }">
						<span>{{ item.datecreate | yyyy-mm-dd }}</span>
					</template>

					<template v-slot:[`item.icon`]="{ item }">
						<v-icon>{{ item.icon }}</v-icon>
					</template>

					<template v-slot:[`item.actions`]="{ item }">
						<confirm @agree="remove(item)"></confirm>
						<detail :module="module" mode="update" activator="icon" :selected="item"></detail>
					</template>
					<template v-slot:no-data>
					</template>
				</v-data-table> -->
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import tableComponent from '@/views/components/table'
import listComponent from '@/views/components/list'
import treeComponent from '@/views/components/tree'

//import confirm from '@/views/components/confirm'
import detail from '@/views/components/detail'

export default {
	//components: { confirm, detail },
	components: { tableComponent, listComponent, treeComponent, detail },
	data: () => ({
		loading: 	false,
		multiple: 	false,
		
		selected: [],

		search: {
			text: '',
			visible: false
		},

		/* movable:		true,	// Ознака наявності функції зміни позиції
		removable:		true,	// Ознака наявності функції видалення
		editable:		true,	// Ознака наявності функції редагування
		creatable:		true,	// Ознака наявності функції створення */
	}),

	props: {
		module: 		{ type: String, default: null }, 	// users | roles | ....
		type: 			{ type: String, default: 'table' }, // table | tree | ....
		itemsName:		{ type: String, default: 'items' },	// Назва масиву даних
	},

	computed: {
		title: function () {
            return this.$store.getters[this.module + '/title']
        },
		icon: function () {
            return this.$store.getters[this.module + '/icon']
        },
		headers: function () {
            return this.$store.getters[this.module + '/headers']
        },
		items: function () {
            return this.$store.getters[this.module + '/' + this.itemsName]
        },

		itemsFiltered() {
			return this.items.filter(item => {
				if(!this.search.text) return this.items;
				return (
					item.code.toLowerCase().includes(this.search.text.toLowerCase())	||
					item.name.toLowerCase().includes(this.search.text.toLowerCase())	||
					item.description.toLowerCase().includes(this.search.text.toLowerCase())
				);
            })
        },

		/* currentDetail: function() {
			return this.module+"Detail";
		} */

		currentComponent: function() {
			return this.type + 'Component';
		}
	},
	watch: {
		/* selected: function (val) {
			console.log('page.vue', 'selected: ', val)
		}, */
	},
	created () {
		//console.log('page', 'computed', 'module: ', this.module)
		//console.log('page', 'created', 'items: ', this.items)

		if(this.items) {
			if (this.items.length == 0) {
				this.refresh()
			}
		}
	},
	methods: {
		refresh () {
			this.loading = true;
			let that = this
			this.$store.dispatch(`${this.module}/search`, {
				success: function(response) {
					that.loading = false;
				},
				error: function(response) {
					that.loading = false
				}
			});
		},

		create(item) {
			this.loading = true

			let that = this
			this.$store.dispatch(`${this.module}/create`, {
				item: 		item,
				success: function(response) {
					that.loading = false
				},
				error: function(response) {
					that.loading = false
				},
			});
		},

		update(item, selected) {
			this.loading = true

			let that = this
			this.$store.dispatch(`${this.module}/update`, {
				item: 		item,
				selected: 	selected,
				success: function(response) {
					that.loading = false
				},
				error: function(response) {
					that.loading = false
				},
			});
		},

		remove(item) {
			//console.log('page.vue.','remove','item:', item)
			this.loading = true;
			let that = this
			this.$store.dispatch(`${this.module}/delete`, {
				item: item,
				success: function(response) {
					that.loading = false;
				},
				error: function(response) {
					that.loading = false
				}
			})
		},
	},
}
</script>
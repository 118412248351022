<template>
	<v-form ref="form">
		<v-row dense>
			<v-col  cols="12">
				<!-- <v-text-field
					v-model="item.code"
					:label="$vuetify.lang.t('$vuetify.code') | capitalize"
					:rules="[rules.required]"
				></v-text-field> -->
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.name') | capitalize"
					:rules="[rules.required]"
				></v-text-field>
				<v-textarea
					v-model="item.description"
					:label="$vuetify.lang.t('$vuetify.description') | capitalize"
					rows="1"
				></v-textarea>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import mixin from './mixins/form'

export default {
	mixins: [mixin],
	data () {
		return {

		}
	},
}
</script>
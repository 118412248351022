export default {
    namespaced: true,
    state: {
        name: 'keyboard',
        title: {
            single: 'keyboard',
            plural: 'keyboards'
        },
        icon: 'mdi-keyboard',

        item:  {},
        items: [],

        default: {
            id: null,
            name: null,
            ordinal: null,
            icon: null,
            description: null,
            type: null,
            buttons: [],
        },

        headers: [
            { text: 'id', value: 'id', sortable: true},
            //{ text: 'icon', value: 'icon', sortable: false},
            { text: 'name', value: 'name', sortable: true},
            { text: 'type', value: 'type.name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],

        tabs: [
			{ title: 'main',    type: 'form', module: 'keyboard' },
			{ title: 'buttons', type: 'keyboard', module: 'keyboardbutton', dataSource: 'buttons', offline: true },
		],
    },
}
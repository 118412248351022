<template>
	<v-main>
		<v-container>
			<v-btn icon color="primary" @click="$router.push('/')">
				<v-icon >mdi-home</v-icon>
			</v-btn>
			<v-card class="d-flex justify-center" elevation="0">
				<v-card elevation="0" width="600">
					<v-toolbar class="elevation-0">
						<v-icon class="mr-6">{{ icon }}</v-icon>
						<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.'+title) | capitalize }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>

					<v-card-text>
						<v-form ref="form">
							<!-- <v-text-field 
								prepend-icon="mdi-account"
								label="E-mail"
								type="text"
								v-model="item.username"
								:rules="[rules.required, rules.email]"
								required    
								@keyup.ctrl.90="OnDefaultLogin"
								@keyup.enter="OnOK"
							></v-text-field>
							<v-text-field 
								prepend-icon="mdi-lock"
								:label="$vuetify.lang.t('$vuetify.password') | capitalize"
								type="password"
								v-model="item.password"
								hint="At least 8 characters"
								min="8"
								required
								:rules="[rules.required]"
								@keyup.ctrl.90="OnDefaultLogin"
								@keyup.enter="OnOK"
							></v-text-field> -->
							<v-row>
								<v-col cols="12">
									<v-text-field 
										prepend-icon="mdi-account"
										label="E-mail"
										type="text"
										v-model="item.username"
										:rules="[rules.required, rules.email]"
										required    
										@keyup.ctrl.90="OnDefaultLogin"
										@keyup.enter="OnOK"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field 
										prepend-icon="mdi-lock"
										:label="$vuetify.lang.t('$vuetify.password') | capitalize"
										type="password"
										v-model="item.password"
										hint="At least 8 characters"
										min="8"
										required
										:rules="[rules.required]"
										@keyup.ctrl.90="OnDefaultLogin"
										@keyup.enter="OnOK"
									></v-text-field>
								</v-col>
							</v-row>
						</v-form>

						<z-alert ref="alert"></z-alert>

						<v-row>
							<!-- Забули пароль? -->
							<v-col cols="12" sm="6" md="6" class="text-left">
								<v-btn text  large color="black" dark :to="{name: 'passwordrecovery'}">
									{{ $vuetify.lang.t('$vuetify.passwordforgot') | capitalize }}
								</v-btn>
							</v-col>
							<!-- Заявка на підключення -->
							<v-col cols="12" sm="6" md="6" class="text-right">
								<v-btn outlined color="primary" :to="{name: 'connection'}">
									{{ $vuetify.lang.t('$vuetify.orderconnection') | capitalize }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions>
						<!-- <v-spacer></v-spacer> -->
						<v-btn 
							large block
							:loading="loading"
							color="success"
							dark
							@click.native="OnOk"
						>
							<v-icon left dark>mdi-check-bold</v-icon>
							OK
						</v-btn>
						<!-- <v-spacer></v-spacer> -->
					</v-card-actions>
				</v-card>
			</v-card>
		</v-container>
	</v-main>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters, mapActions } from 'vuex'
//import router from '../router'
import zAlert from '@/views/components/alert'

export default {
	components: {
		VueRecaptcha, zAlert
	},
	data: () => ({
		//title: 'authorization',
		title: 'signin',
		icon: 'mdi-account-key',
		loading: false,


		rules: {
			required: (value) => !!value || "Обов'язкове для заповнення.",
			email: (value) => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Некоректний e-mail.'
			},
			name: (value) => {
				const pattern = /^([a-zA-Zа-яА-ЯЇї'\- ]+)/
				return (value && value.length <= 100 && pattern.test(value)) || 'Дані некоректні '
			},
			phone: (value) => {
				const pattern = /^\+?3?8?(0\d{9})$/
				return (value === null || value.length == 0 || pattern.test(value)) || 'Номер телефону не вірний (+380961112233)'
			},
		},

		item: {
			username: null,
			password: null,
		},
	}),
	
	methods: {
		 ...mapActions('app',[
			'Login'
		]),

		...mapActions('recaptcha',{
			recaptchaExpired: 'Expired',
			recaptchaValidate: 'Validate',
			recaptchaError: 'Error',
		}),

		OnDefault () {
			//ctrl+z
			this.item.username     = "qwins@i.ua"
			this.item.password     = "qwerty"
		},

		OnOk() {
			//console.log("OnLogin");
			var that = this;
			
			this.$refs.alert.Close();

			if (this.$refs.form.validate()) {
				this.loading = true
				this.Login({
					item: this.item,
					success: function(response) {
						that.loading = false
						//router.push('/')
						that.$router.push('/')
					},
					error: function(response) {
						that.loading = false
						that.$refs.alert.Open(response.data, 'error')
					},
				});
			}
		},
	},
}
</script>

export default {
    namespaced: true,
    state: {
        name: 'replics',
        title: {
            single: 'replic',
            plural: 'replics'
        },
        icon: 'mdi-family-tree',

        items: [],
    },
}
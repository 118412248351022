<template>
	<v-dialog  
		v-model="dialog"
		max-width="400"
		@keydown.esc="cancel"
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<!-- activator - icon -->
			<v-tooltip bottom v-if="activator=='icon'">
				<template v-slot:activator="{ on: tooltip }">
					<v-icon :small="small" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{ acvicon }}</v-icon>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.' + tooltip) | capitalize }}</span>
			</v-tooltip>
			
			<!-- activator - list-item -->
			<v-list-item v-else-if="activator=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
				<v-list-item-icon>
					<v-icon :disabled="disabled">{{ acvicon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-title>{{ $vuetify.lang.t('$vuetify.' + tooltip) | capitalize }}</v-list-item-title>
			</v-list-item>

			<!-- activator - button -->
			<v-tooltip bottom v-else>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">
						<v-icon>{{ acvicon }}</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.' + tooltip) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card>
			<v-toolbar class="elevation-0" color="primary" dark flat style="flex: 0 1 auto;">
				<v-app-bar-nav-icon>
					<v-icon>mdi-delete</v-icon>
				</v-app-bar-nav-icon>
				<v-toolbar-title>
					{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}
					<span class="text-caption">{{ item.id }}</span>
				</v-toolbar-title>
				
				<v-spacer></v-spacer>
				
				<v-btn icon @click="cancel" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<p class="text-h6 text--primary" v-show="!!message" v-html="$vuetify.lang.t('$vuetify.' + message)"></p>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="cancel">
					{{ $vuetify.lang.t('$vuetify.not') }}
				</v-btn>
				<v-btn color="success" @click.native="ok">
					{{ $vuetify.lang.t('$vuetify.yes') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	data: () => ({
		dialog: false,
	}),
	props: {
		module: 		{ type: String, default: null },			// Модуль: users | roles | ....
		mode: 			{ type: String, default: 'remove' }, 		// Режим: create | update | remove
		title:       	{ type: String, default: 'confirm' },
		message:       	{ type: String, default: 'Are you sure you want to delete this record?' },
		tooltip:       	{ type: String, default: 'delete' },
		small:      	{ type: Boolean, default: false },
		disabled:   	{ type: Boolean, default: false },      	//Ознака неактивного елементу
		activator:  	{ type: String, default: 'button'},     	// Тип активатора вікна: button | icon | menu-item | list-item
		acvicon:       	{ type: String, default: 'mdi-delete' },
		item: 			{ type: Object, default(){ return {}} }
	},
	methods: {
		ok() {
			this.$emit('agree')
			this.dialog = false;

			/* switch(this.mode){
				case 'remove': this.remove(this.item); break;
			} */
		},
		cancel() {
			this.dialog = false;
		},

		remove(item) {
			if(item.hasOwnProperty('children')) {
				for(let i in item.children) {
					this.remove(item.children[i])
				}
			}

			this.loading = true;
			let that = this
			this.$store.dispatch(`${this.module}/delete`, {
				item: item,
				success: function(response) {
					that.loading = false;
					this.dialog = false;
				},
				error: function(response) {
					that.loading = false
				}
			})
		}
	},
}
</script>
//Приклад: Статуси документів в системі
//https://help.document.online/%D1%81%D1%82%D0%B0%D1%82%D1%83%D1%81%D0%B8-%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%96%D0%B2-%D0%B2-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%96.html
export default {
	namespaced: true,
	state: {
		name: 'contracts',
		title: {
			list: 'contracts',
			detail: 'contract',
		},
		icon: 'mdi-bookshelf',

		item:   {},
		items:  [],
	},
	getters: {
		
	},
	mutations: {
		
	},
	actions: {
		CheckIn (context, data) {
			context.commit('item');
			//console.log(context.getters.name, 'CheckIn','data', data)
			/* HTTP.get(context.getters.path, {params: data.params}).then(response => {
				//console.log(context.getters.name, 'search','response.data', response.data)
				context.commit('item', response.data)
				if(data.hasOwnProperty('success')) data.success(response.data);
			}, error => {
				//context.dispatch('Message', error, {root: true});
				if(data.hasOwnProperty('error')) data.error(error);
			}); */
			
			//console.log(context.getters.name, 'CheckIn','HTTP.baseURL', HTTP.baseURL)
			//console.log(context.getters.name, 'CheckIn','NODE_ENV', process.env.NODE_ENV)
			//console.log(context.getters.name, 'CheckIn','VUE_APP_URL', process.env.VUE_APP_URL)
			//console.log(context.getters.name, 'CheckIn','VUE_APP_API', process.env.VUE_APP_API)

			// Емуляція запиту
			setTimeout(function() {
				//cconsole.log("Hello World");
				var response = {
					data: {
						number: 'TT.1234567',
						date: '2021-01-01',
						datebegin: '2021-01-02',
						dateend: '2022-01-01',
						insurant: 'Insurant Ins Ins',
						code: data.params.code
					}
				}

				context.commit('item', response.data)

				//console.log('item', context.getters.item)
				
				if(data.hasOwnProperty('success')) data.success(response.data);
			}, 3000);
		},
	}
}
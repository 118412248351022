<template>
	<v-treeview 
		ref="tree"
		v-model="selected"
		:items="items"
		:active.sync="active"
		:selectable="multiple"
		selected-color="indigo"
		:selection-type="selectionType"
		return-object
		expand-icon="mdi-chevron-down"
		indeterminate-icon="mdi-minus-box"
		rounded
		hoverable
		activatable
		:open-all="openAll"
		@update:active="onActive"
	>
		<template v-slot:label="{ item }">
			<v-list-item two-line dense>
				<v-list-item-content>
					<!-- <v-list-item-title v-html="item[titleValue]"></v-list-item-title> -->
					<v-list-item-title v-html="item[titleValue]+' ('+String(item.id)+')'"></v-list-item-title>
					<v-list-item-subtitle v-text="item[subtitleValue]"></v-list-item-subtitle>
					<!-- <v-list-item-subtitle v-text="item.id"></v-list-item-subtitle> -->
				</v-list-item-content>
			</v-list-item>
		</template>
		<template v-slot:append="{ item, active, selected }">
			<div v-if="active || selected">
				<v-btn icon @click="moveItem(items, item, 'up')" v-if="active">
					<v-icon >mdi-arrow-up-bold</v-icon>
				</v-btn>
				<v-btn icon @click="moveItem(items, item, 'down')" v-if="active">
					<v-icon >mdi-arrow-down-bold</v-icon>
				</v-btn>
				<!-- <v-btn icon @click="$emit('up', item)" v-if="movable && active">
					<v-icon >mdi-arrow-up-bold</v-icon>
				</v-btn>
				<v-btn icon @click="$emit('down', item)" v-if="movable && active">
					<v-icon >mdi-arrow-down-bold</v-icon>
				</v-btn> -->

				<!-- <confirm @agree="$emit('remove', item)" v-if="removable"></confirm> -->
				<confirm :module="module" mode="remove" :item="item" @agree="remove(item)" v-if="removable"></confirm>
				<detail :module="module" mode="update" activator="icon" :selected="item" v-if="editable"></detail>
				<detail :module="module" mode="create" activator="icon" :parent="item" v-if="creatable"></detail>
			</div>
		</template>
	</v-treeview>
</template>

<script>
import confirm from '@/views/components/confirm'

export default {
	components: { 
		confirm, 
		detail: () => import('@/views/components/detail')
	},
	data: () => ({
		selected: [],
		active: [],
	}),
	props: {
		module: 		{ type: String, default: null },			// Модуль: users | roles | ....
		items:			{ type: Array, default: ()=>[] },
		multiple:		{ type: Boolean, default: true },
		titleValue:		{ type: String, default: 'name' },			// Назва поля для title
		subtitleValue:	{ type: String, default: 'description' },	// Назва поля для subtitle
		movable:		{ type: Boolean, default: false },			// Ознака наявності функції зміни позиції
		removable:		{ type: Boolean, default: false },			// Ознака наявності функції видалення
		editable:		{ type: Boolean, default: false },			// Ознака наявності функції редагування
		creatable:		{ type: Boolean, default: false },			// Ознака наявності функції створення
		icon:       	{ type: String, default: null },
		openAll:		{ type: Boolean, default: false },
		selectionType:	{ type: String, default: 'independent' }, // leaf | independent
	},
	methods: {
		onActive(items) {
			this.$store.dispatch(`${this.module}/selected`, items)
			this.$emit('selected', items)
		},
		
		getSelected () {
			return this.multiple ? this.selected : (this.active.length>0 ? this.active[0] : null)
		},

		moveItem (items, item, direction) {
			//console.log('tree.vue', 'moveItem', 'item', item)
			//console.log('tree.vue', 'moveItem', 'items', items)

			if(item.hasOwnProperty('parent')) {
				items = item.parent.children
			}

			const index = items.indexOf(item)
			if((direction == 'up' || direction == 'left') && index > 0) {
				items.splice(index-1, 0, items.splice(index, 1)[0]);
			}
			if((direction == 'down' || direction == 'right') && index < items.length) {
				items.splice(index+1, 0, items.splice(index, 1)[0]);
			}
			for(let i in items) {
				let ordinal = items[i]['ordinal']
				if(ordinal !== i) {
					items[i]['ordinal'] = i

					let id = items[i].id
					//console.log('tree.vue', 'moveItem', 'i', i)
					this.loading = true;
					let that = this
					this.$store.dispatch(`${this.module}/ordinal`, {
						item: {
							id: id,
							value: i
						},
						success: function(response) {
							that.loading = false;
						},
						error: function(response) {
							that.loading = false
						}
					});
				}
			}
		},

		remove (item) {
			if(item.hasOwnProperty('children')) {
				for(let i in item.children) {
					this.remove(item.children[i])
				}
			}

			this.loading = true;
			let that = this
			this.$store.dispatch(`${this.module}/delete`, {
				item: item,
				success: function(response) {
					that.loading = false;
				},
				error: function(response) {
					that.loading = false
				}
			})
		}
	}
}
</script>
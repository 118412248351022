var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"400"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.activator=='icon')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":_vm.small,"disabled":_vm.disabled}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v(_vm._s(_vm.acvicon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.tooltip))))])]):(_vm.activator=='list-item')?_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled}},'v-list-item',attrs,false),Object.assign({}, dialog)),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.acvicon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.tooltip))))])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabled}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v(_vm._s(_vm.acvicon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.tooltip))))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-0",staticStyle:{"flex":"0 1 auto"},attrs:{"color":"primary","dark":"","flat":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.title)))+" "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.item.id))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"text-h6 text--primary",domProps:{"innerHTML":_vm._s(_vm.$vuetify.lang.t('$vuetify.' + _vm.message))}})]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.not'))+" ")]),_c('v-btn',{attrs:{"color":"success"},nativeOn:{"click":function($event){return _vm.ok.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.yes'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
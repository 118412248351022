<template>
	 <v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		:transition="transition"
		:scrollable="scrollable"
		:width="width"
		:height="height"
		:max-width="maxWidth"
		:persistent="persistent"
	>
        <template v-slot:activator="{ on: dialog, attrs }">
			<!-- activator - icon -->
			<v-tooltip bottom v-if="activator=='icon'">
				<template v-slot:activator="{ on: tooltip }">
					<v-icon :small="small" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{ acvicon }}</v-icon>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.' + hint)  | capitalize }}</span>
			</v-tooltip>
			
			<!-- activator - list-item -->
			<v-list-item v-else-if="activator=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
				<v-list-item-icon>
					<v-icon :disabled="disabled">{{ acvicon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-title>{{ $vuetify.lang.t('$vuetify.' + title.plural)  | capitalize }}</v-list-item-title>
			</v-list-item>

			<!-- activator - button -->
			<v-tooltip bottom v-else>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">
						<v-icon>{{ acvicon }}</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.' + hint)  | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>

		<v-card class="elevation-0" height="512">
			<v-toolbar class="elevation-0" color="primary" dark flat style="flex: 0 1 auto;">
				<v-app-bar-nav-icon>
					<v-icon>{{ icon }}</v-icon>
				</v-app-bar-nav-icon>
				<v-toolbar-title>
					{{ $vuetify.lang.t('$vuetify.'+title.single) | capitalize }}
					<span class="text-caption">{{ version }}</span>
				</v-toolbar-title>
				
				<v-spacer></v-spacer>
				
				<v-btn icon @click="fullscreen=!fullscreen">
					<v-icon v-if="fullscreen">mdi-checkbox-multiple-blank-outline</v-icon>
					<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
				</v-btn>

				<v-btn icon @click="cancel" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

                <template v-slot:extension v-if="extension">
                    <slot name="extension"></slot>
                </template>
			</v-toolbar>

			<v-card-text>
				<slot name="context"></slot>
			</v-card-text>

			<!-- <v-divider></v-divider> -->
			
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="success" large :disabled="loading" @click.native="ok">
					<v-icon left dark>mdi-check-bold</v-icon>	
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
    </v-dialog>
</template>

<script>

export default {
    data: () => ({
		dialog: false,
		fullscreen: false,
	}),

    props: {
		mode: 			{ type: String, default: 'add' }, // add | create | update
        
        // Dialog
		scrollable: 	{ type: Boolean, default: true },
		hideOverlay: 	{ type: Boolean, default: false },
		width:      	{ type: Number, default: 800 },
		maxWidth:      	{ type: Number, default: 800 },
		height:     	{ type: Number, default: 1024 },
		maxHeight:     	{ type: Number, default: 1024 },
		transition: 	{ type: String, default: 'dialog-bottom-transition' },
		persistent: 	{ type: Boolean, default: true },
		

        // Activator
        activator:  	{ type: String, default: 'button'}, // Тип активатора вікна: button | icon | menu-item | list-item
        acvicon:       	{ type: String, default: null },
        hint:       	{ type: String, default: null },
        small:      	{ type: Boolean, default: false },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
        
		
		//title:      	{ type: String, default: null },
		title:      	{ type: Object, default: () => {} },
		icon:       	{ type: String, default: null },
		version:       	{ type: String, default: null },

        loading: 	    { type: Boolean, default: false },
        
		color:			{ type: String, default: null }, 		// Колір фону (card)

        extension: 	    { type: Boolean, default: false },
	},
    watch: {
        dialog: function (val) {
			if(val) {
                this.$emit('open')	
			}
		},
    },
    methods: {
		open () {
			this.dialog = true
            this.$emit('open')
		},
        close () {
			this.dialog = false
			this.$emit('close')
		},
		cancel () {
			this.$emit('cancel')
			this.close()
		},
		ok () {
			this.$emit('ok')
			//this.close()
		},
    }
}
</script>
<template>
	<z-dialog ref="dialog"
		:hint="mode"
		:acvicon="acvicon"
		:title="title"
		:icon="icon"
		:disabled="disabled"
		:version="item?item.id:null"
		extension
		@open="open"
		@ok="ok"
	>
		<template v-slot:extension>
			<v-tabs v-model="tabindex" align-with-title>
				<v-tabs-slider color="yellow"></v-tabs-slider>
				<v-tab v-for="item in tabs" :key="item.title">
					{{ $vuetify.lang.t('$vuetify.'+item.title) }}
				</v-tab>
			</v-tabs>

			<div v-for="(tab, index) in tabs" :key="index">
				<!-- Створити -->
				<detail v-if="tab.hasOwnProperty('actions') && tab.actions.includes('create') && tabindex==index"
					v-bind="tab"
					mode="create" 
					@create="create(item[tab.dataSource], $event)"
				></detail>

				<!-- Обрати з довідника -->
				<z-dictionary v-if="tab.hasOwnProperty('actions') && tab.actions.includes('add') && tabindex==index"
					v-bind="tab"
					:type="tab.hasOwnProperty('listview')?tab.listview:'list'"
					@add="addItem(item[tab.dataSource], $event)"
				></z-dictionary>
				<!-- :type="(tab.module=='scenarium'?'tree':'list')" -->
			</div>
		</template>

		<template v-slot:context>
			<v-tabs-items v-model="tabindex"  width='100%'>
				<v-tab-item v-for="(tab, index) in tabs" :key="index">
					<v-card flat v-if="tab.type=='form'">
						<v-card-text>
							<component ref="form" 
								v-bind:is="tab.hasOwnProperty('view') ? tab.view + 'Form' : currentForm" 
								:module="module"
							></component>
						</v-card-text>
					</v-card>

					<z-keyboard v-else-if="tab.type=='keyboard'"
						:module="tab.module"
						@change="keyboardChange"
					></z-keyboard>
					<!-- 
						:items="item[tab.dataSource]"
						:request="item"
						@change="keyboardChange"
						@change="$set(item, tab.dataSource, $event)" 
					
					-->

					<z-list v-else-if="tab.type=='list' && item"
						v-bind="tab"
						:items="item[tab.dataSource]"
						@remove="removeItem(item[tab.dataSource], $event)"
						@delete="deteleItem(item[tab.dataSource], $event, tab.dataSource)"
						@update="update(item[tab.dataSource], $event)"
						@create="create(item[tab.dataSource], $event)"
					></z-list>
					
					<div v-else>
						Тип змісту не визначено
					</div>
				</v-tab-item>
			</v-tabs-items>
		</template>
	</z-dialog>
</template>

<script>
import zDialog from '@/views/components/dialog'
import zList from '@/views/components/list'
import zKeyboard from '@/views/components/keyboard'
import zDictionary from '@/views/components/dictionary'

import Vue from 'vue'
//Глобальна реєстрація компонентів групи forms
const requireComponent = require.context('@/views/forms',
	true, // Обрабатывать или нет подкаталоги
	/\.(vue)$/i // Регулярное выражение для определения файлов базовых компонентов /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
	// Получение конфигурации компонента
	const componentConfig 	= requireComponent(fileName)
	const componentName 	= fileName.split('/').pop().replace(/\.\w+$/, '') + 'Form'
	
	//console.log('components registration', 'componentName', componentName)

	// Глобальная регистрация компонента
	Vue.component(
		componentName,
		// Поиск опций компонента в `.default`, который будет существовать,
		// если компонент экспортирован с помощью `export default`,
		// иначе будет использован корневой уровень модуля.
		componentConfig.default || componentConfig
	)
})

export default {
	components: { zDialog, zList, zKeyboard, zDictionary,
		detail: () => import('@/views/components/detail')
	},
	data () {
		return {
			dialog: false,
			fullscreen: false,
			loading: false,

			tabindex: 0,

			/* alert: {
				text: null,
				type: 'warning',
			}, */
		}
	},
	props: {
		module: 		{ type: String, default: null },		// Модуль: users | roles | ....
		mode: 			{ type: String, default: 'update' }, 	// Режим: create | update
		offline: 		{ type: Boolean, default: false },		// Режим без зв'язку з базою даних

		returnObject: 	{ type: Boolean, default: false }, 		// Повернення об'єкту
		noClear: 		{ type: Boolean, default: false },		//

		activator: 		{ type: String, default: 'button' }, 	//Тип активатора: button | icon | list-item
		small: 			{ type: Boolean, default: false },		//Розмір значка активатора: button | icon | list-item
		disabled: 		{ type: Boolean, default: false },		//Вимкнення значка активатора: button | icon | list-item

		selected: 		{ type: [Object, Array], default: null },		// Поточний (виділений) елемент
		parent: 		{ type: Object, default: null },		// Батьківський елемент
		neighbor: 		{ type: Object, default: null },		// Сусідній елемент
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.open()	
			}
		},
		module: function (val) {
			//console.log('detail.vue', 'watch', 'module', val)
		},
	},

	computed: {
		title: function () {
			return this.$store.getters[this.module + '/title']
		},

		icon: function () {
			return this.$store.getters[this.module + '/icon']
		},

		tabs: function () {
			return this.$store.getters[this.module + '/tabs']
		},

		item: function () {
			return this.$store.getters[this.module + '/item']
		},

		acvicon() {
			switch(this.mode){
				case 'create': return 'mdi-plus'
				case 'update': return 'mdi-pencil'
				default: return this.icon
			}
		},

		currentForm: function() {
			return this.module + 'Form';
		},

		/* keyboard: function() {
			if(this.item[this.tab.dataSource].length) {
				return this.item[this.tab.dataSource][0]
			}
		}, */
	},
	created () {
		//console.log('detail.vue', this.module, 'created()')
		//this.clear()
		//this.item = Object.assign({}, this.default);
	},
	methods: {
		open() {
			//console.log('detail.vue', this.module, 'open()')
			this.clear()
			
			if(this.selected) {
				//console.log('detail.vue', 'open', 'offline', this.offline)
				//console.log('detail.vue', 'open', 'selected', this.selected)

				if(this.offline) {
					this.$store.dispatch(`${this.module}/select`, this.selected)
					//this.$store.dispatch(`${this.module}/item`, this.selected)
				} else {
					this.getItem(this.selected.id)
				}
			}

			/* switch(this.mode) {
				case 'create': 
					this.$store.dispatch(`${this.module}/clear`)
					break
				case 'update': 
					break
			} */
		},
		clear() {
			if(!this.noClear) {
				this.$store.dispatch(`${this.module}/clear`)
			}
		},

		default () {
			//ctrl+z
		},

		getItem(id) {
			if(id) {
				this.loading = true;
				var that = this;
				this.$store.dispatch(`${this.module}/find`, {
					item: this.selected,
					success: function(response) {
						//console.log('scenarium.detil', 'load', that.module, 'response: ', response)
						that.loading = false;
					},
					error: function(response) {
						that.loading = false;
					},
				})
			} else {
				this.clear()
			}
		},
		
		ok () {
			//console.log('mixin.detil', 'ok', 'refs.form: ', this.$refs.form[0])
			//console.log('mixin.detil', 'ok', 'item: ', this.item)
			//return

			if (this.$refs.form[0].validate()) {
				if(this.offline) {
					this.$emit(this.mode, this.item)
					this.$refs.dialog.close()
				} else {
					this.loading = true;
					
					let that = this
					this.$store.dispatch(`${this.module}/${this.mode}`, {
						item: 		this.item,
						selected: 	this.selected,
						success: function(response) {
							that.loading = false;						
							
							that.$emit(that.mode, response)

							that.dialog = false;
							that.$refs.dialog.close()
						},
						error: function(response) {
							that.loading = false
						},
					})
				}
			}
		},

		cancel () {
			this.dialog = false;
			//this.clear()
		},

		create(items, item) {
			//console.log('detail.vue', 'create', 'item', item)
			items.push(item)
		},

		update(items, item) {
			const index = items.indexOf(this.selected)
			items.splice(index, 1, item)
		},

		/**
		 * Додати елемент до списку
		 * 
		 * @param {*} items 
		 * @param {*} item 
		 */
		addItem(items, item) {
			//console.log('detail', 'addItem', 'items: ', items)
			//console.log('detail', 'addItem', 'item: ', item)
			//console.log('detail', 'addItem', 'this.item: ', this.item)

			if(Array.isArray(item)) {
				for(let i in item) {
					if(items.length) {
						if(!items.some(elem => String(elem.id) === item[i].id)) {
							//items.push(item[i])
							this.pushItem(items, item[i])
						}
					} else {
						//items.push(item[i])
						this.pushItem(items, item[i])
					}
				}
			} else {
				this.pushItem(items, item)
				//items.push(item)
			}
		},

		pushItem(items, item) {
			if(this.offline) {
				items.push(item)
			} else {
				let obj = {}
				let fields = ['id', 'code', 'name', 'description', 'username', 'login']
				for(let i in fields) {
					let field = fields[i]
					if(item.hasOwnProperty(field)) {
						obj[field] = item[field]
					}
				}
				items.push(obj)
			}
		},

		/**
		 * Видалення зі списку
		 * 
		 * @param {*} items 
		 * @param {*} item 
		 */
		removeItem (items, item) {
			console.log('detail.vue', 'removeItem', 'item', item)
			items.splice(items.indexOf(item), 1);
		},

		/**
		 * Видалення з бази
		 * 
		 * @param {*} items 
		 * @param {*} item 
		 * @param {*} name 
		 */
		deteleItem (items, item, name) {
			console.log('detail.vue', 'deteleItem', 'item', item)
			if(name) {
				if(this.item.id) {
					this.loading = true;

					let master 	= this.item
					let slave 	= {
						name: name,
						item: item,
						items: items
					}

					let that = this
					this.$store.dispatch(`${this.module}/remove`, {
						master:		master,
						slave:		slave,
						success: function(response) {
							that.removeItem(items, item);
							that.loading = false;
						},
						error: function(response) {
							that.loading = false
						},
					})
				} else {
					this.removeItem(items, item);
				}
			} else {
				this.removeItem(items, item);
			}
		},

		/* tableAdd(items, item) {
			console.log('detail', 'tableAdd', 'item: ', item)
			items = item
		}, */

		keyboardChange(item) {
			console.log('detail.vue', 'keyboardChange', 'item: ', item)


			//items = item

			//this.$set(this.item, this.tab.dataSource, item)
		},
	},
}
</script>
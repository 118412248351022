<template>
	<v-dialog v-model="dialog" persistent max-width="900px" scrollable>
		<template v-slot:activator="{ on: dialog, attrs }">
			<!-- activator - icon -->
			<v-tooltip bottom v-if="activator=='icon'">
				<template v-slot:activator="{ on: tooltip }">
					<v-icon :small="small" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{acvicon}}</v-icon>
				</template>
				<span>{{ tooltip | capitalize }}</span>
			</v-tooltip>
			
			<!-- activator - list-item -->
			<v-list-item v-else-if="activator=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
				<v-list-item-icon>
					<v-icon :disabled="disabled">{{acvicon}}</v-icon>
				</v-list-item-icon>
				<v-list-item-title>{{ title | capitalize }}</v-list-item-title>
			</v-list-item>

			<!-- activator - button - menu-->
			<v-tooltip bottom v-else-if="activator=='menu'">
				<template v-slot:activator="{ on: tooltip }">
					<!-- <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">
						<v-icon>{{ avicon }}</v-icon>
					</v-btn> -->
					<v-menu offset-y>
							<template v-slot:activator="{ on: menu, attrs }">
								<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog, ...menu }">
									<v-icon>{{ avicon }}</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<!-- <v-list-item v-for="(item, index) in types" :key="index" v-on="{ ...dialog, ...clickMenu(item) }"> -->
								<v-list-item v-for="(type, index) in types" :key="index" @click="clickMenu(type)">
									<v-list-item-icon>
										<v-icon v-text="type.icon"></v-icon>
									</v-list-item-icon>
									<!-- <v-list-item-title>{{ $vuetify.lang.t('$vuetify.'+item.title) | capitalize }}</v-list-item-title> -->
									<v-list-item-title>{{ type.label | capitalize }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
				</template>
				<span>{{ tooltip | capitalize }}</span>
			</v-tooltip>

			<!-- activator - button -->
			<v-tooltip bottom v-else>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">
						<v-icon>{{ avicon }}</v-icon>
					</v-btn>
				</template>
				<span>{{ tooltip | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>

		<v-card class="elevation-0">
			<v-toolbar class="elevation-0" color="primary" dark flat>
				<v-app-bar-nav-icon>
					<v-icon>{{ icon }}</v-icon>
				</v-app-bar-nav-icon>
				<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.'+title) | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="cancel" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<!-- <template v-slot:extension>
					

					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>

						<v-tab v-for="item in tabs" :key="item.title">
							{{ $vuetify.lang.t('$vuetify.'+item.title) }}
						</v-tab>
					</v-tabs>
				</template> -->
			</v-toolbar>

			<v-card-text  style="height: 300px;">

				<v-form ref="form" v-if="currentType">
					<v-row>
						<v-col  cols="12" md="4">
							<v-row justify="space-around" class="mt-6">
								<!-- <v-file-input v-model="image" @change="previewImage"></v-file-input>
								<v-img contain :src="url"
									max-height="150"
									max-width="250"
								></v-img> -->

								<template v-for="(comp, index) in ['photo','document','audio','sticker','video','animation','voice','media']">
									<attachment v-if="type(currentType.value).components.includes(comp)" :key="index"
										@change="item[comp]=$event"
									></attachment>
								</template>
							</v-row>
							
						</v-col>
						<v-col  cols="12"  md="8">
							<!-- <v-row>
								<v-col  cols="12">
									
								</v-col>
							</v-row> -->
						
							<v-textarea v-if="type(currentType.value).components.includes('text')"
								v-model="item.text"
								label="Текст"
								prepend-icon="mdi-comment"
								rows="6"
							></v-textarea>

							<v-textarea v-if="type(currentType.value).components.includes('caption')"
								v-model="item.caption"
								label="Caption"
								prepend-icon="mdi-comment"
								rows="1"
							></v-textarea>

							<v-text-field v-if="type(currentType.value).components.includes('title')"
								v-model="item.title"
								label="Title"
							></v-text-field>

							<v-text-field v-if="type(currentType.value).components.includes('duration')"
								v-model="item.duration"
								label="Duration"
								type="number"
							></v-text-field>

							<v-row>
								<v-col  cols="12" md="6" v-if="type(currentType.value).components.includes('firstName')">
									<v-text-field 
										v-model="item.firstName"
										label="First Name"
									></v-text-field>
								</v-col>

								<v-col  cols="12" md="6" v-if="type(currentType.value).components.includes('lastName')">
									<v-text-field
										v-model="item.lastName"
										label="Last Name"
									></v-text-field>
								</v-col>

								<v-col  cols="12" md="6" v-if="type(currentType.value).components.includes('phoneNumber')">
									<v-text-field
										v-model="item.phoneNumber"
										label="Phone Number"
									></v-text-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col  cols="12" md="6" v-if="type(currentType.value).components.includes('latitude')">
									<v-text-field
										v-model="item.latitude"
										label="Latitude"
									></v-text-field>
								</v-col>

								<v-col  cols="12" md="6" v-if="type(currentType.value).components.includes('longitude')">
									<v-text-field
										v-model="item.longitude"
										label="Longitude"
									></v-text-field>
								</v-col>
							</v-row>

							<v-text-field v-if="type(currentType.value).components.includes('address')"
								v-model="item.address"
								label="Address"
							></v-text-field>

							

							<v-checkbox
								v-model="item.disableNotification"
								label="Disable Notification"
								:false-value="false"
								:true-value="true"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<!-- <v-divider></v-divider> -->
			
			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn color="success" large 
					:disabled="loading"
					@click.native="ok"
				>
					<v-icon left dark>mdi-check-bold</v-icon>	
					OK
				</v-btn> -->
				<v-btn icon large :disabled="loading" @click.native="send">
					<v-icon>mdi-send</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>

	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//import zKeyboard from '@/views/components/keyboard/keyboard'
import attachment from '@/views/components/attachment'

export default {
	components: {
		//zKeyboard, 
		attachment
	},
	data: () => ({
		dialog: false,
		loading: false,

		title: 'request', 
		icon: 'mdi-chat-question',

		currentType: null,

		
		//file: null,

		//url: null,
      	//image: null,
		//tooltip: 'message',
		//avicon: 'mdi-dots-vertical',

		/* tab: 0,
		tabs: [
			{ title: 'main' },
			{ title: 'keyboard' },
		], */
		/* rules: {
			required: (value) => !!value || "Обов'язкове для заповнення.",
			name: (value) => {
				const pattern = /^([a-zA-Zа-яА-ЯЇї'\-]+)/
				return (value && value.length <= 10 && pattern.test(value)) || 'Name is not correct'
			},
			email: (value) => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Некоректний e-mail.'
			},
			phone: (value) => {
				const pattern = /^\+?3?8?(0\d{9})$/
				return (value === null || value.length == 0 || pattern.test(value)) || 'Номер телефону не вірний (+380961112233)'
			},
		}, */
		
		item: {},

	}),
	props: {
		/* title: {
			type: String,
			default: 'update' // create | update
		}, */
		tooltip: {
			type: String,
			default: 'message'
		},
		activator: {
			type: String,
			default: 'menu' // button | icon | list-item | menu
		},
		avicon: {
			type: String,
			default: 'mdi-dots-vertical'
		},
		
		
		disabled: {
			type: Boolean,
			default: false
		},

		small: Boolean,

		/* selected: {
			type: Object,
			default: null
		}, */
	},
	watch: {
		dialog: function (val) {
			if(val) {
				//this.load()
			}

			//console.log('request.watch.dialog', this.item)
		},

		/* 'item.type': function (val) {
			if(val) {
				//console.log('item.type', val)
			}
		}, */
	},
	computed: {
		...mapGetters('scenarium', [ 'types', 'type', 'itemstree' ]),
		//...mapGetters('scenarium', [ 'types', 'type' ]),

		/* acvicon() {
			switch(this.mode){
				case 'create': return 'mdi-plus'
				case 'update': return 'mdi-pencil'
				default: return this.icon
			}
		}, */


		/* settings() {
			if(this.item && this.item.hasOwnProperty('settings')) {
				var content = JSON.parse(this.item.settings);
				return content
			}
		}, */

		/* InlineKeyboardMarkup() {
			if(this.settings && this.settings.hasOwnProperty('InlineKeyboardMarkup')) {
				return this.settings.InlineKeyboardMarkup
			}
		}, */

		/* image() {
			if(this.item.image) {
				return process.env.VUE_APP_URL + process.env.VUE_APP_PROFILES_UPLOADS+'/'+this.item.image;
			} else {
				return require('../assets/no-image.png')
				//'https://cdn.vuetifyjs.com/images/profiles/marcus.jpg'
			}
		}, */
	},
	created () {
		//this.clear()
		//this.item = Object.assign({}, this.default);
	},
	methods: {
		//...mapActions('scenarium',['clear','find', 'create', 'update', 'save']),
		//...mapActions('scenarium',['upload']),
		default () {
			//ctrl+z
		},

		clickMenu (type) {
			this.currentType = type
			this.title = type.label
			this.icon = type.icon
			this.dialog = true
			//console.log('clickMenu','item', item)
		},

		

		/* previewImage() {
			this.url= URL.createObjectURL(this.image)
		}, */

		clear() {
			//this.tab = 0
			this.item = {
				type: 'text',
				keyboard: null
			}
		},

		/* load() {
			if(this.selected) {
				this.item = Object.assign({}, this.selected);

				console.log('request.load','item', this.item)
			} else {
				this.clear()
			}
		}, */
		
		send () {
			this.$emit('send', this.item);
			this.dialog = false;
		},

		ok () {
			//console.log('request.actions.ok - item:', this.item)

			if (this.$refs.form.validate()) {
				if(this.$refs.hasOwnProperty('keyboard')) {
					this.item['keyboard'] = this.$refs.keyboard.keyboard
					//console.log('request.methods.ok - keyboard:', this.$refs.keyboard)
					//console.log('request.methods.ok - keyboard:', this.$refs.keyboard.keyboard) // getKeyboard())
				}
				
				this.$emit(this.mode, this.item);

				this.dialog = false;
			}
			
			//console.log('profiles.actions.ok - settings:', settings)
			//console.log('profiles.actions.ok - settings:', JSON.stringify(settings))
			//console.log('profiles.actions.ok - item:', this.item)
		},

		cancel () {
			this.dialog = false;
			this.clear()
		},

		upload (data) {
			var that = this;
			//console.log('changeFile: item', item)
			//console.log('changeFile: file', file)

			this.item.filename 		= data.filename
			this.item.filepathname 	= data.filepathname
		},

		clearFile () {
			this.item.filename 		= null
			this.item.filepathname 	= null
		},

		/* OnImageClick() {
            window.addEventListener('focus', () => {
                //console.log('OnImageClick: focus')
            }, { once: true })

            this.$refs.uploader.click()
        },
        OnImageChanged(e) {
            var that = this;

            //console.log('item', this.item)
            var file = e.target.files[0]
            //console.log('file', file)

            let formData = new FormData();
            formData.append('file', file);

            this.loading = true;
            this.Upload({
                id: this.item.id,
                params: formData,
                success: function(response) {
                    that.SetImage(response)
                    that.loading = false;
                    that.alert.text = null;

                    //console.log('OnImageChanged.item', that.item)
                },
                error: function(response) {
                    that.loading = false
                    that.alert.text = response.data;
                },
            });
        } */

		/* OnImageChanged(e) {
			var that = this;

			//console.log('item', this.item)
			var file = e.target.files[0]
			//console.log('file', file)

			let formData = new FormData();
			formData.append('file', file);

			this.loading = true;
			this.Upload({
				id: this.item.id,
				params: formData,
				success: function(response) {
					that.loading = false;
					that.alert.text = null;

					//console.log('OnImageChanged.item', that.item)
				},
				error: function(response) {
					that.loading = false
					that.alert.text = response.data;
				},
			});
		} */
	},
}
</script>
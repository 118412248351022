<template>
    <v-alert 
        :type="type"
        :outlined="outlined"
        :dismissible="dismissible"
        :value="visible"
    >
        {{ text }}
    </v-alert>
</template>

<script>

export default {
	data () {
		return {
            text: null,
            type: 'success',
            visible: false,
		}
	},
	props: {
        outlined:	    { type: Boolean, default: true},
        dismissible:	{ type: Boolean, default: true},
	},
	methods: {
        Open (text, type) {
            this.text = text
            this.type = type
            this.visible = true
        },
        Close () {
            this.text = null
            this.type = 'success'
            this.visible = false
        },
    },
}
</script>
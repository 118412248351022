export default {
	data () {
		return {
			
		}
	},
	props: {
		module: 	{ type: String, default: null },			// Модуль: users | roles | ....
		mode: 		{ type: String, default: 'update' }, 		// Режим: create | update
		selected: 	{ type: [Object, Array], default: null },	// Поточний (виділений) елемент
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.open()
			}
		},
	},

	computed: {
		item: function () {
			//console.log('mixin.form', this.module, 'item: ', this.$store.getters[this.module + '/item'])
			return this.$store.getters[this.module + '/item']
		},

		types: function () {
			return this.$store.getters[this.module + '/types']
		},

		rules() {
			return {
				required: (value) => !!value || this.$vuetify.lang.t('$vuetify.rule.required'),
				code: (value) => {
					const pattern = /^([a-zA-Z'\-]+)/
					return (value === null || value.length == 0 || value.length > 32 || pattern.test(value)) || this.$vuetify.lang.t('$vuetify.rule.code', [128])
				},
				name: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯЇї'\-]+)/
					return (value && value.length <= 10 && pattern.test(value)) || 'Name is not correct'
					//return value === null || ( value.length <= 10 && pattern.test(value)) || 'Name is not correct'
				},
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || this.$vuetify.lang.t('$vuetify.rule.email')//'Некоректний e-mail.'
				},
				phone: (value) => {
					const pattern = /^\+?3?8?(0\d{9})$/
					return (value === null || value.length == 0 || pattern.test(value)) || 'Номер телефону не вірний (+380961112233)'
				},
				personsname: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯЇї'\- ]+)/
					return (value && value.length <= 10 && pattern.test(value)) || this.$vuetify.lang.t('$vuetify.rule.personsname')//'Name is not correct'
				},
			};
		},
	},
	methods: {
		open() {
			this.clear()
			if(this.selected) {
				//console.log('mixin.form', 'open', 'this.selected: ', this.selected)
				//this.getItem(this.selected.id)
			}
		},
		clear() {
			//console.log('mixin.form', 'clear')
			this.$store.dispatch(`${this.module}/clear`, {})
		},

		default () {
			//ctrl+z
		},

		validate () {
			return this.$refs.form.validate()
		},
	},
}
export default {
    namespaced: true,
    state: {
        name: 'accounts',
        title: {
            single: 'account',
            plural: 'accounts'
        },
        icon: 'mdi-account-multiple',

        item:  {},
        items: [],

        default: {
            id: null,
            username: null,
            login: null,
            description: null,
            roles: [],
        },

        headers: [
            { text: 'id' , value: 'id', align: ' d-none' },
            { text: 'username' , value: 'username', sortable: true},
            { text: 'login', value: 'login', sortable: true},
            { text: 'roles', value: 'roles', align: ' d-none'},
            { text: 'description', value: 'description', sortable: true},
            { text: 'datecreate', value: 'datecreate', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],
        
        tabs: [
			{ title: 'main',    type: 'form', module: 'accounts' },
			{ title: 'roles',   type: 'reference', module: 'roles', dataSource: 'roles', delatiing: true },
		],

        itemTitle: 'username',
        itemSubtitle: 'login',
    },
}
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { createStore } from 'vuex-extensions' //https://www.npmjs.com/package/vuex-extensions

import accounts from './modules/accounts'
import apikey from './modules/apikey'
import app from './modules/app'
import bot from './modules/bot'
import botcommand from './modules/botcommand'
import chats from './modules/chats'
import chatstypes from './modules/chatstypes'
import contracts from './modules/contracts'
import datatype from './modules/datatype'
import dialogs from './modules/dialogs'
import funcs from './modules/funcs'
import functions from './modules/functions'
import functype from './modules/functype'
import keyboard from './modules/keyboard'
import keyboardtype from './modules/keyboardtype'
import keyboardbutton from './modules/keyboardbutton'
import menu from './modules/menu'
import menutype from './modules/menutype'
import messages from './modules/messages'
import messagestypes from './modules/messagestypes'
import photos from './modules/photos'
import profiles from './modules/profiles'
import replics from './modules/replics'
import roles from './modules/roles'
import scenarium from './modules/scenarium'
import scenariumrequest from './modules/scenariumrequest'
import scenariumrespond from './modules/scenariumrespond'
import services from './modules/services'
import users from './modules/users'

Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_API;
/*
axios.defaults.headers = {
	'Content-Type': 'application/json',
	//'Access-Control-Allow-Origin': '*',
	//'Access-Control-Allow-Credentials': 'true',
	//'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
};
*/

export default createStore(Vuex.Store, {
	state: {
		name: 'main',
		title: {
			single: 'role',
			plural: 'roles'
		},
		icon: 'mdi-account-tie',

		message: {
			type: 'success',            
			text: null,
		},

		item: null,
		items: [],
		selected: null,

		headers: [
            { text: 'id', value: 'id', sortable: true},
            { text: 'code', value: 'code', sortable: true},
            { text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],
		tabs: [],
	},
	getters: {
		name(state) {
			return state.name;
		},
		module(state) {
			return state.name;
		},
		path(state) {
			return '/'+state.name;
		},
		icon(state) {
			return state.icon;
		},
		title(state) {
			return state.title;
		},
		item(state) {
			return state.item;
		},
		items(state) {
			return state.items;
		},
		headers(state) {
			return state.headers;
		},
		tabs(state) {
			return state.tabs;
		},
/*
		message(state) {
			return state.message;
		}, */

		/* modes(state) {
			return state.modes;
		}, */
	},
	mutations: {
		items (state, data) {
			state.items = data?data:[]
		},

		insert (state, data) {
			if(Array.isArray(data)) {
				for(var i in data) {
					state.items.push(data[i]);
				}
			} else {
				state.items.push(data);
			}
		},
		update (state, data) {
			if(data) {
				if(data.hasOwnProperty('old') && data.hasOwnProperty('new')) {
					const index = state.items.indexOf(data.old)
					state.items.splice(index, 1, data.new)
				}
			}
		},
		delete (state, data) {
			if(Array.isArray(data)) {
				for(let i in data) {
					const index = state.items.indexOf(data[i])
					state.items.splice(index, 1);
				}
			} else {
				const index = state.items.indexOf(data)
				state.items.splice(index, 1);
			}
		},
		/* message (state, data) {
			state.message = data?data:{
				type: 'success',
				text: null,
			};
		}, */
	},
	actions: {
		Clear (context){
			//console.log(context.getters.name,'Clear')
			context.commit('items')
		},

		Create(context, data) {
			//console.log(context.getters.name,'Create', 'item', data.item)

			/* setTimeout(() => {
				if(data.hasOwnProperty('success')) data.success({});
			}, 3000) */
			axios.post(context.getters.path, data.item, data.config).then(response => {
				if(data.hasOwnProperty('success')) data.success(response.data);
			}, error => {
				//console.log(context.getters.name,'Create - error', error)
				context.dispatch('Message', error);
				if(data.hasOwnProperty('error')) data.error(error);
			});
		},

		Read(context, data) {
			console.log(context.getters.name,'Read', 'data', data)

			/* setTimeout(() => {
				if(data.hasOwnProperty('success')) data.success({});
			}, 3000) */
			axios.get(context.getters.path, {params: data.params}).then(response => {
				console.log(context.getters.name,'Read', 'response.data', response.data)
				context.commit('items', response.data);
				if(data.hasOwnProperty('success')) data.success(response.data);
			}, error => {
				//console.log(context.getters.name,'Read - error', error)
				//context.dispatch('Message', error);
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		},

		Update(context, data) {
			/* setTimeout(() => {
				if(data.hasOwnProperty('success')) data.success({});
			}, 3000) */
			axios.put(context.getters.path + '/' + data.item.id, data.item).then(response => {
				if(data.hasOwnProperty('success')) data.success(response.data);
			}, error => {
				context.dispatch('Message', error);
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		},

		Delete(context, data) {
			/* setTimeout(() => {
				if(data.hasOwnProperty('success')) data.success({});
			}, 1000) */
			axios.delete(context.getters.path + '/' + data.item.id).then(response => {
				if(data.hasOwnProperty('success')) data.success(response.data);
			}, error => {
				context.dispatch('Message', error);
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		},

		//-------------------------------------------------------------------------
		Message(context, data) {
			var message = data
			if(data.hasOwnProperty('response')) {
				let status = data.response.status

				//console.log(context.getters.name,'Message - status', status)

				switch(status) {
					case 401: message = {
						type: 'error',
						text: 'Ви не авторизовані в системі! Для подальшої роботи вам необхідно пройти процедуру авторизації.',
					}
					break; 
					default: message = {
						type: 'error',
						text: data.response.data.message,
					}
					//console.log(context.getters.name,'Message - response.data', data.response.data)
				}
			}
			context.commit('message', message);
		},
	},
	modules: {
		accounts,
		apikey,
		app,
		bot,
		botcommand,
		chats,
		chatstypes,
		contracts,
		datatype,
		dialogs,
		funcs,
		functions,
		functype,
		keyboard,
		keyboardtype,
		keyboardbutton,
		menu,
		menutype,
		messages,
		messagestypes,
		photos,
		profiles,
		replics,
		roles,
		scenarium,
		scenariumrequest,
		scenariumrespond,
		services,
		users,
	},

	mixins: {
		getters: {
			name(state) {
				return state.name;
			},
			module(state) {
				return state.name;
			},
			path(state) {
				return '/'+state.name;
			},
			title(state) {
				return state.title;
			},
			icon(state) {
				return state.icon;
			},
			default(state) {
				return state.default;
			},
			item(state) {
				return state.item;
			},
			items(state) {
				return state.items;
			},
			selected(state) {
				return state.selected;
			},
			headers(state) {
				return state.headers;
			},
			tabs(state) {
				return state.tabs;
			},
			types(state) {
				return state.types;
			},

			itemTitle(state) {
				if(state.hasOwnProperty('itemTitle')) {
					return state.itemTitle
				}
				return 'name';
			},

			itemSubtitle(state) {
				if(state.hasOwnProperty('itemSubtitle')) {
					return state.itemSubtitle
				}
				return 'description';
			},
			/* form(state) {
				return state.form;
			}, */
		},
		mutations: {
			name (state, data) {
				state.name = data;
			},
			item(state, data) {
				state.item = data?data:{}
			},
			items (state, data) {
				state.items = data?data:[]
			},
			types (state, data) {
				state.types = data
			},
			selected (state, data) {
				state.selected = data
			},
			insert (state, data) {
				if(Array.isArray(data)) {
					for(var i in data) {
						state.items.push(data[i]);
					}
				} else {
					state.items.push(data);
				}
			},
			update (state, data) {
				if(data) {
					if(data.hasOwnProperty('old') && data.hasOwnProperty('new')) {
						const index = state.items.indexOf(data.old)
						state.items.splice(index, 1, data.new)
					}
					//if(state.item && state.selected) {
					/* if(state.selected) {
						//console.log(state.name, 'update 1','item', state.item)
						//console.log(state.name, 'update 1','selected', state.selected)
						//console.log(state.name, 'update 1','items', state.items)
						//console.log(state.name, 'update 1','selected', state.selected)
						const index = state.items.indexOf(state.selected)
						state.items.splice(index, 1, data)
						//state.items[index] = data
						//state.selected = data
						//console.log(state.name, 'update 2','index', index)
						//console.log(state.name, 'update 2','items', state.items)
					} */
				}
			},
			delete (state, data) {
				if(Array.isArray(data)) {
					for(let i in data) {
						const index = state.items.indexOf(data[i])
						state.items.splice(index, 1);
					}
				} else {
					const index = state.items.indexOf(data)
					state.items.splice(index, 1);
				}
			},
		},
		actions: {
			clear(context) {
				//console.log('index.js', context.getters.name, 'clear')
				// var copy = Object.assign({}, obj);

				//deep copy
				context.commit('item', JSON.parse(JSON.stringify(context.getters.default)));
			},

			selected(context, data) {
				context.commit('selected', data);
			},

			// Встановити виділений елемент
			select(context, data) {
				context.commit('item', data);
				//console.log(context.getters.name, 'select', 'item', context.getters.item)
			},
			
			// Додати до списку
			add (context, data) {
				context.commit('insert', data)
			},

			remove (context, data) {
				axios.delete(context.getters.path + '/' + data.master.id + '/' + data.slave.name + '/' + data.slave.item.id).then(response => {
					//console.log(context.getters.name, 'delete', 'response.data', response.data)
					if(data.hasOwnProperty('success')) data.success(response);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'delete','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				})
			},

			//-------------------------------------------------------------------------------
			find(context, data) {
				//console.log(context.getters.name, 'find','data', data)
				//context.commit('selected', data.item);

				axios.get(context.getters.path + '/' + data.item.id, {params: data.params}).then(response => {
					//console.log(context.getters.name, 'find','id', data.item.id)
					//console.log(context.getters.name, 'find','response.data', response.data)
					
					context.commit('item', response.data);
					if(data.hasOwnProperty('success')) data.success(response.data);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'find','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			},
	
			search(context, data) {
				//console.log(context.getters.name, 'search','data', data)
				//console.log(context.getters.name, 'search','path', context.getters.path)
				//console.log(context.getters.name, 'search','HTTP', axios.defaults.baseURL)

				var params = null
				if(data.params) params = {params: data.params}
				// Очистка списку
				context.commit('items');
				//axios.get(context.getters.path, params).then(response => {
				axios.get(context.getters.path, params).then(response => {
					//console.log(context.getters.name, 'search','response.data', response.data)
					context.commit('items', response.data);
					//context.commit('insert', response.data);
					if(data.hasOwnProperty('success')) data.success(response.data);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'search','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			},

			/* read(context, data) {
				//console.log(context.getters.name, 'read','data', data)
				//context.dispatch('Read', data, {root: true});
				axios.get(context.getters.path, {params: data.params}).then(response => {
					//console.log(context.getters.name,'Read', 'response.data', response.data)
					context.commit('items', response.data);
					if(data.hasOwnProperty('success')) data.success(response.data);
				}, error => {
					//console.log(context.getters.name,'Read - error', error)
					//context.dispatch('Message', error);
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			}, */
			
	
			create(context, data) {
				//console.log(context.getters.name, 'create', 'data', data)
				//console.log(context.getters.name, 'create', 'item', JSON.stringify(data.item))
				//console.log(context.getters.name, 'create', 'item', data.item)

				axios.post(context.getters.path, data.item, data.config).then(response => {
					context.commit('insert', response.data);

					//console.log(context.getters.name, 'create', 'items', context.getters.items)
					//console.log(context.getters.name, 'create', 'response.data', response.data)

					if(data.hasOwnProperty('success')) data.success(response.data);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'create','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			},
	
			update(context, data) {
				//console.log(context.getters.name, 'update','data', data)
				//console.log(context.getters.name, 'update','id', data.item.id)
				//console.log(context.getters.name, 'update','item', data.item)
				//console.log(context.getters.name, 'update','item', JSON.stringify(data.item))
				
				axios.put(context.getters.path + '/' + data.item.id, data.item).then(response => {
					//context.commit('update', { new: response.data, old: data.selected })
					context.commit('update', {old: data.selected, new: response.data});
					if(data.hasOwnProperty('success')) data.success(response);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'update','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			},

			ordinal(context, data) {
				//console.log(context.getters.name, 'ordinal','data', data)
				//console.log(context.getters.name, 'update','id', data.item.id)
				//console.log(context.getters.name, 'update','item', data.item)
				
				axios.put(context.getters.path + '/' + data.item.id + '/ordinal/' +  data.item.value).then(response => {
					//context.commit('update', { new: response.data, old: data.selected })
					//context.commit('update', {old: data.selected, new: response.data});
					if(data.hasOwnProperty('success')) data.success(response);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'update','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			},

			/* patch(context, data) {
				console.log(context.getters.name, 'patch','data', data)
				
				axios.put(context.getters.path + '/' + data.item.id, data.params).then(response => {
					//context.commit('update', { new: response.data, old: data.selected })
					context.commit('update', response.data);
					//context.commit('selected', response.data);
					if(data.hasOwnProperty('success')) data.success(response);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'update','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			}, */


	
			delete(context, data) {
				//console.log(context.getters.name, 'delete','data', data)
				//console.log(context.getters.name, 'delete', 'item', JSON.stringify(data.item))
				//console.log(context.getters.name, 'delete', 'item.id', data.item.id)
				//context.commit('delete', data.item)

				axios.delete(context.getters.path + '/' + data.item.id).then(response => {
					context.commit('delete', data.item);

					//console.log(context.getters.name, 'delete', 'items', context.getters.items)
					//console.log(context.getters.name, 'delete', 'response.data', response.data)

					if(data.hasOwnProperty('success')) data.success(response);
				}, error => {
					//context.dispatch('Message', error, {root: true});
					console.log(context.getters.name, 'delete','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				})
			},

			save(context, data) {
				//console.log(context.getters.name, 'save','data', data)
				axios.post(context.getters.path, data.item, data.config).then(response => {
					//context.commit('insert', response.data);
					if(data.hasOwnProperty('success')) data.success(response.data);
				}, error => {
					console.log(context.getters.name, 'save','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			},

			upload(context, data) {
				let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

				let formData = new FormData();

				formData.append("file", data.file, data.file.name);

				//console.log(context.getters.name, 'upload','formData', formData)
				// files
				/* if(Array.isArray(data.files)) {
					for (let file of data.files) {
						formData.append("files", file, file.name);
					}
				} else {
					formData.append("files", data.files, data.files.name);
				} */

				// additional data
				//formData.append("test", "foo bar");

				//axios.post(context.getters.path + '/upload', formData, config).then(response => {
				axios.post('upload', formData, config).then(response => {
					if(data.hasOwnProperty('success')) data.success(response.data);
				}, error => {
					console.log(context.getters.name, 'upload','error', error.response)
					if(data.hasOwnProperty('error')) data.error(error.response);
				});
			},
		},
	},
})


//import {HTTP} from '../_http'
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API;

export default {
    namespaced: true,
    state: {
        name: 'messages',
        title: {
            single: 'message',
            plural: 'messages'
        },
        icon: 'mdi-family-tree',

        items: [],
    },
    actions: {
        findByChat(context, data) {
            //console.log(context.getters.name, 'findByChat','data', data)
            axios.get('chats/' + data.item.id + context.getters.path, {params: data.params}).then(response => {
                //console.log(context.getters.name, 'findByChat','response.data', response.data)
                context.commit('items', response.data);
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error, {root: true});
                console.log(context.getters.name, 'findByChat','error', error.response)
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
        },

        send(context, data) {
            //console.log(context.getters.name, 'send','data', data)
            axios.post(context.getters.path + '/send', data.item, data.config).then(response => {
                //console.log(context.getters.name, 'send','response.data', response.data)
                context.commit('insert', response.data);
                //console.log(context.getters.name, 'send','items', context.getters.items)
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error, {root: true});
                console.log(context.getters.name, 'find','error', error.response)
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
        },
    }
}
<template>
	<v-form ref="form">
		<v-row dense>
			<v-col  cols="12">
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.name') | capitalize"
					:rules="[rules.required]"
				></v-text-field>
				<v-textarea
					v-model="item.description"
					:label="$vuetify.lang.t('$vuetify.description') | capitalize"
					rows="1"
				></v-textarea>

				<!-- Parent Сценарій -->
				<z-scenarium 
					label="Сценарій верхнього рівня"
					:item="item.scenarium" 
					@change="$set(item, 'scenarium', $event)"
				></z-scenarium>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import mixin from './mixins/form'
import zScenarium from '@/views/components/scenarium'

export default {
	mixins: [mixin],
	components: { zScenarium },
	data () {
		return {

		}
	},
}
</script>
<template>
	<div>
		<v-text-field 
			:value="value"
			:label="label"
			readonly
			clearable
			:prepend-icon="prependIcon"
			append-outer-icon="mdi-menu"
			@click:append-outer="$refs.dialog.open()"
			@click:clear="$emit('change', null)"
			:hint="hint"
		>
			<template slot="append-outer">
				<z-dictionary
					:module="module"
					type="tree"
					items-value="itemstree"
					:multiple="false"
					acvicon="mdi-menu"
					@add="addItem"
				></z-dictionary>
			</template>
		</v-text-field>
	</div>
</template>

<script>
import zDictionary from '@/views/components/dictionary'

export default {
	components: { zDictionary },
	data: () => ({

	}),
	props: {
		module: 		{ type: String, default: 'scenarium' },				// Модуль: users | roles | ....
		prependIcon: 	{ type: String, default: null }, 					//'mdi-family-tree' 
		label: 			{ type: String, default: 'Перехід до сценарію' },
		item: 			{ type: Object, default: ()=>{} },
	},

	computed: {
		value() {
			if(this.item && this.item.hasOwnProperty('name')) {
				//return this.item.name
				return this.item.name +' ('+String(this.item.id)+')'
			}
		},
		hint() {
			if(this.item && this.item.hasOwnProperty('id')) {
				return this.item.id
			}
		}
	},
	watch: {	
		/* item(val) {
			console.log('scenarium', 'watch', 'item', val)
		} */
	},
	methods: {
		addItem(item) {
			//console.log('scenarium', 'addItem', 'item', item)
			this.$emit('change', {
				id: item.id,
				name: item.name,
			})
		}
	},
}
</script>
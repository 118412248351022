export default {
    namespaced: true,
    state: {
        name: 'chats',
        title: {
            single: 'chat',
            plural: 'chats'
        },
        icon: 'mdi-chat',

        item: null,
        items: [],
    },
}
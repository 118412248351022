<template>
	<v-container>
		<v-item-group active-class="primary">
			<v-container>
				<v-row>
					<v-col v-for="(setting, index) in settings" :key="index" cols="12" md="3">
						<v-item v-slot="{ active, toggle }">
							<v-hover v-slot="{ hover }" open-delay="200">
								<v-card 
									color="primary" dark
									:elevation="hover ? 4 : 2"
									:class="{ 'on-hover': hover }"
									class="mx-auto"
									:to="setting.to"
								>
									<v-app-bar flat color="primary">
										<v-app-bar-nav-icon>
											<v-icon>{{ setting.icon }}</v-icon>
										</v-app-bar-nav-icon>

										<v-toolbar-title class="text-subtitle-1 pl-0">
											{{ $vuetify.lang.t('$vuetify.' + GetTitle(setting.name)) | capitalize }}
										</v-toolbar-title>
									</v-app-bar>
									<!-- <v-card-text class="text-caption">
										{{ setting.description }}
									</v-card-text> -->
								</v-card>
							</v-hover>
						</v-item>
					</v-col>
				</v-row>
			</v-container>
		</v-item-group>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'


export default {
	data: () => ({
		loading: false,
		icon: 'mdi-cog',
		title: {
			single: 'setting',
			plural: 'settings'
		},
	}),
	computed: {
		...mapGetters('menu', [ 'settings' ]),
	},
	methods: {
		GetTitle(module) {
			let title = this.$store.getters[module + '/title']
			if(title) {
				return title.plural
			}
			return module
		}
	},
}
</script>
<template>
	<v-main>
		<!-- <v-container style="background: linear-gradient(45deg,#ffffff,#257047);" fluid>
			<v-container>
				
			</v-container>
		</v-container>

		<v-main style="padding: 30px 0px 0px;">
			<v-container>
				<v-card class="d-flex justify-center mb-6 flex-wrap" flat tile>
					
				</v-card>
				
			</v-container>
		</v-main>

		<v-overlay :value="loading">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay> -->

	</v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//import ShopCard from '@/views/shop.card'

export default {
	name: 'Home',
	components: {
		//ShopCard
	},
	data () {
		return {
			loading: false,
			browser: null,
		}
	},
	computed: {
		...mapGetters('app', [
			'services'
		]),

		/* gradient() {

			var colors = [
				{ id: 0, hex: "#ffffff", disabled: false },
				{ id: 1, hex: "#257047", disabled: false }
			]

			let gradient = "linear-gradient(45deg";
			colors.forEach( function(e) {
				gradient += "," + e.hex;
			});
			gradient += ")";

			console.log('gradient', gradient)
			return gradient;
		} */

	},
	created () {

		// Дата компіляції
		console.log('build', document.documentElement.dataset.buildTimestampUtc)
		
		// інформація про браузер, який використовується та операційну систему
		var platform = require('platform');
		//console.log('platform', platform)

		this.browser = {
			name: platform.name, // 'Safari'
			version: platform.version, // '5.1'
			product: platform.product, // 'iPad'
			manufacturer: platform.manufacturer, // 'Apple'
			layout: platform.layout, // 'WebKit'
			os: platform.os, // 'iOS 5.0'
			description: platform.description, // 'Safari 5.1 on Apple iPad (iOS 5.0)'
			ua: platform.ua
		}
		//console.log('browser', this.browser)
	},
	mounted () {
		
		// Встановлення фокусу в строку пошуку
		//this.$nextTick(() => this.$refs.decodedString.focus())
	},

	methods: {
		/* ...mapActions('contracts', [
			'CheckIn'
		]), */

		OnClickProduct (item) {

		},
		
	}
}
</script>


<style>
/* @import url("https://fonts.googleapis.com/css?family=Questrial"); */

.v-application .text-h4 {
  	font-family: "Open Sans",Sans-Serif !important;
}

/* "Roboto", sans-serif !important; */
/* font-family:"Open Sans",Sans-Serif;font-size:16px */

</style>


export default {
	namespaced: true,
	state: {
		name: 'scenariumrespond',
		title: {
			single: 'respond',
			plural: 'responds'
		},
		icon: 'mdi-message-bulleted',

		item:  {},
		items: [],

		default: {
			id: null,
			name: null,
            description: null,
			ordinal: null,
			scenarium: null,
			datatype: null,
			expr: null,
			reference: null,
		},

		headers: [
			{ text: 'id', value: 'id', sortable: true},
			{ text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
			{ text: 'ordinal', value: 'ordinal', sortable: true},
			{ text: 'scenarium', value: 'scenarium.name', sortable: true},
			{ text: 'datatype', value: 'datatype.name', sortable: true},
			{ text: 'expr', value: 'expr', sortable: true},
			{ text: 'reference', value: 'reference.name', sortable: true},
		],

		tabs: [
			{ title: 'main',        type: 'form', module: 'scenariumrespond' },
			//{ title: 'keyboard',    type: 'list', module: 'keyboard', propertyName: 'keyboards', actions: ['add'] },
		],
	},
}
export default {
    //...uk,
    langname: "Українська",
    language: "мова",
    homepage: "Головна",
    profile: "Профіль",

    commonAttributes: "загальні атрибути",
    type: "тип",
    name: "назва",
    namePersonOrganisation: "П.І.Б. /Назва організації",
    number: "номер",
    date: "дата",
    from: "з",
    to: "по",
    yes: "так",
    not: "ні",
    error: "помилка",

    commonActions: "загальні дії",
    actions: "Дії",
    enter: "Увійти",
    exit: "Вихід",
    ok: "OK",
    cancel: "Скасувати",
    confirm: "підтвердити",
    agree: "згоден",
    disagree: "не згоден",
    add: "Додати",
    remove: "Видалити",
    clear: "Очистити",
    new: "Створити",
    edit: "Редагувати",
    create: "Створити",
    update: "Редагувати",
    delete: "Видалити",
    search: "пошук",
    login: "логін",
    signin: "Вхід",
    signup: "Реєстрація",
    save: "Зберегти",
    open: "відкрити",
    close: "закрити",
    cut: "вирізати",
    copy: "копіювати",
    duplicate: "дублікат",
    paste: "вставити",
    refresh: "оновити",
    export: "експорт",
    import: "імпорт",
    authorization: "авторизація",
    registration: "реєстрація",
    createaccount: "Створити Аккаунт",
    password: "пароль",
    passwordold: "старий пароль",
    passwordnew: "новий пароль",
    passwordconfirm: "підтвердити пароль",
    passwordchange: "змінити пароль",
    passwordreset: "Оновлення паролю",
    passwordrecovery: "Відновлення паролю",
    passwordforgot: "Забули пароль?",
    donthaveaccount: "Створити Аккаунт",
    orderconnection: "Заявка на підключення",

    // Objects
    main: "головна",
    account:"обліковий запис",
    accounts:"облікові записи",
    bot:"бот",
	bots:"боти",
	botcommand:"команда бота",
	botcommands:"команди бота",
    users: "користувачі",
    user: "користувач",
    roles: "Ролі",
    role: "Роль",
    scenarium: "сценарій",
    scenariums: "сценарії",
    chat: "чат",
    chats: "чати",
    gallery: "галерея",
    message: "повідомлення",
    messages: "повідомлення",
    request: "запит",
    requests: "запити",
    respond: "відповідь",
    responds: "відповіді",
    menu: "меню",
    menutype: "тип меню",
	menutypes: "типи меню",
    funcs: "функції",
    func: "функція",
    functype: "тип функції",
	functypes: "типи функції",
    functions: "функції",
	function: "функція",

    token: "токен",
    text: "text",
	photo: "photo",
	document: "document",
	audio: "audio",
	sticker: "sticker",
	video: "video",
	animation: "animation",
	voice: "voice",
	contact: "contact",
	location: "location",
	venue: "venue",
	media: "media",

    caption: "заголовок",
	title: "назва",
	firstName: "им'я",
	lastName: "прізвище",
	phoneNumber: "номер телефону",
    disableNotification: "вимкнути сповіщення",
    onetime: "один раз",
	resize: "змінити розмір",
    hidden: "сховати",
    
    //Keyboard
    keyboard: "клавіатура",
    tableAdd: "додати таблицю",
    tableRemove: "видалити таблицю",
    tableRefresh: "оновити таблицю",
    tableRowAddBefore: "додати рядок таблиці перед",
    tableRowAddAfter: "додати рядок таблиці після",
    tableRowRemove: "видалити рядок таблиці",
    tableColAddBefore: "додати колонку таблиці перед",
    tableColAddAfter: "додати колонку таблиці після",
    tableColRemove: "видалити колонку таблиці",
    tableColMoveLeft: "змістити колонку таблиці ліворуч",
    tableColMoveRight: "змістити колонку таблиці праворуч",
    tableRowMoveUp: "змістити рядок таблиці вверх",
    tableRowMoveDown: "змістити рядок таблиці вниз",
    InlineKeyboardMarkup: "Inline Keyboard Markup",
    ReplyKeyboardMarkup: "Reply Keyboard Markup",
    ReplyKeyboardRemove: "Reply Keyboard Remove",
    properties: "властивості",
    buttonsproperties: "властивості кнопки",

    //Sentences
    "Are you sure you want to delete this record?": "Ви впевнені, що хочете видалити запис?",

    row: "рядок",
    rows: "рядки",
    column: "колонка",
    columns: "колонки",

    //homepage.banner
    cameraoff: "На вашому пристрої доступ до камери заблоковано. В такому режимі ви можете перевіряти поліси, шляхом введення вручну номеру полісу або цифр зі штрих-коду.",

    stopscan: "зупинити сканування",
    startscan: "відновити сканування",

    settings: "налаштування",
    video: "відео",
    frame: "рамка",
    laser: "лазер",
    sound: "звук",
    soundeditor: "редактор звукових сигналів",
    volume: "гучність",
    frequency: "частота",
    duration: "тривалість",
    beeps: {
        scan: "зчитування коду",
        success: "поліс ідентифіковано",
        failure: "поліс не ідентифіковано",
        error: "помилка",
    },

    //Attributes
    id: "id",
    code: "код",
	username: "им'я користувача",
	description: "опис",
	datecreate: "дата створення",
    pib: "прізвице, ім'я, по батькові",
    telephone: "телефон",
    email: "email",
    comment: "коментар",
    icon: "значок",
    regexpression: "регулярний вираз",
	ordinal: "порядковий номер",
    type: "тип",
	types: "типи",
    datatype: "тип даних",
	datatypes: "типи даних",
    keyboard: "клавіатура",
	keyboards: "клавіатури",
	keyboardtype: "тип клавіатури",
	keyboardtypes: "типи клавіатур",
    keyboardbutton: "кнопка клавіатури",
	keyboardbuttons: "кнопки клавіатури",
    button: "кнопка",
	buttons: "кнопки",

persons: "фізичні особи",
person: "фізична особа",
firstname: "ім’я",
surname: "прізвище",
patronymic: "по батькові",
birthDate: "дата народження",
birthPlace: "місце народження",
deathDate: "дата смерті",
deathPlace: "місце смерті",
nationality: "громадянство",
post: "посада",
personsname: "ПІБ",
organization: "організація",

sex: "стать",
male: "чоловіча",
female: "жіноча",

persons_roles: "ролі осіб",
claimant: "заявник",
owner: "власник",
driver: "водій",
previous_owners: "попередні власники",

identification: "документ, який посвідчує особу",
seria: "серія",
number: "номер",
issuedby: "ким виданий",
dateofissue: "дата видачі",
recordno: "запис №",
dateofexpiry: "дійсний до",
documentno: "документ №",

identificationstype: "тип документа",
identificationstypes: "типи посвідченнь особи",
passport: "паспорт",
passportCitizen: "паспорт громадянина",
passportForeign: "паспорт громадянина для виїзду за кордон",
passportDiplomatic: "дипломатичний паспорт",
passportService: "службовий паспорт",
passportSeamen: "посвідчення особи моряка",
passportCrew: "посвідчення члена екіпажу",
passportReturn: "посвідчення особи на повернення",
passportTemporary: "тимчасове посвідчення громадянина",
driverLicense: "посвідчення водія",
identityCardTravel: "посвідчення особи без громадянства для виїзду за кордон",
identityCardPermanent: "посвідка на постійне проживання",
identityCardTemporary: "посвідка на тимчасове проживання",
identityCardMigrant: "картка мігранта",
refugeeCertificate: "посвідчення біженця",
refugeeTravelDocument: "проїзний документ біженця",
identityCardAdditionalProtection: "посвідчення особи, яка потребує додаткового захисту",
identityCardGrantedAdditionalProtection: "проїзний документ особи, якій надано додатковий захист",

addresses: "адреси",
address: "адреса",
postalCode: "індекс",
сountry: "країна",
locality: "населений пункт",
district: "район",
street: "вулиця",
house: "будинок",
apartment: "квартира",
latitude: "latitude",
longitude: "longitude",

addressestypes: "типи адрес",
addresstype: "тип адреси",
addressResidence: "адреса проживання",
addressRegistration: "адреса реєстрації",

documents: "документи",
document: "документ",
templates: "Шаблони",
template: "Шаблон",
constructors: "Коструктори",
constructor: "Коструктор",
//number: "номер",
//date: "дата",

policies: "договори страхування",
policy: "договір страхування",
//number: "номер",
//date: "дата",
insurer: "страхувальник",
periodValidity: "строк дії",

notifications: "повідомлення",
notification: "повідомлення",
//number: "номер",
//date: "дата",

cases: "справи",
case: "справа",
//number: "номер",
//date: "дата",

payments: "виплати",
payment: "виплата",
//number: "номер",
//date: "дата",

calendars: "календарі",
calendar: "календар",
//number: "номер",
//date: "дата",

    //---------------------------
    company: "компанія",
    inn: "ІПН",

    //Rules
    rule: {
        required: "Значення поля не може бути пустим",
        lenless: "Name must be less than {0} characters",
        code: "Code must be less than {0} characters",
        personsname: "Ім'я особи містить недопустимі символи",
        email: "E-mail вказано невірно",
        phone: "Номер телефону не вірний (+380961112233)",
    },
    
    carousel: {
        ariaLabel: { 
            delimiter: ''
        }
    },

    noDataText: '',

    dataTable: {
        itemsPerPageText: '',
        ariaLabel: {
            sortNone: '',
            activateAscending: '',
        },
        loadingText: 'Loading... Please wait',
    },

    dataFooter: {
        prevPage: '',
        nextPage: '',
        itemsPerPageAll: '',
        pageText: '',
    },

    datePicker: { 
        prevMonthAriaLabel: '',
        nextMonthAriaLabel: '',
    },

    carousel: {
        prev: 'попередній',
        next: 'наступний',
        ariaLabel: {
            delimiter: ''
        }
    },

    badge: '',
}
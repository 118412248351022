<template>
	<v-form ref="form">
		<v-row dense>
			<v-col cols="12" md="8">
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.name') | capitalize"
					:rules="[rules.required]"
				></v-text-field>
			</v-col>
			<v-col cols="12" md="4">
				<v-text-field
					v-model="item.icon"
					:label="$vuetify.lang.t('$vuetify.icon') | capitalize"
					:prepend-inner-icon="item.icon"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-textarea
					v-model="item.description"
					:label="$vuetify.lang.t('$vuetify.description') | capitalize"
					rows="1"
				></v-textarea>
				<v-text-field
					v-model="item.regexpression"
					:label="$vuetify.lang.t('$vuetify.regexpression') | capitalize"
				></v-text-field>
				<v-text-field
					v-model="item.ordinal"
					:label="$vuetify.lang.t('$vuetify.ordinal') | capitalize"
					type="number"
					min="1"
				></v-text-field>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import mixin from './mixins/form'

export default {
	mixins: [mixin],
	data () {
		return {

		}
	},
}
</script>
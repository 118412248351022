<template>
    <v-main>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-btn icon color="primary" @click="$router.push('/')">
						<v-icon >mdi-home</v-icon>
					</v-btn>
                    <!-- <v-breadcrumbs :items="breadcrumbs" divider="-">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.to"
                                :disabled="item.disabled"
                                :exact="item.exact"
                            >
                                {{ $vuetify.lang.t('$vuetify.'+item.text) | capitalize }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs> -->
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <v-card elevation="0" width="600px">
                        <v-toolbar class="elevation-0">
                            <v-icon class="mr-6">{{ icon }}</v-icon>
                            <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.'+title) | capitalize }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-form ref="form">
                                <v-text-field
                                    v-model="item.oldpassword"
                                    :label="$vuetify.lang.t('$vuetify.passwordold') | capitalize"
                                    prepend-icon="mdi-lock"
                                    type="password"
                                    hint="At least 8 characters"
                                    min="8"
                                    required
                                    :rules="[rules.required]"
                                    @keyup.ctrl.90="OnDefault"
                                    @keyup.enter="OnOK"
                                ></v-text-field>
                                <v-text-field
                                    v-model="item.password"
                                    :label="$vuetify.lang.t('$vuetify.passwordnew') | capitalize"
                                    prepend-icon="mdi-lock"
                                    type="password"
                                    hint="At least 8 characters"
                                    min="8"
                                    required
                                    :rules="[rules.required]"
                                    @keyup.ctrl.90="OnDefault"
                                    @keyup.enter="OnOK"
                                ></v-text-field>
                                <v-text-field
                                    v-model="item.confirm"
                                    :label="$vuetify.lang.t('$vuetify.passwordconfirm') | capitalize"
                                    prepend-icon="mdi-lock"
                                    type="password"
                                    hint="At least 8 characters"
                                    min="8"
                                    required
                                    :rules="[rules.required]"
                                    @keyup.ctrl.90="OnDefault"
                                    @keyup.enter="OnOK"
                                ></v-text-field>
                            </v-form>

                            <z-alert ref="alert"></z-alert>
                        </v-card-text>

                        <v-card-actions>
                            <!-- <v-spacer></v-spacer> -->
                            <v-btn large block
                                :loading="loading"
                                @click.native="OnOk"
                                color="success"
                                dark
                            >
                                <v-icon left dark>mdi-check-bold</v-icon>	
                                {{ $vuetify.lang.t('$vuetify.ok') }}
                            </v-btn>
                            <!-- <v-spacer></v-spacer> -->
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters, mapActions } from 'vuex'
import zAlert from '@/views/components/alert'

export default {
    components: {
        VueRecaptcha, zAlert
    },
    data: () => ({
        title: 'passwordchange',
        icon: 'mdi-key-change',
        loading: false,
        valid: false,

        breadcrumbs: [
            { text: 'homepage', disabled: false, to:{name: 'home'}, exact: true},
            { text: 'passwordchange', disabled: true},
        ],

        rules: {
            required: (value) => !!value || 'Required.',
        },

        item: {
            oldpassword: null,
            password: null,
            confirm: null,
        },

        alert: {
			text: null,
			type: null,
		},

    }),
    
    computed: {
        
    },
    methods: {
        ...mapActions('app',[
            'PasswordChange'
        ]),

        OnDefault () {
            //ctrl+z
            this.item.oldpassword    = 'abc'
            this.item.password       = 'abcd'
            this.item.confirm        = 'abcd'
        },  

        OnOk() {
            var that = this;

            this.$refs.alert.Close();

            if (this.$refs.form.validate()) {
                if(this.item.password == this.item.confirm) {
                    this.loading = true;
                    this.PasswordChange({
                        item: this.item,
                        success: function(response) {
                            that.loading = false;
                            //router.push('/')
                            that.$refs.alert.Open(response.data, 'success')
                        },
                        error: function(response) {
                            that.loading = false
                            that.$refs.alert.Open(response.data, 'error')
                        },
                    });
                } else {
                    that.$refs.alert.Open("Пароль і його підтвердження відрізняються між собою", 'error')
                }
            }
        },
    },
}
</script>
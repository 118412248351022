
export default {
    namespaced: true,
    state: {
        name: 'dialogs',
        title: {
            single: 'dialog',
            plural: 'dialogs'
        },
        icon: 'mdi-family-tree',

        items: [],
    },
}
<template>
	<v-form ref="form">
		<v-row>
			<v-col cols="12">
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.name') | capitalize"
					:rules="[rules.required]"
				></v-text-field>
				<v-textarea
					v-model="item.description"
					:label="$vuetify.lang.t('$vuetify.description') | capitalize"
					rows="1"
				></v-textarea>
				<v-combobox
					v-model="item.datatype"
					:items="types"
					:label="$vuetify.lang.t('$vuetify.type') | capitalize"
					item-text="name"
					item-value="id"
					:return-object="true"
					:rules="[rules.required]"
					:prepend-icon="item.icon"
				>
					<template slot="item" slot-scope="data">
						<v-icon>{{ data.item.icon }}</v-icon>
						<span class="ml-3">{{ data.item.name }}</span>
					</template>
				</v-combobox>

				<!-- Сценарій -->
				<z-scenarium 
					:item="item.reference" 
					@change="$set(item, 'reference', $event)"
				></z-scenarium>

				<!-- Expression -->
				<v-text-field
					v-model="item.expr"
					label="Expression"
					clearable
					prepend-icon="mdi-regex"
				></v-text-field>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import mixin from './mixins/form'
import zScenarium from '@/views/components/scenarium'

export default {
	mixins: [mixin],
	components: { zScenarium },
	data () {
		return {

		}
	},
	computed: {
		types: function () {
			//console.log('keyboard', 'computed', 'types', this.$store.getters['keyboardtype/items'])
			return this.$store.getters['datatype/items']
		},
		/* type: function () {
			if(this.item.datatype) {
				return this.item.datatype.code
			}
		} */
	},
	methods: {
		// для перегрузки 
		open() {},
		clear() {},
	}
}
</script>
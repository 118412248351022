<template>
	<v-form ref="form">
		<v-row dense>
			<v-col  cols="12">

				<v-textarea
					v-model="item.errorText"
					label="Текст"
					hint="Текст повідомлення у разі невідповідності типу очікуваної відповіді"
					prepend-icon="mdi-comment"
					rows="1"
				></v-textarea>
				
				<z-scenarium 
					:item="item.errorScenarium"
					prepend-icon="mdi-family-tree"
					@change="$set(item, 'errorScenarium', $event)"
				></z-scenarium>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import mixin from './mixins/form'
import zScenarium from '@/views/components/scenarium'

export default {
	mixins: [mixin],
	components: { zScenarium },
	data () {
		return {

		}
	},
}
</script>
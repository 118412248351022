export default {
    namespaced: true,
    state: {
        name: 'users',
        title: {
            single: 'user',
            plural: 'users'
        },
        icon: 'mdi-account-multiple',

        items: [],
        /* headers: [
            //{ text: 'ID', value: 'id', align: ' d-none' },
            { text: 'ID', value: 'id', sortable: true },
            { text: 'Назва', value: 'name', sortable: true},
            { text: 'Компанія', value: 'profiles.company', sortable: true},
            { text: 'Ролі', value: 'usersroles.length', sortable: true},
            { text: 'Сервіси', value: 'services.length', sortable: true},
            { text: 'Логін', value: 'login', sortable: true, align: ' d-none'},
            { text: 'Активність', value: 'active', sortable: true, align: ' d-none'},
            { text: 'enable', value: 'enable', sortable: true, align: ' d-none'},
            { text: 'expiration', value: 'expiration', sortable: true, type: 'datetime', align: ' d-none'},
            { text: 'dateactive', value: 'dateactive', sortable: true, type: 'datetime', align: ' d-none'},
            { text: 'datecreate', value: 'datecreate', sortable: true, type: 'datetime'},
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        form: {
            tab: null,
            tabs: ['Головна', 'Профіль', 'Ролі', 'Сервіси']
        }, */

    },
}
<template>
	<v-list two-line dense>
		<v-list-item-group
			v-model="selected"
			color="primary"
			:multiple="multiple"
		>
			<v-hover v-slot="{ hover }" v-for="(item, index) in items" :key="index">
				<v-list-item style="background: #FAFAFA;" class="my-1" link>
					<!-- <v-list-item-avatar>
						<v-icon>{{ getIcon(item) }}</v-icon>
					</v-list-item-avatar> -->

					<v-list-item-content>
						<!-- <v-list-item-title v-html="getText(item, itemTitle)"></v-list-item-title>
						<v-list-item-subtitle v-text="getText(item, itemSubtitle)"></v-list-item-subtitle> -->
						<v-list-item-title v-html="item.name"></v-list-item-title>
						<v-list-item-subtitle v-html="item.description"></v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action style="flex-direction: row; align-items: center;">
						<v-btn icon @click="moveItem(items, item, 'up')" v-if="movable && hover">
							<v-icon >mdi-arrow-up-bold</v-icon>
						</v-btn>
						<v-btn icon @click="moveItem(items, item, 'down')" v-if="movable && hover">
							<v-icon >mdi-arrow-down-bold</v-icon>
						</v-btn>
						<!-- <v-btn icon @click="$emit('up', item)" v-if="movable && hover">
							<v-icon >mdi-arrow-up-bold</v-icon>
						</v-btn>
						<v-btn icon @click="$emit('down', item)" v-if="movable && hover">
							<v-icon >mdi-arrow-down-bold</v-icon>
						</v-btn> -->

						<confirm v-if="removable" @agree="$emit('remove', item)"></confirm>
						<confirm v-if="deletable" @agree="$emit('delete', item)" acvicon="mdi-delete-outline"></confirm>
						<detail :module="module" mode="update" activator="icon" :selected="item" v-if="editable"  :offline="offline" @create="$emit('create', $event)"></detail>
						<detail :module="module" mode="create" activator="icon" :neighbor="item" v-if="creatable" :offline="offline" @update="$emit('update', $event)"></detail>
					</v-list-item-action>
				</v-list-item>
			</v-hover>
			</v-list-item-group>
	</v-list>
</template>

<script>
import confirm from '@/views/components/confirm'

export default {
	components: { 
		confirm,  
		detail: () => import('@/views/components/detail')
	},
	data: () => ({
		selected: [],
	}),
	props: {
		module: 			{ type: String, default: null },		// Модуль: users | roles | ....
		offline: 			{ type: Boolean, default: false },		// Режим без зв'язку з базою даних
		items: 				{ type: Array, default: ()=>[] },		// Масив даних
		multiple: 			{ type: Boolean, default: false },		// Ознака виділення декількох рідків
		itemTitle:			{ type: [String, Array], default: 'name' },	// Назва поля для title
		itemSubtitle:		{ type: [String, Array], default: 'description' },	// Назва поля для subtitle
		itemIcon:			{ type: [String, Array], default: 'icon' },	// Назва поля для icon
		creatable:			{ type: Boolean, default: false },		// Ознака наявності функції створення
		editable:			{ type: Boolean, default: false },		// Ознака наявності функції редагування
		removable:			{ type: Boolean, default: false },		// Ознака наявності функції видалення зі списку
		deletable:			{ type: Boolean, default: false },		// Ознака наявності функції видалення з бази даних та зі списку
		movable:			{ type: Boolean, default: false },		// Ознака наявності функції зміни позиції

		defaultIcon: 		{ type: String, default: 'mdi-circle-medium' },	// Значок за замовченням
	},
	computed: {
		
	},
	methods: {
		getSelected () {
			let result = []
			if(this.multiple) {
				for(let i in this.selected) {
					result.push(this.items[this.selected[i]])
				}
			} else {
				result.push(this.items[this.selected])
			}
			return result
		},

		getText(item, key) {
			if(Array.isArray(key)) {
				for(let i in key) {
					let k = key[i]
					if(item[k]) {
						return item[k]
					}
				}
			} else {
				if(typeof item[key] === 'object' && item[key]) {
					if(item[key].hasOwnProperty('name')) {
						return item[key].name
					}
				}
				return item[key]
			}
		},

		getIcon(item) {
			let key = this.itemIcon
			if(item.hasOwnProperty(key)) {
				if(item[key]) {
					if(typeof item[key] === 'object' && item[key]) {
						if(item[key].hasOwnProperty('icon')) {
							return item[key].icon
						}
					} else {
						return item.icon
					}
				}
			}
			return this.defaultIcon
		},

		moveItem (items, item, direction) {
			//console.log('list.vue', 'moveItem', 'item', item)
			//console.log('list.vue', 'moveItem', 'items', items)

			/* if(item.hasOwnProperty('parent')) {
				items = item.parent.children
			} */

			const index = items.indexOf(item)
			if((direction == 'up' || direction == 'left') && index > 0) {
				items.splice(index-1, 0, items.splice(index, 1)[0]);
			}
			if((direction == 'down' || direction == 'right') && index < items.length) {
				items.splice(index+1, 0, items.splice(index, 1)[0]);
			}
			for(let i in items) {
				let ordinal = items[i]['ordinal']
				if(ordinal !== i) {
					items[i]['ordinal'] = i

					/* let id = items[i].id
					//console.log('tree.vue', 'moveItem', 'i', i)
					this.loading = true;
					let that = this
					this.$store.dispatch(`${this.module}/ordinal`, {
						item: {
							id: id,
							value: i
						},
						success: function(response) {
							that.loading = false;
						},
						error: function(response) {
							that.loading = false
						}
					}); */
				}
			}
		},
	}
}
</script>
<template>
	<v-form ref="form">
		
		<v-row dense>
			<v-col  cols="12">
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.name') | capitalize"
					:rules="[rules.required]"
				></v-text-field>
				<v-textarea
					v-model="item.description"
					:label="$vuetify.lang.t('$vuetify.description') | capitalize"
					rows="1"
				></v-textarea>
				<v-text-field v-if="item"
					v-model="item.text"
					label="Text"
				></v-text-field>
				<z-scenarium 
					:item="item.scenarium" 
					@change="$set(item, 'scenarium', $event)"
				></z-scenarium>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col  cols="12" md="6">
				<v-text-field
					v-model="item.row"
					:label="$vuetify.lang.t('$vuetify.row') | capitalize"
					type="number"
					readonly
				></v-text-field>
			</v-col>
			<v-col  cols="12" md="6">
				<v-text-field
					v-model="item.ordinal"
					:label="$vuetify.lang.t('$vuetify.ordinal') | capitalize"
					type="number"
					readonly
				></v-text-field>
			</v-col>
		</v-row>

	</v-form>
</template>

<script>
import mixin from './mixins/form'
import zScenarium from '@/views/components/scenarium'

export default {
	mixins: [mixin],
	components: { zScenarium },
	data () {
		return {
			
		}
	},
	methods: {
		// для перегрузки 
		open() {},
		clear() {},
	}
}
</script>
import Vue from "vue"

export default {
	namespaced: true,
	state: {
		name: 'scenarium',
		title: {
			single: 'scenarium',
			plural: 'scenariums'
		},
		icon: 'mdi-family-tree',
		
		item: null,
		items: [],
		selected: null,

		default: {
			id: null,
			code: null,
			name: null,
			ordinal: null,
			scenarium: null,
			description: null,
			requests: [],
			responds: [],
			errorText: null,
			errorScenarium: {},
		},

        headers: [
            { text: 'id', value: 'id', sortable: true},
            { text: 'code', value: 'code', sortable: true},
            { text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
            //{ text: 'type', value: 'type', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],

        tabs: [
			{ title: 'main', 		type: 'form', module: 'scenarium' },
			{ title: 'requests', 	type: 'list', module: 'scenariumrequest', dataSource: 'requests', offline: true, editable: true, removable: true, movable: true, actions: ['create'], itemTitle: ['text','caption','title'], itemSubtitle: 'datatype', itemIcon: 'datatype' },
			{ title: 'responds', 	type: 'list', module: 'scenariumrespond', dataSource: 'responds', offline: true, editable: true, removable: true, movable: true, actions: ['create'], itemTitle: 'reference', itemSubtitle: 'datatype', itemIcon: 'datatype' },
			{ title: 'error', 		type: 'form', module: 'scenarium', view: 'scenariumerror' },
		],
		
		// propertyName -> itemsName -> dataSource

		/* types: [
			//{value: 'none', label: 'None', icon: 'mdi-set-none'},
			{value: 'text', label: 'text', icon: 'mdi-message-text', components: ['text', 'arseMode', 'disablePreview', 'replyToMessageId', 'replyMarkup', 'disableNotification']},
			{value: 'photo', label: 'photo', icon: 'mdi-image', components: ['chatId','photo','caption','replyToMessageId','replyMarkup','disableNotification','parseMode']},
			{value: 'document', label: 'document', icon: 'mdi-file-document', components: ['chatId','document','caption','replyToMessageId','replyMarkup','disableNotification','parseMode']},

			{value: 'audio', label: 'audio', icon: 'mdi-headphones', components: ['chatId','audio','duration','performer','title','replyToMessageId','replyMarkup','disableNotification','parseMode']},
			{value: 'sticker', label: 'sticker', icon: 'mdi-sticker', components: ['chatId','sticker','replyToMessageId','replyMarkup','disableNotification']},
			{value: 'video', label: 'video', icon: 'mdi-video', components: ['chatId','video','duration','caption','replyToMessageId','replyMarkup','disableNotification','supportsStreaming','parseMode']},
			{value: 'animation', label: 'animation', icon: 'mdi-animation', components: ['chatId','animation','duration','caption','replyToMessageId','replyMarkup','disableNotification','parseMode']},
			{value: 'voice', label: 'voice', icon: 'mdi-microphone', components: ['chatId','voice','duration','replyToMessageId','replyMarkup','disableNotification','parseMode']},
			{value: 'contact', label: 'contact', icon: 'mdi-card-account-phone', components: ['chatId','phoneNumber','firstName','lastName','replyToMessageId','replyMarkup', 'disableNotification']},
			{value: 'location', label: 'location', icon: 'mdi-map-marker', components: ['chatId','latitude','longitude','replyToMessageId','replyMarkup','disableNotification','livePeriod']},
			{value: 'venue', label: 'venue', icon: 'mdi-lightbulb-question', components: ['chatId','latitude','longitude','title','address','foursquareId','replyToMessageId','replyMarkup','disableNotification']},
			{value: 'media', label: 'media', icon: 'mdi-folder-play', components: ['chatId','media','disableNotification','replyToMessageId']},
		], */
	},
	getters: {
		
		/* types(state) {
			return state.types
		}, */

		/* type: (state) => (value) => {
			//console.log(state.name, 'getters:type','value', value)
			if(value)
				return state.types.find(item => item.value === value)
			else return state.types[0]
		}, */

		itemstree (state) {
			var map = {}
				,roots = []
				,items = state.items.sort((a, b) => { return a.ordinal - b.ordinal; });

			//console.log(this.name, 'getters:scenarium','list', list)

			for (var i in items) {
				//items[i]['children'] = []
				Vue.set(items[i], 'children', []) // initialize the children
				map[items[i].id] = i;    			// initialize the map
			}

			//Array.from(list).map((it, i) => {
			//	this.$set(list[i], 'children', [])
			//});
			
			for (var i in items) {
				let item = items[i];
				if (item.hasOwnProperty('scenarium') && item.scenarium !== null) {
					//let parent = map[item.scenarium.id]
					item['parent'] = items[map[item.scenarium.id]]
					// if you have dangling branches check that map[node.parentId] exists
					if(items[map[item.scenarium.id]]) {
						items[map[item.scenarium.id]].children.push(item);
					}
				} else {
					roots.push(item);
				}
			}
			//console.log(state.name, 'getters','itemstree','roots', roots)
			return roots;
		},
	},

	actions: {
		clear(context) {
			//console.log('scenarium.js', context.getters.name, 'clear')

			//deep copy
			let item = JSON.parse(JSON.stringify(context.getters.default))

			if(Array.isArray(context.getters.selected)) {
				//item.scenarium = context.getters.selected[0]
				let parent = context.getters.selected[0]
				item.scenarium = {
					id: parent.id,
					name: parent.name,
				}

			}

			context.commit('item', item);
		},
	},
}
export default {
	namespaced: true,
	state: {
		name: 'scenariumrequest',
		title: {
			single: 'request',
			plural: 'requests'
		},
		icon: 'mdi-chat-question',

		item:  {},
		items: [],

		default: {
			id: null,
			name: null,
            description: null,
			ordinal: null,
			scenarium: null,
			datatype: null,
			text: null,
			caption: null,
			title: null,
			duration: null,
			firstName: null,
			lastName: null,
			phoneNumber: null,
			latitude: null,
			longitude: null,
			address: null,
			filename: null,
			filepathname: null,
			disableNotification: 0,
			keyboardtype: null,
			keyboardOnetime: 0,
			keyboardResize: 0,
			buttons: [],
			keyboards: []
		},

		headers: [
			{ text: 'id', value: 'id', sortable: true},
			{ text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
			{ text: 'ordinal', value: 'ordinal', sortable: true},
			{ text: 'scenarium', value: 'scenarium.name', sortable: true},
			{ text: 'datatype', value: 'datatype.name', sortable: true},
			{ text: 'text', value: 'text', sortable: true},
			{ text: 'caption', value: 'caption', sortable: true},
			{ text: 'title', value: 'title', sortable: true},
			{ text: 'duration', value: 'duration', sortable: true},
			{ text: 'firstName', value: 'firstName', sortable: true},
			{ text: 'lastName', value: 'lastName', sortable: true},
			{ text: 'phoneNumber', value: 'phoneNumber', sortable: true},
			{ text: 'latitude', value: 'latitude', sortable: true},
			{ text: 'longitude', value: 'longitude', sortable: true},
			{ text: 'address', value: 'address', sortable: true},
			{ text: 'filename', value: 'filename', sortable: true},
			{ text: 'filepathname', value: 'filepathname', sortable: true},
			{ text: 'disableNotification', value: 'disableNotification', sortable: true},
			{ text: 'keyboardtype', value: 'keyboardtype.name', sortable: true},
			{ text: 'keyboardOnetime', value: 'keyboardOnetime', sortable: true},
			{ text: 'keyboardResize', value: 'keyboardResize', sortable: true},
		],

		tabs: [
			{ title: 'main',        type: 'form', module: 'scenariumrequest' },
			//{ title: 'keyboard',    type: 'list', module: 'keyboard', dataSource: 'keyboards', editable: true, removable: true,  actions: ['add'] },
			//{ title: 'keyboard',    type: 'keyboard', module: 'keyboard', dataSource: 'keyboards', editable: true, removable: true },
			//{ title: 'keyboard',    type: 'keyboard', module: 'scenariumrequest', dataSource: 'buttons', editable: true, removable: true },
			{ title: 'keyboard',    type: 'keyboard', module: 'scenariumrequest' },
		],
	},
}
<template>
	<z-dialog ref="dialog"
		:hint="hint"
		:acvicon="acvicon"
		:title="title"
		:icon="icon"
		:loading="loading"
		extension
		@open="open"
		@ok="ok"
	>
		<template v-slot:extension>
			<v-text-field v-if="search.visible"
				v-model="search.text"
				:label="$vuetify.lang.t('$vuetify.search') | capitalize"
				dense
				prepend-inner-icon="mdi-magnify" 
				clearable
				solo-inverted
				hide-details="auto"
				@click:clear="search.text=''"
			></v-text-field>
			
			<v-spacer></v-spacer>
			<v-btn icon @click="search.visible = !search.visible" >
				<v-icon>mdi-magnify</v-icon>
			</v-btn>
		</template>

		<template v-slot:context>
			<component ref="component" v-bind:is="currentComponent" :module="module" :items="items" :multiple="multiple"
				:itemTitle="itemTitle"
				:itemSubtitle="itemSubtitle"
			></component>
		</template>
	</z-dialog>
</template>

<script>
import zDialog from '@/views/components/dialog'
import listComponent from '@/views/components/list'
import treeComponent from '@/views/components/tree'

export default {
	components: { zDialog, listComponent, treeComponent },
	data: () => ({
		loading: false,

		search: {
			text: '',
			visible: false
		},
	}),

	props: {
		module: 		{ type: String, default: null },	// Модуль: users | roles | ....
		type: 			{ type: String, default: 'list' },	// Тип відображення конткнту: list | table | tree
		multiple: 		{ type: Boolean, default: true },	

		//item: 			{ type: Object, default: () => {} },

		//title:      	{ type: String, default: null },
		//title:      	{ type: Object, default: () => {} },
		//icon:       	{ type: String, default: null },
		hint:       	{ type: String, default: 'add' },
		scrollable: 	{ type: Boolean, default: false },
		hideOverlay: 	{ type: Boolean, default: false },
		width:      	{ type: Number, default: 800 },
		height:     	{ type: Number, default: 800 },
		transition: 	{ type: String, default: 'dialog-bottom-transition' },
		color:			{ type: String, default: null }, 		// Колір фону (card)
		

		small:      	{ type: Boolean, default: false },
		disabled:   	{ type: Boolean, default: false }, 		//Ознака неактивного елементу
		activator:  	{ type: String, default: 'button'}, 	// Тип активатора вікна: button | icon | menu-item | list-item
		acvicon:  		{ type: String, default: 'mdi-playlist-plus'},

		//dataSource:		{ type: String, default: 'items' },		// Джерело даних (назва )
	},

	computed: {
		title: function () {
            return this.$store.getters[this.module + '/title']
        },
		icon: function () {
            return this.$store.getters[this.module + '/icon']
        },
		headers: function () {
            return this.$store.getters[this.module + '/headers']
        },
		items: function () {
			//console.log('dictionary', 'computed:items', 'getters', this.module + '/' + this.dataSource)
			let key = 'items'
			switch(this.type) {
				case 'tree': key = 'items' + this.type; break;
			}
			//console.log('dictionary', 'computed', 'items', this.module + '/' + key)
            return this.$store.getters[this.module + '/' + key]
        },

		itemTitle: function () {
			//console.log('dictionary.vue', 'computed', 'itemTitle', this.module, this.$store.getters[this.module + '/itemTitle'])
            return this.$store.getters[this.module + '/itemTitle']
        },
		itemSubtitle: function () {
            return this.$store.getters[this.module + '/itemSubtitle']
        },


		itemsFiltered() {
			return this.items.filter(item => {
				if(!this.search.text) return this.items;
				return (
					item.code.toLowerCase().includes(this.search.text.toLowerCase())	||
					item.name.toLowerCase().includes(this.search.text.toLowerCase())	||
					item.description.toLowerCase().includes(this.search.text.toLowerCase())
				);
            })
        },

		currentComponent: function() {
			return this.type + 'Component';
		}
	},
	watch: {
		dialog: function (val) {
			//console.log('dictionary', 'watch:dialog', 'module', this.module)
			//console.log('dictionary', 'watch:dialog', 'val', val)
			if(val) {
				if (this.items.length == 0) {
					this.refresh()
				}
			}
		},

		items: function (val) {
			if(val) {
				//console.log('dictionary', 'watch:items', 'val', val)
			}
		},
	},
	methods: {
		open (e) {
			//this.dialog = true
			if(this.items) {
				if (this.items.length == 0) {
					this.refresh()
				}
			}
		},

		ok (e) {
			//console.log('dictionary', 'ok', 'component: ', this.$refs.component)
			let selected = this.$refs.component.getSelected();
			//console.log('dictionary', 'ok', 'selected: ', selected)
			this.$emit('add', selected)

			this.$refs.dialog.close()
		},

		refresh () {
			this.loading = true;
			let that = this
			this.$store.dispatch(`${this.module}/search`, {
				success: function(response) {
					that.loading = false;
				},
				error: function(response) {
					that.loading = false
				}
			});
		},

		/* addItem (items, item) {
			items.push(Object.assign({}, item))
		},
		updateItem (items, selected, item) {
			const index = items.indexOf(selected)
			items.splice(index, 1, item)
		},
		removeItem (items, item) {
			const index = items.indexOf(item)
			items.splice(index, 1);
		},

		moveItem (items, item, direction) {
			const index = items.indexOf(item)
			if((direction == 'up' || direction == 'left') && index > 0) {
				items.splice(index-1, 0, items.splice(index, 1)[0]);
			}
			if((direction == 'down' || direction == 'right') && index < items.length) {
				items.splice(index+1, 0, items.splice(index, 1)[0]);
			}
			for(let i in items) {
				items[i]['ordinal'] = i
			}
		}, */
	},
}
</script>
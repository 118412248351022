<template>
	<v-card>
		<v-toolbar class="elevation-1">
			
			<!-- Types -->
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-tooltip bottom :value="item">
						<template v-slot:activator="{ on: tooltip }">
							<v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...on }">
								<v-icon>{{ item.keyboardtype.icon }}</v-icon>
							</v-btn>
						</template>
						<span>{{ $vuetify.lang.t('$vuetify.' + item.keyboardtype.name) | capitalize }}</span>
					</v-tooltip>
				</template>
				<v-list dense>
					<v-list-item v-for="(item, index) in types" :key="index" @click="OnType(item)">
						<v-list-item-icon>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-html="item.name"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>

						<!-- <v-list-item-title>{{ $vuetify.lang.t('$vuetify.'+item.name) | capitalize }}</v-list-item-title> -->
					</v-list-item>
				</v-list>
			</v-menu>

			<v-divider vertical inset></v-divider>

			<v-dialog v-model="dialogTableAdd" persistent max-width="290" >
				<template v-slot:activator="{ on, attrs }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...on }">
								<v-icon>mdi-table-large-plus</v-icon>
							</v-btn>
						</template>
						<span>{{ $vuetify.lang.t('$vuetify.tableAdd') | capitalize }}</span>
					</v-tooltip>
				</template>
				<v-card>
					<v-card-title class="text-h5">{{ $vuetify.lang.t('$vuetify.tableAdd') | capitalize }}</v-card-title>
					<v-card-text>
						<v-text-field
							v-model="rowcount"
							:label="$vuetify.lang.t('$vuetify.rows') | capitalize"
							type="number"
							min="1"
							max="12"
						></v-text-field>
						<v-text-field
							v-model="colcount"
							:label="$vuetify.lang.t('$vuetify.columns') | capitalize"
							type="number"
							min="1"
							max="12"
						></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn  text @click="dialogTableAdd = false">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
						<v-btn @click="tableAdd(rowcount, colcount)">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="tableRemove">
						<v-icon>mdi-table-large-remove</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableRemove') | capitalize }}</span>
			</v-tooltip>

			<!-- <v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="Refresh">
						<v-icon>mdi-table-refresh</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableRefresh') | capitalize }}</span>
			</v-tooltip> -->

			<v-divider vertical inset></v-divider>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="rowAddBefore" :disabled="!selected">
						<v-icon>mdi-table-row-plus-before</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableRowAddBefore') | capitalize }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="rowAddAfter" :disabled="!selected">
						<v-icon>mdi-table-row-plus-after</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableRowAddAfter') | capitalize }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="rowRemove(selected)" :disabled="!selected">
						<v-icon>mdi-table-minus</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableRowRemove') | capitalize }}</span>
			</v-tooltip>

			<v-divider vertical inset></v-divider>
			
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="colAddBefore" :disabled="!selected">
						<v-icon>mdi-table-column-plus-before</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableColAddBefore') | capitalize }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="colAddAfter" :disabled="!selected">
						<v-icon>mdi-table-column-plus-after</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableColAddAfter') | capitalize }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="colRemove()" :disabled="!selected">
						<v-icon>mdi-table-column-remove</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableColRemove') | capitalize }}</span>
			</v-tooltip>

			<v-divider vertical inset></v-divider>
			
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="moveLeft" :disabled="!selected">
						<v-icon>mdi-arrow-left-bold</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableColMoveLeft') | capitalize }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="moveRight" :disabled="!selected">
						<v-icon>mdi-arrow-right-bold</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableColMoveRight') | capitalize }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="moveUp"  :disabled="!selected">
						<v-icon>mdi-arrow-up-bold</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableRowMoveUp') | capitalize }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="moveDown"  :disabled="!selected">
						<v-icon>mdi-arrow-down-bold</v-icon>
					</v-btn>
				</template>
				<span>{{ $vuetify.lang.t('$vuetify.tableRowMoveDown') | capitalize }}</span>
			</v-tooltip>

			<v-divider vertical inset></v-divider>

			<!-- Properties -->
			<detail module="keyboardbutton" mode="update" activator="icon" return-object @update="OnProperties" @change="OnProperties" no-clear :disabled="!selected" offline></detail>

			<v-menu bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item>
						<v-list-item-action>
							<v-checkbox :input-value="item.keyboardOnetime" color="primary"></v-checkbox>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title>One time</v-list-item-title>
							<!-- <v-list-item-subtitle>One time</v-list-item-subtitle> -->
						</v-list-item-content>
					</v-list-item>

					<v-list-item>
						<v-list-item-action>
							<v-checkbox :input-value="item.keyboardResize" color="primary"></v-checkbox>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title>Resize</v-list-item-title>
							<!-- <v-list-item-subtitle>resize</v-list-item-subtitle> -->
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-divider vertical inset></v-divider>

			<!-- <v-btn text @click="OnTest">Test</v-btn> -->

		</v-toolbar>
		<v-card-text>
			<v-row dense v-for="(row, i) in keyboarditems" :key="i">
				<v-col v-for="(item, j) in row" :key="j">
					<v-badge bordered color="red" overlap dot bottom :value="item.scenarium == null" class="d-block">
						<v-badge bordered color="green" overlap dot :value="item.funcs.length" class="d-block">
							<v-btn class="text-caption" style="text-transform: none !important;" 
								elevation="2" 
								block 
								@click="select(item)"
								:color="item==selected?'info':null"
								v-html="item.text"
							></v-btn>
						</v-badge>
					</v-badge>
				</v-col>
				<!-- 
					:color="item==selected?'info':null"
					:color="item.ordinal==selected.ordinal && item.row==selected.row?'info':null"
				-->
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	components: {
		detail: () => import('@/views/components/detail')
	},
	data () {
		return {
			selected: null,

			dialogTableAdd: false,
			dialogProperties: false,

			rowcount: 1,
			colcount: 1, 
		}
	},
	props: {
		module: 		{ type: String, default: null },	// Модуль: users | roles | ....
	},
	watch: {
		/* items(val) {
			//console.log('keyboard', 'watch.items', 'val: ', val)
		}, */

		/* type(val) {
			this.keyboard.type = val
		} */
	},
	computed: {
		keyboarditems () {
			let result = {}
			if(this.item.hasOwnProperty('buttons')) {
				for(let i in this.item.buttons) {
					let button = this.item.buttons[i]
					let row = button.row

					if(!result.hasOwnProperty(row)) {
						result[row] = []
					}
					result[row].push(button)
				}
			}

			//console.log('keyboard.vue', 'computed.keyboarditems', 'result: ', result)

			return result;
		},

		types: function () {
			return this.$store.getters['keyboardtype/items']
		},
		
		item: function () {
			//console.log('mixin.form', this.module, 'item: ', this.$store.getters[this.module + '/item'])
			return this.$store.getters[this.module + '/item']
		},

		default: function () {
			return this.$store.getters['keyboardbutton/default']
		},
	},
	created () {
		this.clear()
	},
	methods: {
		clear() {
			this.item.keyboardtype = this.types[0];
		},

		// Виділення кнопки
		select (item) {
			//console.log('keyboard', 'select', 'item: ', item)
			//console.log('keyboard', 'select', 'item.funcs', item.funcs)
			
			this.selected = item
			this.$store.commit(`keyboardbutton/item`, JSON.parse(JSON.stringify(item)))
		},

		OnType (item) {
			this.item.keyboardtype = item
		},

		selectUpdate () {
			if(this.selected) {
				for(let i in this.item.buttons) {
					let button = this.item.buttons[i]

					if(button.row == this.selected.row && button.ordinal == this.selected.ordinal) {
						this.selected = button
					}
				}
			}
		},

		OnProperties(item) {
			//console.log('keyboard', 'OnProperties', 'item: ', item)

			this.selected.text = item.text
			this.$set(this.selected, 'scenarium', item.scenarium)
			this.$set(this.selected, 'funcs', item.funcs)
		},

		tableAdd (rowcount, colcount) {
			var index = 0

			for(let r = 0; r < rowcount; r++) {
				for(let c = 0; c < colcount; c++) {
					index++
					let item = JSON.parse(JSON.stringify(this.default));
					item.row = r
					item.ordinal = c
					item.text = 'Кнопка '+ index.toString()

					this.select(item)
					this.item.buttons.push(item)
				}
			}

			this.dialogTableAdd = false;
		},

		tableRemove () {
			this.select(null)

			for (let i = this.item.buttons.length - 1; i >= 0; i -= 1) {
				this.item.buttons.splice(i, 1);
			}
		},

		rowAddBefore () {
			let row = this.selected.row

			for(let i in this.item.buttons) {
				let button = this.item.buttons[i]
				if(button.row >= row) {
					button.row++
				}
			}

			//console.log('keyboard.vue', 'rowAddBefore', 'buttons: ', buttons)

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row
			item.ordinal = 0
			item.text = 'Кнопка '+ (this.item.buttons.length + 1).toString()

			this.item.buttons.push(item)
			this.select(item)
		},

		rowAddAfter () {
			let row = this.selected.row

			for(let i in this.item.buttons) {
				let item = this.item.buttons[i]
				if(item.row > row) {
					item.row++
				}
			}

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row+1
			item.ordinal = 0
			item.text = 'Кнопка '+ (this.item.buttons.length + 1).toString()

			this.item.buttons.push(item)
			this.select(item)
		},

		rowRemove (selected) {
			let row = this.selected.row

			for (let i = this.item.buttons.length - 1; i >= 0; i -= 1) {
				let item = this.item.buttons[i]
				if(item.row === row) {
					this.item.buttons.splice(i, 1);
				}
				if(item.row > row) {
					item.row--
				}
			}

			this.select(null)
		},

		colAddBefore () {
			let ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.item.buttons) {
				let item = this.item.buttons[i]
				if(item.row === row) {
					if(item.ordinal >= ordinal) {
						item.ordinal++
					}
				}
			}

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row
			item.ordinal = ordinal
			item.text = 'Кнопка '+ (this.item.buttons.length + 1).toString()

			this.select(item)
			this.item.buttons.push(item)
		},
		colAddAfter () {
			let ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.item.buttons) {
				let item = this.item.buttons[i]
				if(item.row === row) {
					if(item.ordinal > ordinal) {
						item.ordinal++
					}
				}
			}

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row
			item.ordinal = ordinal+1
			item.text = 'Кнопка '+ (this.item.buttons.length + 1).toString()

			this.select(item)
			this.item.buttons.push(item)
		},
		colRemove () {
			// Видалення рядка, якщо видаляється остання кнопка в рядку
			if(this.keyboarditems[this.selected.row].length === 1 ) {
				this.rowRemove(this.selected)
				return
			}

			for (let i = this.item.buttons.length - 1; i >= 0; i -= 1) {
				let item = this.item.buttons[i]
				if(item.row === this.selected.row) {
					if(item.ordinal === this.selected.ordinal) {
						this.item.buttons.splice(i, 1);
					} 
					console.log(item.text, item.ordinal)
					if(item.ordinal > this.selected.ordinal) {
						item.ordinal--
					}
					console.log(item.text, item.ordinal)
				}
				
			}

			this.select(null)
		},

		moveLeft () {
			//console.log('keyboard.vue', 'moveLeft', 'selected: ', this.selected.name + ' ('+this.selected.row.toString() + ' : ' + this.selected.ordinal.toString()+')')
			let ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.item.buttons) {
				let button = this.item.buttons[i]
				if(button.row === row) {
					if(button.ordinal === ordinal-1) {
						button.ordinal++
						console.log('keyboard.vue', 'moveLeft', 'selected: ', this.selected.name + ' ('+this.selected.row.toString() + ' : ' + this.selected.ordinal.toString()+')')
						this.selected.ordinal--
						console.log('keyboard.vue', 'moveLeft', 'selected: ', this.selected.name + ' ('+this.selected.row.toString() + ' : ' + this.selected.ordinal.toString()+')')
					}
				}
			}
		},
		moveRight () {
			//console.log('keyboard.vue', 'moveRight', 'selected: ', this.selected.name + ' ('+this.selected.row.toString() + ' : ' + this.selected.ordinal.toString()+')')

			let ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.item.buttons) {
				let button = this.item.buttons[i]
				if(button.row === row) {
					if(button.ordinal === ordinal+1) {
						button.ordinal--
						this.selected.ordinal++
					}
				}
			}
		},

		moveUp () {
			let ordinal 	= this.selected.ordinal
				,row 		= this.selected.row
				
			if(this.keyboarditems.hasOwnProperty(row-1)) {
				let rowNew		= row-1
					,ordinalNew = this.keyboarditems[rowNew].length

				for(let i in this.item.buttons) {
					let button = this.item.buttons[i]
					if(button.row === row) {
						if(button.ordinal === ordinal) {
							button.row 		= rowNew
							button.ordinal 	= ordinalNew
							this.select(button)
						} else if(button.ordinal > ordinal) {
							button.ordinal--
						}
					}
				}
			}
		},
		moveDown () {
			let ordinal 	= this.selected.ordinal
				,row 		= this.selected.row
				,rowNew		= row+1

			if(this.keyboarditems.hasOwnProperty(rowNew)) {
				let ordinalNew = this.keyboarditems[rowNew].length
				for(let i in this.item.buttons) {
					let button = this.item.buttons[i]
					if(button.row === row) {
						if(button.ordinal === ordinal) {
							button.row 		= rowNew
							button.ordinal 	= ordinalNew
							this.select(button)
						} else if(button.ordinal > ordinal) {
							button.ordinal--
						}
					}
				}
			}
		},

		/*
		selectUpdate () {
			if(this.selected) {
				if(this.keyboard.hasOwnProperty('buttons')) {
					let buttons = this.keyboard.buttons
					for(let i in buttons) {
						let button = buttons[i]

						if(button.row == this.selected.row && button.ordinal == this.selected.ordinal) {
							this.selected = button
						}
					}
				}
			}
		},

		OnChange(item) {
			//console.log('keyboard', 'OnChange', 'item: ', item)

			let buttons 		= []
				,ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let button = this.keyboard.buttons[i]
				if(button.row === row && button.ordinal === ordinal) {
					//console.log('keyboard', 'OnChange', 'item: ', item)
					button.name = item.name
					this.$set(button, 'scenarium', item.scenarium)
					this.$set(button, 'funcs', item.funcs)
					
					this.select(button)
				}
				buttons.push(button)
			}

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},

		tableAdd (rowcount, colcount) {
			var buttons = [], index = 0

			this.keyboards = []

			for(let r = 0; r < rowcount; r++) {
				for(let c = 0; c < colcount; c++) {
					index++
					let item = JSON.parse(JSON.stringify(this.default));
					item.row = r
					item.ordinal = c
					item.name = 'Кнопка '+ index.toString()

					this.select(item)

					buttons.push(item)
				}
			}

			this.$set(this.keyboard, 'buttons', buttons)
			this.keyboards.push(this.keyboard)
			this.$emit('change', this.keyboards)

			this.dialogTableAdd = false;
		},

		tableRemove () {
			this.select(null)
	
			//this.clear()
			this.keyboards = []
			this.keyboard = {}
			this.$emit('change', this.keyboards)
		},

		rowAddBefore () {
			let buttons = []
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let button = this.keyboard.buttons[i]
				if(button.row >= row) {
					button.row++
				}
				buttons.push(button)
			}

			//console.log('keyboard.vue', 'rowAddBefore', 'buttons: ', buttons)

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row
			item.ordinal = 0
			item.name = 'Кнопка '+ (buttons.length + 1).toString()

			this.select(item)

			buttons.push(item)

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},

		rowAddAfter () {
			let buttons = []
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let item = this.keyboard.buttons[i]
				if(item.row > row) {
					item.row++
				}
				buttons.push(item)
			}

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row+1
			item.ordinal = 0
			item.name = 'Кнопка '+ (buttons.length + 1).toString()

			this.select(item)

			buttons.push(item)

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},

		rowRemove (selected) {
			let buttons = []
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let item = this.keyboard.buttons[i]
				if(item.row === row) {
					continue
				}

				if(item.row > row) {
					item.row--
				}
				buttons.push(item)
			}

			this.select(null)

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},

		colAddBefore () {
			let buttons 		= []
				,ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let item = this.keyboard.buttons[i]
				if(item.row === row) {
					if(item.ordinal >= ordinal) {
						item.ordinal++
					}
				}
				buttons.push(item)
			}

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row
			item.ordinal = ordinal
			item.name = 'Кнопка '+ (buttons.length + 1).toString()

			this.select(item)

			buttons.push(item)

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},
		colAddAfter () {
			let buttons 		= []
				,ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let item = this.keyboard.buttons[i]
				if(item.row === row) {
					if(item.ordinal > ordinal) {
						item.ordinal++
					}
				}
				buttons.push(item)
			}

			let item = JSON.parse(JSON.stringify(this.default));
			item.row = row
			item.ordinal = ordinal+1
			item.name = 'Кнопка '+ (buttons.length + 1).toString()

			this.select(item)

			buttons.push(item)

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},
		colRemove () {
			// Видалення рядка, якщо видаляється остання кнопка в рядку
			if(this.keyboarditems[this.selected.row].length === 1 ) {
				this.rowRemove(this.selected)
				return
			}

			let buttons = []
			for(let i in this.keyboard.buttons) {
				let item = this.keyboard.buttons[i]
				if(item.row === this.selected.row) {
					if(item.ordinal === this.selected.ordinal) {
						continue
					}
					if(item.ordinal > this.selected.ordinal) {
						item.ordinal--
					}
				}
				buttons.push(item)
			}

			this.select(null)

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},

		moveLeft () {
			//console.log('keyboard.vue', 'moveLeft', 'selected: ', this.selected.name + ' ('+this.selected.row.toString() + ' : ' + this.selected.ordinal.toString()+')')
			let buttons 	= []
				,ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let button = this.keyboard.buttons[i]
				if(button.row === row) {
					if(button.ordinal === ordinal-1) {
						button.ordinal++
						this.selected.ordinal--
					}
				}
				buttons.push(button)
			}

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},
		moveRight () {
			//console.log('keyboard.vue', 'moveRight', 'selected: ', this.selected.name + ' ('+this.selected.row.toString() + ' : ' + this.selected.ordinal.toString()+')')

			let buttons 	= []
				,ordinal 	= this.selected.ordinal
				,row 		= this.selected.row

			for(let i in this.keyboard.buttons) {
				let button = this.keyboard.buttons[i]
				if(button.row === row) {
					if(button.ordinal === ordinal+1) {
						button.ordinal--
						this.selected.ordinal++
					}
				}
				buttons.push(button)
			}

			this.$set(this.keyboard, 'buttons', buttons)
			this.$emit('change', this.keyboards)
		},

		moveUp () {
			let buttons 	= []
				,ordinal 	= this.selected.ordinal
				,row 		= this.selected.row
				
			if(this.keyboarditems.hasOwnProperty(row-1)) {
				let rowNew		= row-1
					,ordinalNew = this.keyboarditems[rowNew].length

				for(let i in this.keyboard.buttons) {
					let button = this.keyboard.buttons[i]
					if(button.row === row) {
						if(button.ordinal === ordinal) {
							button.row 		= rowNew
							button.ordinal 	= ordinalNew
							this.select(button)
						} else if(button.ordinal > ordinal) {
							button.ordinal--
						}
					}
					buttons.push(button)
				}
				this.$set(this.keyboard, 'buttons', buttons)
				this.$emit('change', this.keyboards)
			}
		},
		moveDown () {
			let buttons 		= []
				,ordinal 	= this.selected.ordinal
				,row 		= this.selected.row
				,rowNew		= row+1

			if(this.keyboarditems.hasOwnProperty(rowNew)) {
				let ordinalNew = this.keyboarditems[rowNew].length
				for(let i in this.keyboard.buttons) {
					let button = this.keyboard.buttons[i]
					if(button.row === row) {
						if(button.ordinal === ordinal) {
							button.row 		= rowNew
							button.ordinal 	= ordinalNew
							this.select(button)
						} else if(button.ordinal > ordinal) {
							button.ordinal--
						}
					}
					buttons.push(button)
				}
				this.$set(this.keyboard, 'buttons', buttons)
				this.$emit('change', this.keyboards)
			}
		},
		*/
		OnTest() {
			//console.log('keyboard.vue', 'OnTest', 'types: ', this.types)
			console.log('keyboard.vue', 'OnTest', 'keyboards: ', this.keyboards)
			console.log('keyboard.vue', 'OnTest', 'keyboard: ', this.keyboard)
		},
	},
}
</script>
export default {
    namespaced: true,
    state: {
        name: 'botcommand',
        title: {
            single: 'botcommand',
            plural: 'botcommands'
        },
        icon: 'mdi-robot',

        item:  {},
        items: [],

        default: {
            id: null,
            name: null,
            description: null,
            bot: null,
            scenarium: null,
            hidden: 1,
        },

        headers: [
            { text: 'id', value: 'id', sortable: true},
            { text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
            { text: 'bot', value: 'bot.name', sortable: true},
            { text: 'scenarium', value: 'scenarium.name', sortable: true},
            { text: 'hidden', value: 'hidden', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],

        tabs: [
			{ title: 'main', type: 'form', module: 'botcommand' },
		],
    },
}
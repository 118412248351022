
export default {
    namespaced: true,
    state: {
        name: 'chatstypes',
        title: {
            single: 'chatstype',
            plural: 'chatstypes'
        },
        icon: 'mdi-family-tree',

        items: [],
    },
}
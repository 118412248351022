var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"elevation-1"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","value":_vm.item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","dark":""}},'v-btn',attrs,false),Object.assign({}, tooltip, on)),[_c('v-icon',[_vm._v(_vm._s(_vm.item.keyboardtype.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.item.keyboardtype.name))))])])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.types),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.OnType(item)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.description)}})],1)],1)}),1)],1),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, on)),[_c('v-icon',[_vm._v("mdi-table-large-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableAdd'))))])])]}}]),model:{value:(_vm.dialogTableAdd),callback:function ($$v) {_vm.dialogTableAdd=$$v},expression:"dialogTableAdd"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableAdd'))))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.rows')),"type":"number","min":"1","max":"12"},model:{value:(_vm.rowcount),callback:function ($$v) {_vm.rowcount=$$v},expression:"rowcount"}}),_c('v-text-field',{attrs:{"label":_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.columns')),"type":"number","min":"1","max":"12"},model:{value:(_vm.colcount),callback:function ($$v) {_vm.colcount=$$v},expression:"colcount"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogTableAdd = false}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel')))]),_c('v-btn',{on:{"click":function($event){return _vm.tableAdd(_vm.rowcount, _vm.colcount)}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.ok')))])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.tableRemove}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-large-remove")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableRemove'))))])]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.rowAddBefore}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-row-plus-before")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableRowAddBefore'))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.rowAddAfter}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-row-plus-after")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableRowAddAfter'))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":function($event){return _vm.rowRemove(_vm.selected)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-minus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableRowRemove'))))])]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.colAddBefore}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-column-plus-before")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableColAddBefore'))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.colAddAfter}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-column-plus-after")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableColAddAfter'))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":function($event){return _vm.colRemove()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-column-remove")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableColRemove'))))])]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.moveLeft}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableColMoveLeft'))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.moveRight}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableColMoveRight'))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.moveUp}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-up-bold")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableRowMoveUp'))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.selected},on:{"click":_vm.moveDown}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-down-bold")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.tableRowMoveDown'))))])]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('detail',{attrs:{"module":"keyboardbutton","mode":"update","activator":"icon","return-object":"","no-clear":"","disabled":!_vm.selected,"offline":""},on:{"update":_vm.OnProperties,"change":_vm.OnProperties}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.item.keyboardOnetime,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("One time")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.item.keyboardResize,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Resize")])],1)],1)],1)],1),_c('v-divider',{attrs:{"vertical":"","inset":""}})],1),_c('v-card-text',_vm._l((_vm.keyboarditems),function(row,i){return _c('v-row',{key:i,attrs:{"dense":""}},_vm._l((row),function(item,j){return _c('v-col',{key:j},[_c('v-badge',{staticClass:"d-block",attrs:{"bordered":"","color":"red","overlap":"","dot":"","bottom":"","value":item.scenarium == null}},[_c('v-badge',{staticClass:"d-block",attrs:{"bordered":"","color":"green","overlap":"","dot":"","value":item.funcs.length}},[_c('v-btn',{staticClass:"text-caption",staticStyle:{"text-transform":"none !important"},attrs:{"elevation":"2","block":"","color":item==_vm.selected?'info':null},domProps:{"innerHTML":_vm._s(item.text)},on:{"click":function($event){return _vm.select(item)}}})],1)],1)],1)}),1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
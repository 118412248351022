<template>
	<v-layout fill-height :loading="loading">

		<!-- Dialogs an messages -->
		<v-navigation-drawer absolute permanent style="padding: 0px 0px 0px 300px; width: 100%;" color="chatBactground">
			<v-container class="fill-height">
				<v-row class="fill-height pb-14" align="end">
					<v-col>
						<v-card v-for="(message, index) in messages" :key="index" elevation="0" color="chatBactground">
							<v-card elevation="0"  color="chatBactground">
								<div v-if="message.from.isBot">
									<chatMessage color="request" :message="message"></chatMessage>
								</div>
								<div v-else :class="['d-flex', 'justify-end']">
									<chatMessage color="respond" justify="right" :message="message"></chatMessage>
								</div>
							</v-card>
						</v-card>
					</v-col>
				</v-row>
			</v-container>

			<template v-slot:append>
				<div>
					<!-- Send message -->
					<v-text-field v-if="chat"
						outlined
						v-model="messagetext"
						:label="$vuetify.lang.t('$vuetify.message') | capitalize" 
						hide-details="auto"
					>
						<template v-slot:prepend>
							<v-menu top offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="blue" rounded dark v-bind="attrs" v-on="on">
										<v-icon>mdi-menu</v-icon>
										{{ $vuetify.lang.t('$vuetify.menu') | capitalize }} 
									</v-btn>
								</template>

								<v-list two-line dense>
									<v-list-item v-for="(item, index) in commands" :key="index" @click="sendScenarium(item)">
										<v-list-item-content>
											<v-list-item-title>{{ item.name }}</v-list-item-title>
											<v-list-item-subtitle v-text="item.code"></v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-icon v-on="on">mdi-emoticon</v-icon>
								</template>
								{{ $vuetify.lang.t('$vuetify.sticker') | capitalize }}
							</v-tooltip>
						</template>
						<template slot="append">
							<v-icon @click="sendText" :disabled="!chat">mdi-send</v-icon>
						</template>
						<template slot="append-outer">
							<z-dictionary
								module="scenarium"
								type="tree"
								:multiple="false"
								acvicon="mdi-family-tree"
								@add="sendScenarium"
							></z-dictionary>
							<messagesDetail
								avicon="mdi-paperclip"
								@send="sendMessage"
							></messagesDetail>

						</template>
					</v-text-field>
				</div>
			</template>
		</v-navigation-drawer>
		
		<v-file-input class="d-none" ref="fileinput" @change="sendFile"></v-file-input>

		<!-- Chats -->
		<v-navigation-drawer absolute permanent floating :loading="loading">
			<template v-slot:prepend>
				<v-system-bar height="30" color="transparent">
					<v-icon>mdi-chat</v-icon>
					<v-subheader>{{ $vuetify.lang.t('$vuetify.' + title.plural) | capitalize }}</v-subheader>
					<v-spacer></v-spacer>
					<v-icon @click="refreshChats">mdi-autorenew</v-icon>
				</v-system-bar>
			</template>

			<v-list two-line dense>
				<v-list-item-group v-model="selected" color="primary">
					<v-list-item v-for="(item, index) in items" :key="index" @click="clickChat(item)">
						<v-list-item-avatar>
							<v-icon v-if="item.isBot">mdi-robot</v-icon>
							<v-icon v-else>mdi-account</v-icon>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title v-html="item.firstName + ' ' + (item.lastName?item.lastName:'')"></v-list-item-title>
							<v-list-item-subtitle v-html="item.type"></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>

			<template v-slot:append>
				
			</template>
		</v-navigation-drawer>

		<v-overlay :value="loading">
			<v-progress-circular
				:size="50"
				color="primary"
				indeterminate
			></v-progress-circular>
		</v-overlay>
	</v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import chatMessage from '@/views/components/chat.message.vue';
import messagesDetail from '@/views/messages.detail'
import zDictionary from '@/views/components/dictionary'

export default {
	components: {
		chatMessage
		, messagesDetail, zDictionary
	},
	data: () => ({
		loading: false,
		selected: [],

		chat: null,
		dialog: null,

		messagetext: null,
	}),
	computed: {
		...mapGetters('scenarium', 	{ scenariums: 'items' }),
		...mapGetters('chats', 		[ 'title', 'icon', 'items', 'item' ]),
		...mapGetters('messages', 	{ messages: 'items' }),
		commands () {
			let codes =['/start', '/end', '/help', '/info', '/introduction']
			let items = []
			for(let i in codes) {
				let scenarium = this.scenariums.find(item => item.code == codes[i])
				if(scenarium) {
					items.push(scenarium)
				}
			}
			return items
		},
	},
	watch: {	
		/* chat (val) {
			if(val) {
				//this.OnRefreshDialogs()
			}
		}, */
		/* dialog (val) {
			if(val) {
				this.OnRefreshReplics()
			}
		}, */
    },
	created () {
		if (this.items.length == 0) {
			this.refreshChats()
		}
	},
	methods: {
		...mapActions('chats', 		[ 'search']),
		...mapActions('messages', 	[ 'send', 'findByChat' ]),

		/* replicText (replic) {
			//console.log('chats', 'methods::replicText','replic', replic)
			if(replic) {
				if(replic.text) {
					return replic.text
				} else {
					if(replic.scenarium) {
						if(replic.scenarium.text) {
							return replic.scenarium.text
						}
					}
				}
			}
			return '';
		}, */

		OnRefresh () {

		},
		
		refreshChats () {
			let that = this
			that.loading = true;
			this.search({
				//item: this.item,
				success: function(response) {
					that.loading = false;
					//that.OnClose();
				},
				error: function(response) {
					that.loading = false
					//that.$refs.alert.Open(response.data, 'error')
				}
			});
		},

		clickChat (item) {
			//console.log('chats', 'methods::clickChat','item', item)
			this.chat = item

			let that = this
			that.loading = true;
			this.findByChat({
				item: item,
				success: function(response) {
					that.loading = false;
					//that.OnClose();
					//console.log('chats', 'methods::clickChat','item', that.item)
					//console.log('chats', 'methods::clickChat','messages', that.messages)
					that.$vuetify.goTo(9999, {
						duration: 300,
						offset: 0,
						easing: 'easeInOutCubic',
					})
				},
				error: function(response) {
					that.loading = false
					//that.$refs.alert.Open(response.data, 'error')
				}
			});
		},

		sendText () {
			let that = this
			that.loading = true;
			this.send({
				item: {
					chatId: this.chat.id,
					text: this.messagetext,
				},
				success: function(response) {
					that.loading = false;
					that.messagetext = null
					//that.OnClose();
					//console.log('chats', 'methods::clickChat','item', that.item)
					//console.log('chats', 'methods::clickChat','messages', that.messages)
				},
				error: function(response) {
					that.loading = false
					//that.$refs.alert.Open(response.data, 'error')
				}
			});
		},

		sendScenarium (scenarium) {
			//console.log('chats.vue', 'methods::sendScenarium','scenarium', scenarium)

			let that = this
			that.loading = true;
			this.send({
				item: {
					chatId: this.chat.id,
					scenariumId: scenarium.id,
				},
				success: function(response) {
					that.loading = false;
					//console.log('chats', 'methods::clickChat','item', that.item)
					//console.log('chats', 'methods::clickChat','messages', that.messages)
				},
				error: function(response) {
					that.loading = false
					//that.$refs.alert.Open(response.data, 'error')
				}
			});
		},

		sendFile (file) {
			let formData = new FormData();
			formData.append("chatId", this.chat.id);

			//console.log('sendFile', 'file.name', file.name)
			console.log('sendFile', 'file', file)

			switch(file.type){
				case 'image/jpeg': 		formData.append("photo", file, file.name); formData.append("caption", this.messagetext); break;
				case 'image/gif': 		formData.append("photo", file, file.name); formData.append("caption", this.messagetext); break;
				case 'image/png': 		formData.append("photo", file, file.name); formData.append("caption", this.messagetext); break;
				case 'image/jpeg': 		formData.append("photo", file, file.name); formData.append("caption", this.messagetext); break;
				case 'image/webp': 		formData.append("photo", file, file.name); formData.append("caption", this.messagetext); break;
				case 'audio/mpeg': 		formData.append("audio", file, file.name); formData.append("title", this.messagetext); break;
				case 'video/quicktime': formData.append("video", file, file.name); formData.append("caption", this.messagetext); break;
				case 'video/mp4': 		formData.append("video", file, file.name); formData.append("caption", this.messagetext); break;
				default: formData.append("document", file, file.name); formData.append("caption", this.messagetext); 
			}

			let that = this
			that.loading = true;
			this.send({
				item: formData,
				config: {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				},
				success: function(response) {
					that.loading = false;
					that.messagetext = null;
				},
				error: function(response) {
					that.loading = false
				}
			});
		},

		sendMessage (item) {
			let formData = new FormData();
			formData.append("chatId", this.chat.id);

			for(var key in item) {
				let value = item[key]
				if(['photo','document','audio','sticker','video','animation','voice','media'].includes(key)) {
					formData.append(key, value, value.name)
				} else {
					formData.append(key, value)
				}
			}

			let that = this
			that.loading = true;
			this.send({
				item: formData,
				config: {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				},
				success: function(response) {
					that.loading = false;
					that.messagetext = null;
				},
				error: function(response) {
					that.loading = false
				}
			});
		},
	},
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"elevation":"0","loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([_vm._l((_vm.headers),function(item,index){return {key:("header." + (item.value)),fn:function(ref){return [_c('span',{key:index},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + item.text))))])]}}}),{key:"item.datecreate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("yyyy-mm-dd")(item.datecreate)))])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.hidden",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.hidden),callback:function ($$v) {_vm.$set(item, "hidden", $$v)},expression:"item.hidden"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.removable)?_c('confirm',{on:{"agree":function($event){return _vm.$emit('remove', item)}}}):_vm._e(),(_vm.editable)?_c('detail',{attrs:{"module":_vm.module,"mode":"update","activator":"icon","selected":item}}):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
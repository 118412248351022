export default {
	//...en,
	langname: "English",
	language: "language",
	homepage: "Home",
	profile: "Profile",

	commonAttributes: "common attributes",
	type: "type",
	name: "name",
	namePersonOrganisation: "name",
	number: "number",
	date: "date",
	from: "from",
	to: "to",
	yes: "yes",
	not: "not",
	error: "error",

	commonActions: "common actions",
	actions: "actions",
	enter: "enter",
	exit: "exit",
	ok: "ok",
	cancel: "cancel",
	confirm: "confirm",
	agree: "agree",
	disagree: "disagree",
	add: "add",
	remove: "remove",
	clear: "clear",
	new: "new",
	edit: "edit",
	create: "create",
	update: "update",
	delete: "delete",
	search: "search",
	login: "login",
	signin: "Sign In",
	signup: "signup",
	save: "save",
	open: "open",
	close: "close",
	cut: "cut",
	copy: "copy",
	duplicate: "duplicate",
	paste: "paste",
	refresh: "refresh",
	export: "export",
	import: "import",
	authorization: "authorization",
	registration: "registration",
	createaccount: "Create Account",
	password: "password",
	passwordold: "password old",
	passwordnew: "password new",
	passwordconfirm: "password confirm",
	passwordchange: "password change",
	passwordreset: "password reset",
	passwordrecovery: "password recovery",
	passwordforgot: "Forgot password?",
	donthaveaccount: "don't you have account?",
	orderconnection: "Order connection",
	
	// Objects
	main: "main",
	account:"account",
	accounts:"accounts",
	bot:"bot",
	bots:"bots",
	botcommand:"botcommand",
	botcommands:"botcommands",
	users: "users",
	user: "user",
	roles: "roles",
	role: "role",
	scenarium: "scenarium",
	scenariums: "scenarium",
	chat: "chat",
	chats: "chats",
	gallery: "gallery",
	message: "message",
	messages: "messages",
	request: "request",
	requests: "requests",
	respond: "respond",
	responds: "responds",
	menu: "menu",
	menutype: "menutype",
	menutypes: "menutypes",
	funcs: "functions",
	func: "function",
	functype: "functype",
	functypes: "functypes",
	functions: "functions",
	function: "function",

	token: "token",
	text: "text",
	photo: "photo",
	document: "document",
	audio: "audio",
	sticker: "sticker",
	video: "video",
	animation: "animation",
	voice: "voice",
	contact: "contact",
	location: "location",
	venue: "venue",
	media: "media",

	caption: "caption",
	title: "title",
	firstName: "first name",
	lastName: "last name",
	phoneNumber: "phone number",
	disableNotification: "disable notification",
	onetime: "one time",
	resize: "resize",
	hidden: "hidden",
	
	//Keyboard
	keyboard: "keyboard",
	tableAdd: "add table",
	tableRemove: "remove table",
	tableRefresh: "refresh table",
	tableRowAddBefore: "add table row before",
	tableRowAddAfter: "add table row after",
	tableRowRemove: "remove table row",
	tableColAddBefore: "add table column before",
	tableColAddAfter: "add table column after",
	tableColRemove: "remove table column",
	tableColMoveLeft: "move table column left",
	tableColMoveRight: "move table column right",
	tableRowMoveUp: "move table row up",
	tableRowMoveDown: "move table row down",
	InlineKeyboardMarkup: "Inline Keyboard Markup",
	ReplyKeyboardMarkup: "Reply Keyboard Markup",
	ReplyKeyboardRemove: "Reply Keyboard Remove",
	properties: "properties",
	buttonsproperties: "button's properties",

	//Sentences
	"Are you sure you want to delete this record?": "Are you sure you want to delete this record?",
   
	row: "row",
	rows: "rows",
	column: "column",
	columns: "columns",

	//homepage.banner
	cameraoff: "На вашому пристрої доступ до камери заблоковано. В такому режимі ви можете перевіряти поліси, шляхом введення вручну номер полісу або цифри зі штрих-коду.",
	
	stopscan: "stop scan",
	startscan: "start scan",

	settings: "settings",
	video: "video",
	frame: "frame",
	laser: "laser",
	sound: "sound",
	soundeditor: "sound editor",
	volume: "volume",
	frequency: "frequency",
	duration: "duration",
	beeps: {
		scan: "scan",
		success: "success",
		failure: "failure",
		error: "error",
	},
	
	//Attributes
	id: "id",
	code: "code",
	username: "id",
	description: "description",
	datecreate: "datecreate",
	pib: "name and surname",
	telephone: "telephone",
	email: "email",
	comment: "comment",
	icon: "icon",
	regexpression: "regexpression",
	ordinal: "ordinal",
	type: "type",
	types: "types",
	datatype: "datatype",
	datatypes: "datatypes",
	keyboard: "keyboard",
	keyboards: "keyboards",
	keyboardtype: "keyboard's type",
	keyboardtypes: "keyboard types",
	keyboardbutton: "keyboard's button",
	keyboardbuttons: "keyboard buttons",
	button: "button",
	buttons: "buttons",


persons: "persons",
person: "person",
firstname: "name",
surname: "surname",
patronymic: "patronymic",
birthDate: "date of birth",
birthPlace: "place of birth",
deathDate: "date of death",
deathPlace: "place of death",
nationality: "nationality",
post: "post",
personsname: "person",
organization: "organization",

sex: "sex",
male: "male",
female: "female",

persons_roles: "person's roles",
claimant: "claimant",
owner: "owner",
driver: "driver",
previous_owners: "previous owners",

identification: "identification document",
seria: "seria",
number: "number",
issuedby: "issued by",
dateofissue: "date of issue",
recordno: "record No",
dateofexpiry: "date of expiry",
documentno: "document No",

identificationstype: "identification's type",
identificationstypes: "identification's types",
passport: "passport",
passportCitizen: "passport of the citizen",
passportForeign: "foreign passport",
passportDiplomatic: "diplomatic passport",
passportService: "service passport",
passportSeamen: "seamen's passport",
passportCrew: "crew member certificate",
passportReturn: "identity card for return",
passportTemporary: "temporary identity card",
driverLicense: "driver license",
identityCardTravel: "stateless identity card for travel abroad",
identityCardPermanent: "permanent residence permit",
identityCardTemporary: "temporary residence permit",
identityCardMigrant: "migrant card",
refugeeCertificate: "refugee certificate",
refugeeTravelDocument: "refugee travel document",
identityCardAdditionalProtection: "identity card in need of additional protection",
identityCardGrantedAdditionalProtection: "travel document of a person who has been granted additional protection",

addresses: "addresses",
address: "address",
postalCode: "postal сode",
сountry: "сountry",
locality: "locality",
district: "region",
street: "street",
house: "house",
apartment: "apartment",
latitude: "latitude",
longitude: "longitude",

addressestypes: "addresses types",
addresstype: "address type",
addressResidence: "address of residence",
addressRegistration: "address of registration",

documents: "documents",
document: "document",
templates: "templates",
template: "template",
constructors: "constructors",
constructor: "constructor",
//number: "number",
//date: "date",

policies: "insurance contracts",
policy: "insurance contract",
//number: "number",
//date: "date",
insurer: "insurer",
periodValidity: "validity period",

notifications: "notifications",
notification: "notification",
//number: "number",
//date: "date",

cases: "cases",
case: "case",
//number: "number",
//date: "date",

payments: "payments",
payment: "payment",
//number: "number",
//date: "date",

calendars: "calendars",
calendar: "calendars",
//number: "number",
//date: "date",

	//---------------------------
	
	company: "company",
	inn: "inn",
	
	//Rules
	rule: {
		required: "Value is required and can't be empty",
		lenless: "Name must be less than {0} characters",
		code: "Code must be less than {0} characters",
		personsname: "Person's name is not correct",
		email: "E-mail is not correct",
		phone: "Phone number is not correct (+380961112233)",
	},
	
	carousel: {
		ariaLabel: { 
			delimiter: ''
		}
	},

	noDataText: '',

	dataTable: {
		itemsPerPageText: '',
		ariaLabel: {
			sortNone: '',
			activateAscending: '',
		},
		loadingText: 'Loading... Please wait',
	},

	dataFooter: {
		prevPage: '',
		nextPage: '',
		itemsPerPageAll: '',
		pageText: '',
	},

	datePicker: { 
		prevMonthAriaLabel: '',
		nextMonthAriaLabel: '',
	},

	carousel: {
		prev: 'previous',
		next: 'next',
		ariaLabel: {
			delimiter: ''
		}
	},

	badge: '',
}
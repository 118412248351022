<template>
    <v-main>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-btn icon color="primary" @click="$router.push('/')">
						<v-icon >mdi-home</v-icon>
					</v-btn>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <v-card elevation="0" width="600px">
                        <v-toolbar class="elevation-0">
                            <v-icon class="mr-6">{{ icon }}</v-icon>
                            <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.'+title) | capitalize }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-form ref="form">
                                <v-text-field
                                    v-model="item.email"
                                    :label="$vuetify.lang.t('$vuetify.email') | capitalize"
                                    prepend-icon="mdi-email"
                                    type="text"
                                    :rules="[rules.required, rules.email]"
                                    required    
                                    @keyup.ctrl.90="OnDefault"
                                ></v-text-field>
                            </v-form>

                            <z-alert ref="alert"></z-alert>
                        </v-card-text>

                        <v-card-actions>
                            <!-- <v-spacer></v-spacer> -->
                            <v-btn large block
                                :loading="loading"
                                @click.native="OnOk"
                                color="success"
                                dark
                            >
                                <v-icon left dark>mdi-check-bold</v-icon>	
                                {{ $vuetify.lang.t('$vuetify.ok') }}
                            </v-btn>
                            <!-- <v-spacer></v-spacer> -->
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters, mapActions } from 'vuex'
import zAlert from '@/views/components/alert'


export default {
    components: {
        VueRecaptcha, zAlert
    },
    data: () => ({
        //title: 'passwordforgot',
        title: 'passwordrecovery',
        icon: 'mdi-account-question',
        loading: false,

        rules: {
            required: (value) => !!value || 'Required.',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Некоректний e-mail.'
            },
        },

        item: {
            email: null,
        },
    }),
   
    methods: {
        ...mapActions('app',[
            'PasswordRecovery'
        ]),

        OnDefault () {
            //ctrl+z
            this.item.email     = "qwins@i.ua"
        },  

        OnOk() {
            var that = this;

            this.$refs.alert.Close();

            if (this.$refs.form.validate()) {
                this.loading = true;
                this.PasswordRecovery({
                    item: this.item,
                    success: function(response) {
                        that.loading = false;
                        that.$refs.alert.Open(response.data, 'success')
                    },
                    error: function(response) {
                        that.loading = false
                        that.$refs.alert.Open(response.data, 'error')
                    },
                });
            }
        },
    },
}
</script>
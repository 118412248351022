import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import login from '../views/app.login.vue'
import signup from '../views/app.signup.vue'
import passwordChange from '../views/app.password.change.vue'
import passwordrecovery from '../views/app.password.recovery.vue'

import chats from '@/views/chats.vue'
import users from '@/views/users.vue'
import settings from '@/views/settings.vue'
import page from '@/views/components/page.vue'

Vue.use(VueRouter) 
const routes = [
	//{ path: '*', component: NotFoundComponent },
	{ path: '/', name: 'home', component: home },
	{ path: '/login', name: 'login', component: login },
	{ path: '/signup', name: 'signup', component: signup },
	{ path: '/password/change', name: 'passwordChange', component: passwordChange },
	{ path: '/password/recovery', name: 'passwordrecovery', component: passwordrecovery },

	{ path: '/chats',			name: 'chats',			component: chats },
	{ path: '/users',			name: 'users',			component: users },
	{ path: '/settings',		name: 'settings',		component: settings },

	{ path: '/scenarium',		name: 'scenarium',		component: page, 	 props: { module: 'scenarium', type: 'tree', itemsName: 'itemstree' } },
	
	{ path: '/bot',				name: 'bot',			component: page, 	 props: { module: 'bot' }},
	{ path: '/botcommand',		name: 'botcommand',		component: page, 	 props: { module: 'botcommand' }},
	{ path: '/accounts',		name: 'accounts',		component: page, 	 props: { module: 'accounts' }},
	{ path: '/roles',			name: 'roles',			component: page, 	 props: { module: 'roles' }},
	{ path: '/menu',			name: 'menu',			component: page, 	 props: { module: 'menu' }},
	{ path: '/menutype',		name: 'menutype',		component: page, 	 props: { module: 'menutype' }},
	{ path: '/funcs',			name: 'funcs',			component: page, 	 props: { module: 'funcs' }},
	{ path: '/functype',		name: 'functype',		component: page, 	 props: { module: 'functype' }},
	{ path: '/datatype',		name: 'datatype',		component: page, 	 props: { module: 'datatype' }},
	{ path: '/keyboard',		name: 'keyboard',		component: page, 	 props: { module: 'keyboard' }},


	/* {
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/About.vue')
	}, */
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,

	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
			}
		}
	}
})

export default router

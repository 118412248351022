import Vue from "vue"
export default {
    namespaced: true,
    state: {
        name: 'roles',
        title: {
            single: 'role',
            plural: 'roles'
        },
        icon: 'mdi-account-tie',

        item:  {},
        items: [],

        default: {
            id: null,
            code: null,
            name: null,
            ordinal: null,
            description: null,
            accounts: [],
            menu: []
        },

        headers: [
            { text: 'id', value: 'id', sortable: true},
            { text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],
        
        tabs: [
			{ title: 'main',        type: 'form', module: 'roles' },
			{ title: 'accounts',    type: 'list', module: 'accounts',   dataSource: 'accounts', deletable: true, actions: ['add'], itemTitle: 'username', itemSubtitle: 'login' },
			{ title: 'menu',        type: 'list', module: 'menu',       dataSource: 'menu',     deletable: true, actions: ['add'] },
		],
    },
}
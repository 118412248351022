export default {
    namespaced: true,
    state: {
        name: 'funcs',
        title: {
            single: 'function',
            plural: 'functions'
        },
        icon: 'mdi-function',

        item:  {},
        items: [],

        default: {
            id: null,
            code: null,
            name: null,
            description: null,
            type: null,
            scenariums: []
        },

        types: [
            { code: 'AnswerCount', name: 'Answer Count' },
            { code: 'InsuranceVid', name: 'Insurance Vid' },
        ],

        headers: [
            { text: 'id', value: 'id', sortable: true},
            { text: 'code', value: 'code', sortable: true},
            { text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
            { text: 'type', value: 'type', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],

        tabs: [
			{ title: 'main', type: 'form', module: 'functions' },
			{ title: 'scenariums', type: 'list', module: 'scenarium', dataSource: 'scenariums', itemsValue: 'itemstree', actions: ['add'] },
		],
    },
}
export default {
    namespaced: true,
    state: {
        name: 'datatype',
        title: {
            single: 'datatype',
            plural: 'datatypes'
        },
        icon: 'mdi-format-list-bulleted-type',

        item:  {},
        items: [],

        default: {
            id: null,
            code: null,
            name: null,
            regexpression: null,
            ordinal: null,
            icon: null,
            description: null,
        },

        headers: [
            { text: 'id', value: 'id', sortable: true},
            { text: 'icon', value: 'icon', sortable: false},
            //{ text: 'code', value: 'code', sortable: true},
            { text: 'name', value: 'name', sortable: true},
            { text: 'description', value: 'description', sortable: true},
            //{ text: 'regexpression', value: 'regexpression', sortable: true},
            { text: 'actions', value: 'actions', sortable: false },
        ],

        tabs: [
			{ title: 'main', type: 'form', module: 'datatype' },
		],
    },
}
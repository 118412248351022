<template>
	<v-container>
		<v-card elevation="0" :loading="loading">
			<v-toolbar color="primary" dark flat >
				<v-icon>{{ icon }}</v-icon>
				<v-toolbar-title class="mx-3">{{ title.single | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="OnRefresh">
					<v-icon>mdi-autorenew</v-icon>
				</v-btn>
				<!-- <v-btn icon @click="OnSave">
					<v-icon>mdi-content-save</v-icon>
				</v-btn> -->
			</v-toolbar>
			<v-row>
				<v-col  cols="12" md="3">
					<!-- <v-subheader v-text="item.header"></v-subheader> -->
					<v-list two-line dense>
						<v-list-item-group v-model="selected" color="primary">
							<template v-for="(item, index) in items.filter(item => item.enable==1)">
								<v-list-item :key="index" >
									<v-list-item-avatar>
										<v-icon v-if="item.isBot">mdi-robot</v-icon>
										<v-icon v-else>mdi-account</v-icon>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title v-html="item.username"></v-list-item-title>
										<v-list-item-subtitle v-html="item.firstName + ' ' + item.lastName"></v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-divider :key="item.id" inset></v-divider>
							</template>
						</v-list-item-group>
					</v-list>
				</v-col>
				<v-col  cols="12" md="9">
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	data: () => ({
		loading: false,
		selected: [],
	}),
	computed: {
		...mapGetters('users', [ 'title', 'icon', 'items' ]),
	},
	created () {
		if (this.items.length == 0) {
			this.OnRefresh()
		}
	},
	methods: {
		...mapActions('users', [ 'search' ]),
		
		OnRefresh () {
			let that = this
			that.loading = true;
			this.search({
				//item: this.item,
				success: function(response) {
					that.loading = false;
					//that.OnClose();
				},
				error: function(response) {
					that.loading = false
					//that.$refs.alert.Open(response.data, 'error')
				}
			});
		},

	},
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","clipped-left":"","flat":""}},[_c('v-avatar',{attrs:{"color":"primary","to":{name: 'home'}}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v("CtB")])]),_c('v-toolbar-title',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}],staticClass:"ml-4 text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.version))])]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.current)+" ")])]}}])},[_c('v-list',{attrs:{"subheader":"","dense":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.language'))))]),_c('v-list-item-group',{model:{value:(_vm.selectedlang),callback:function ($$v) {_vm.selectedlang=$$v},expression:"selectedlang"}},[_vm._l((_vm.$vuetify.lang.locales),function(item,name,index){return [_c('v-list-item',{key:index,on:{"click":function($event){return _vm.OnClickLocale(name)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.langname)}})],1),_c('v-list-item-action',[(active)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1)]}}],null,true)})]})],2)],1)],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.authorized),expression:"!authorized"}],staticClass:"mx-3",attrs:{"icon":"","to":{name: 'login'}}},[_c('v-icon',[_vm._v("mdi-login")])],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.authorized),expression:"authorized"}],attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.user'))))])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',[_c('v-list-item',{attrs:{"three-line":""},on:{"click":function($event){return _vm.$refs.profile.Open()}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.profile.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.profile.company))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.profile.email))])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.OnPasswordChange}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-key-change")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.passwordchange'))))])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.apikey.Open()}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-key")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("API key")])],1)],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider'):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.OnLogout}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.exit'))))])],1)],1)],1):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.authorized),expression:"authorized"}],attrs:{"icon":""},on:{"click":_vm.OnLogout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,2153328536)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.exit'))))])]):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","flat":"","expand-on-hover":""}},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.mainmenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":item.to}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.'+item.title))))])],1)}),1)],1),_c('v-main',{staticStyle:{"padding":"64px 0px 0px 64px"}},[_c('router-view')],1),_c('z-profile',{ref:"profile",attrs:{"value":_vm.profile}}),_c('z-apikey',{ref:"apikey"}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }